import { useTranslation } from 'react-i18next';
import { Status } from '../types/Status';

export const RequetStatus = ({ status }: { status: Status }) => {
	const { t } = useTranslation();

	switch (status) {
		case Status.ACCEPTED:
			return <div className='text-green-500'>{t(status)}</div>;
		case Status.PENDING_APPROVAL:
			return <div className='text-yellow-500'>{t(status)}</div>;
		case Status.REJECTED:
			return <div className='text-red-500'>{t(status)}</div>;
		default:
			return <div>{t(status)}</div>;
	}
};
