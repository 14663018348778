import styled from 'styled-components';

export const StyledSideMenu = styled.div<{ bgColor: string; color: string }>`
	.ant-layout-sider,
	.ant-layout-sider-trigger,
	.ant-layout-sider-children .ant-menu,
	.ant-menu-submenu-title > .ant-menu-title-content,
	.ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
		background-color: unset;
		background: ${({ bgColor }) => bgColor} !important;
		color: ${({ color }) => color} !important;
		z-index: 10;
	}
	.ant-layout .ant-layout-sider-trigger,
	.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
		background-color: unset;
		background: unset;
		color: ${({ color }) => color} !important;
	}
	.ant-menu-item-active {
		color: ${({ color }) => color} !important;
		opacity: 0.8;
	}
	.ant-menu-dark .ant-menu-item-selected {
		background-color: ${({ color }) => color} !important;
		color: ${({ bgColor }) => bgColor} !important;
	}
`;
