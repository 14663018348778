import { LanguagePhrases } from '../models/LanguagePhrases';

export const polishPhrases: LanguagePhrases = {
	signIn: 'zalogować się',
	home: 'dom',
	signinTitle: 'Zaloguj się na swoje konto płatnicze',
	signinDesc: 'Zaloguj się przy użyciu poświadczeń portalu klienta',
	email: 'Email',
	password: 'Hasło',
	forgetPassword: 'Zapomniałem hasła',
	emailRequired: 'Pole e-mail nie powinno być puste!',
	passwordRequired: 'Pole hasło nie powinno być puste!',
	somethingWrong: 'Wystąpił błąd!',
	emailMsg: 'Prosimy upewnić się, że podany adres e-mail jest poprawny!',
	passwordMsg:
		'Prosimy upewnić się, że hasło zawiera co najmniej jedną wielką literę, jedną małą literę, jeden znak specjalny, a długość hasła wynosi co najmniej 8 znaków!',
	orders: 'Zamówienia',
	problemLoadingListOfOrders: 'Wystąpił problem podczas ładowania listy zamówień',
	date: 'Data',
	orderType: 'Typ zamówienia',
	country: 'Kraj',
	amount: 'Kwota',
	merchant: 'Kupiec',
	rate: 'Wskaźnik',
	status: 'Status',
	buy: 'Kupić',
	sell: 'Sprzedać',
	closed: 'Zamknięte',
	active: 'Aktywny',
	appealed: 'Odwołał się',
	cancelled: 'Odwołany',
	pending: 'Aż do',
	problemLoadingCountries: 'Wystąpił problem podczas ładowania listy krajów',
	becomeMerchant: 'zostać Handlowcem',
	yourAccountIsInactive: 'Twoje konto jest nieaktywne. Skontaktuj się z administratorem, aby go aktywować',
	regularUserHome: 'Strona główna zwykłego użytkownika',
	merchantHome: 'Strona główna handlowca',
	merchantsOffer: 'Oferty kupców',
	searchInMerchantsTable: 'Szukaj w tabeli kupców',
	problemLoadingListOfMerchants: 'Wystąpił problem podczas ładowania listy sprzedawców',
	name: 'nazwa',
	rating: 'ocena',
	totalReview: 'Całkowita recenzja',
	ordersCompleted: 'Zamówienia zrealizowane',
	price: 'cena',
	available: 'dostępny',
	minLimit: 'minimalny limit',
	maxLimit: 'maksymalna granica',
	accountVerification: 'Weryfikacja konta',
	problemLoadingMerchant: 'Wystąpił problem podczas ładowania informacji o sprzedawcy',
	merchantInfo: 'informacje handlowe',
	wantToBuy: 'chcę kupić',
	wantToSell: 'chcę sprzedać',
	amountIsRequired: 'wymagana jest kwota',
	willSend: 'wyślę',
	willReceive: 'otrzymam',
	paymentMethod: 'metoda płatności',
	paymentMethodIsRequired: 'Metoda płatności jest wymagana',
	valueInRangeError: 'kwota powinna mieścić się w tym zakresie:',
	merchants: 'Kupcy',
	merchantId: 'Identyfikator sprzedawcy',
	fullName: 'Pełna nazwa',
	currency: 'Waluta',
	countryCurrency: 'Kraj/waluta',
	paymentMethods: 'Metody Płatności',
	bestSellers: 'Najlepsze sprzedawcy',
	myDetails: 'Moje szczegóły',
	changePassword: 'Zmień hasło',
	myDetailsForm: 'Formularz Moje dane',
	myProfile: 'Mój profil',
	firstName: 'Imię',
	surname: 'Nazwisko',
	phone: 'Telefon',
	address: 'Adres',
	city: 'Miasto',
	zip: 'Zamek błyskawiczny',
	state: 'Państwo',
	registrationDate: 'Data rejestracji',
	newPassword: 'Nowe hasło',
	currentPassword: 'aktualne hasło',
	confirmPassword: 'Potwierdź hasło',
	updatePassword: 'Aktualizować hasło',
	changePasswordForm: 'Zmień formularz hasła',
	yourPasswordGotUpdated: 'Twoje hasło zostało zaktualizowane',
	somethingWentWrongWithUpdatingPassword: 'Podczas aktualizowania hasła coś poszło nie tak',
	pleaseMakeSurePasswordIsValid:
		'Upewnij się, że hasło zawiera co najmniej jedną wielką literę, małą literę, znak specjalny, a długość hasła powinna wynosić co najmniej 8',
	twoPasswordsDoNotMatch: 'Oba hasła muszą być takie same',
	myOffers: 'Moje oferty',
	yourSellerFormGotUpdated: 'Twój formularz sprzedawcy został zaktualizowany',
	somethingWentWrongWithUpdatingSellerForm: 'Podczas aktualizowania formularza sprzedawcy coś poszło nie tak',
	yourBuyerFormGotUpdated: 'Twój formularz kupującego został zaktualizowany',
	somethingWentWrongWithUpdatingBuyerForm: 'Podczas aktualizowania formularza kupującego coś poszło nie tak',
	sellOffer: 'Oferta sprzedaży',
	'country/currency': 'Kraj / Waluta',
	profitRatio: 'Wskaźnik zysku',
	profitRatioIsRequired: 'Wymagany jest wskaźnik zysku',
	profitRatioInRangeError: 'Wskaźnik zysku nie może być większy niż',
	atleastSelectOneMethod: 'Wybierz co najmniej jedną metodę płatności',
	maximumAmount: 'Maksymalna ilość',
	valueMustBePositive: 'Wartość musi być dodatnia',
	mustBeGreaterThanMinValue: 'Wartość musi być większa niż wartość minimalna',
	minimumAmount: 'Stawka minimalna',
	mustBeLessThanMaxValue: 'Wartość musi być mniejsza niż wartość maksymalna',
	visibleForUsers: 'Widoczne dla użytkowników',
	no: 'NIE',
	yes: 'Tak',
	maxOrders: 'Maksymalna ilość zamówień',
	newSellers: 'Nowe sprzedawcy',
	minOrders: 'Minimalna iloota zamowien',
	update: 'Aktualizacja',
	buyOffer: 'Kup ofertę',
	logout: 'Wyloguj',
	termsOfUse: 'Warunki korzystania',
	companies: 'Firmy',
	news: 'Aktualności',
	privacyPolicy: 'Polityka prywatności',
	merchantsOfferSubtitle:
		'Bezszwowe transakcje, Bezgraniczne możliwości: Podnieś swoją firmę dzięki doskonałości w systemie P2P',
	limit: 'Limit',
	countries: 'Kraje',
	min: 'Minimum',
	max: 'Maksymalny',
	all: 'Wszystko',
	merchantProfile: 'Profil sprzedawcy',
	availableLimit: 'Dostępny / Limit',
	trade: 'Handel',
	fees: 'Opłaty',
	reviews: 'Opinie',
	buyers: 'Kupujący',
	termsAndConditions: 'Zasady i Warunki',
	unitPrice: 'Cena jednostkowa',
	youSale: 'Sprzedajesz',
	youReceive: 'otrzymujesz',
	selectPaymentMethods: 'Wybierz metody płatności',
	account: 'Konta',
	note: 'Notatka',
	pleaseWriteYourNote: 'Proszę napisać swoją notatkę',
	certainPaymentMethodsMayHaveFees:
		'Niektóre metody płatności mogą wiązać się z opłatami i dziennymi limitami ustalonymi przez dostawcę usług płatniczych.',
	youPay: 'Ty płacisz',
	sellers: 'Sprzedawców',
	seller: 'Sprzedawca',
	buyer: 'Kupujacy',
	merchantType: 'Typ sprzedawcy',
	orderHeading: 'Zamówienie',
	orderSubtitle: 'Historia się powtarza: Bezproblemowe ponowne zamówienia, za każdym razem!',
	dateRange: 'Zakres dat',
	show: 'Pokaż',
	entries: 'Wpisy',
	searchOrder: 'Wyszukaj zamówienie',
	id: 'ID',
	client: 'Klient',
	type: 'Typ',
	creationDate: 'Data utworzenia',
	availability: 'Dostępność',
	buyersTerm: 'Warunki i postanowienia kupującego',
	sellersTerm: 'Warunki i postanowienia sprzedającego',
	bankDeposit: 'Wpłata na konto bankowe',
	accHolder: 'Oryginalny właściciel konta',
	thirdParty: 'Nie akceptujemy płatności zewnętrznych',
	wedFri: 'Środa-Piątek',
	merchantReview: 'Recenzje handlowca',
	apiNotFound: 'Nie znaleziono interfejsu API',
	unableToAuthenticateTheUser: 'Nieprawidłowa lub wygasła sesja',
	somethingWentWrong: 'Coś poszło nie tak',
	constraintViolated: 'Naruszono ograniczenie',
	recordIsLinked: 'Rekord jest powiązany',
	parametersCannotBeNull: 'Parametry nie mogą mieć wartości null',
	methodArgumentsAreInvalid: 'Argumenty metody są nieprawidłowe',
	fileUploadingError: 'Błąd przesyłania pliku',
	parameterIsNotValid: 'Parametr żądania jest nieprawidłowy',
	requiredParametersAreMissing: 'Brak wymaganych parametrów',
	pleaseEnterValidEmailAddress: 'Proszę wpisać aktualny adres e-mail',
	passwordDoesNotMatchRequirements:
		'Hasło nie spełnia wymagań: minimum 8 i maksymalnie 16 znaków, zawiera co najmniej jedną dużą literę, jedną małą literę, jedną cyfrę i jeden znak specjalny',
	invalidMerchantOfferRequest: 'Nieprawidłowe żądanie oferty sprzedawcy',
	invalidTradingAccountGroup: 'Nieprawidłowa grupa kont handlowych',
	countryIsNotAllowed: 'Kraj nie jest dozwolony w systemie P2P',
	orderIdProvidedIsInvalid: 'Podany identyfikator zamówienia jest nieprawidłowy',
	orderIsClosed: 'Zamówienie jest zamknięte',
	actionNotApplicable: 'Działanie nie dotyczy',
	errorOccurredWhileProcessingYourRequest: 'Wystąpił błąd podczas przetwarzania Twojego żądania',
	problemOccurredWhileLoadingRecords: 'Wystąpił problem podczas ładowania rekordów',
	problemOccurredWhileLoadingRecord: 'Wystąpił problem podczas ładowania rekordu',
	problemOccurredWhileAddingRecord: 'Wystąpił problem podczas dodawania rekordu',
	problemOccurredWhileUpdatingRecord: 'Wystąpił problem podczas aktualizacji rekordu',
	problemOccurredWhileDeletingRecord: 'Wystąpił problem podczas usuwania rekordu',
	recordAlreadyExists: 'Rekord już istnieje',
	noRecordFound: 'Nie znaleziona nagrania',
	accessDeniedMissingKyc: 'Odmowa dostępu z powodu braku Kyc',
	invalidCredentials: 'Nieprawidłowe dane uwierzytelniające',
	invalidPaymentMethod: 'Nieprawidłowa metoda płatności',
	invalidOfferType: 'Nieprawidłowy typ oferty',
	orderIsInProgress: 'Zamówienie jest w trakcie realizacji',
	orderReviewIsAlreadyPresent: 'Przegląd zamówienia jest już obecny',
	orderIsClosedMoreThanWeekAgo: 'Zamówienie zostało zamknięte ponad tydzień temu',
	userIsInactive: 'Użytkownik jest nieaktywny',
	userCountryNotAllowed: 'Kraj użytkownika nie jest dozwolony',
	orderIsExpiredOrClosed: 'Zamówienie wygasło lub zostało zamknięte',
	invalidOrderAction: 'Nieprawidłowa akcja zamówienia',
	onlyOneOrderAllowedAtTime: 'Dozwolone jest tylko jedno zamówienie na raz',
	invalidTradingAccount: 'Nieprawidłowe konto handlowe',
	merchantBelongsToDifferentCountry: 'Nie można handlować! Sprzedawca należy do innego kraju',
	orderAmountIsMoreThanAvailableBalance: 'Kwota zamówienia przekracza dostępne saldo!',
	merchantOfferNotAvailable: 'Oferta sprzedawcy niedostępna!',
	amountDoesNotSatisfyAvailableLimit: 'Kwota nie przekracza dostępnego limitu!',
	countriesPaymentMethods: 'Kraje / Metody płatności',
	countriesPaymentSubtitle:
		'Dokonuj dziś transakcji P2P TetherUS bez żadnych opłat i przy użyciu preferowanej metody płatności!',
	countryCode: 'Kod kraju',
	currencyCode: 'Kod waluty',
	maxProfitRatio: 'Maksymalny współczynnik zysku',
	public: 'Publiczny',
	administration: 'Administracja',
	searchInCountriesAndPaymTable: 'Wyszukaj kraje/metody płatności',
	welcomeBack: 'Witamy spowrotem',
	loginToAdminPortal: 'Zaloguj się do portalu administracyjnego',
	enterYourEmail: 'Wprowadź swój email',
	enterPassword: 'Wprowadź hasło',
	readOur: 'Przeczytaj nasze',
	clients: 'Klienci',
	clientProfile: 'Profil klienta',
	searchInClientsTable: 'Wyszukaj tabelę klientów',
	clientsPageHeading: 'Centrum Wzmocnienia P2P: Łącząc Użytkowników, Uwalniając Możliwości',
	orderWith: '{{type}} order from {{name}}',
	timeCreated: 'Time Created',
	orderNumber: 'Order Number',
	clientDetails: 'Client Details',
	merchantDetails: 'Merchant Details',
	orderDetails: 'Order Details',
	paymentDetails: 'Payment Details',
	clientPay: 'Client Pay',
	clientAccount: 'Client Account',
	merchantReceive: 'Merchant Receive',
	orderHistory: 'Order History',
	copiedSuccessfully: 'Copied Successfully',
	accountGroupLimits: 'Limity Grup Kont',
	orderMax: 'Maksymalne zamówienie',
	orderMin: 'Minimalne zamówienie',
	tradingPlatform: 'Platforma wymiany',
	tradingAccountGroup: 'Grupa kont handlowych',
	addAccountGroupLimits: 'Dodaj nową grupę kont',
	submit: 'Zatwierdź',
	editAccountGroupLimits: 'Edytuj Limit Grupy Konta',
	deleteConfirmation: 'Czy na pewno chcesz usunąć ten rekord?',
	confirmDelete: 'Potwierdzenie usunięcia',
	ok: 'OK',
	cancel: 'Anuluj',
	supportRoom: 'Support Room',
	theorder: 'The Order',
	color: 'Kolor',
	backgroundColor: 'Kolor tła',
	confirmNewPaymentMethodDelete: 'Czy na pewno chcesz usunąć ten rodzaj płatności?',
	countryCodeIsRequired: 'Kod kraju jest wymagany',
	currencyCodeIsRequired: 'Kod waluty jest wymagany',
	maxProfitRatioIsRequired: 'Maksymalny współczynnik zysku jest wymagany',
	countryNameIsRequired: 'Nazwa kraju jest wymagana',
	paymentMethodName: 'Nazwa metody płatności',
	reverseToPreviousStage: 'Reverse To Previous Stage',
	releasePayment: 'Release Payment',
	closeAppeal: 'Clocse Order',
	cancelOrder: 'Cancel Order',
	cancelThisOrder: 'Cancel This Order',
	cancelOrderMessage: 'I made sure that no payment was made by Client/Merchant',
	closeOrderMessage:
		'I made sure that both parties transferred the payment. On confirmation order will be marked as completed.',
	closeThisAppeal: 'Close This Appeal',
	releaseMerchantPayment: 'Release Merchant Payment',
	releaseMerchantPaymentMessage1: 'I have confirm that client has transferred the payment',
	releaseMerchantPaymentMessage2: 'I agree to release Merchant payment to the Client.',
	releaseMerchantPaymentMessageSell: 'I have confirm that Merchant has transferred the payment',
	releaseMerchantPaymentMessageSell2: 'I agree to release clinet payment to the merchant.',
	revertToPreviousStage: 'Revert To Previous Stage',
	revertToPreviousStageMessage: 'Are you sure that you want to change order status from Appeal to Transferred?',
	Assignee: 'Assignee',
	Resolved: 'Resolved',
	AssignedOn: 'Resolved',
	Reassign: 'Reassign',
	invalidmerchantOffer: 'Invalid Merchant Offer',
	invalidtradingaccountgroup: 'Invalid Trading account group',
	orderidisinvalid: 'Order ID provided is Invalid',
	Orderisclosed: 'Order is closed',
	invalidorderaction: 'Invalid Order Action',
	onlyoneorderallowed: 'Only one order allowed at time',
	cannotttrade: 'Cant trade, merchant belongs to different country',
	orderamountismorethanbalance: 'Order amount is more than available balance!',
	merchantoffernotavailable: 'Merchant Offer not available!',
	Amountdoesnotsatisfyavailablelimit: 'Amount does not satisfy available limit!',
	ErroroccurredwhileprocessingMT5request: 'Error occurred while processing Konto handlowe request.!',
	Amountdoesnotsatisfyofferallowedlimit: 'Amount does not satisfy offer allowed limit!',
	cancelAppeal: 'Cancel Appeal',
	revert: 'REVERT',
	release: 'RELEASE',
	adminusers: 'Admin Users',
	admin: 'Admin',
	adminDetailsforAddEditAdmin: 'Jedność Administracji: Dodawanie Liderów, Podnoszenie Doskonałości Razem',
	emailAddress: 'Email Address',
	role: 'Role',
	phoneNumber: 'Phone Number',
	canHandleAppeal: 'Can Handle Appeal',
	addNewAdmin: 'Add new Admin',
	lastName: 'Last Name',
	accountGroups: 'Grupy kont',
	notificationsHeading: 'Powiadomienia',
	noNotificationsFound: 'Brak powiadomzeń',
	opened: 'Otwarty',
	appeal: 'Apel',
	expired: 'Wygasło',
	placed: 'Umieszczony',
	unknown: 'Nieznane',
	quantity: 'Quantity',
	notAvailable: 'Niedostępny',
	currentpassword: 'Current Password',
	newpassword: 'New Password',
	matchedPassword: 'The new password that you entered do not match!',
	appealTask: 'Appeal Task',
	paymentReceived: 'Payment Received',
	activeTask: 'Active Task',
	paymentTransfered: 'Payment Transfered',
	accepted: 'Accepted',
	notificationNewAppealTaskAssigned:
		'Otwarto odwołanie dla zamówienia {{orderType}} o numerze #{{orderNumber}} na kwotę {{amount}}.',
	notificationAppealReassigned:
		'Twoje zadanie odwoławcze zostało ponownie przypisane {{newAssigneeFullName}} z powodu {{reassignMethod}}.',
	notificationAppealNewMessage: 'Nowa wiadomość w zamówieniu #{{orderNumber}} o {{createdAt}}.',
	notificationAppealReminder:
		'Masz oczekujące odwołanie, które zostanie przypisane ponownie za {{reassignedInMinutes}} godzin.',
	seeMore: 'Zobacz Więcej',
	finishedSuccessfuly: 'Zakończono pomyślnie',
	adminUser: 'Admin user',
	enterValidValue: 'Wprowadź poprawna wartość',
	requiredField: 'Pole jest wymagane',
	resolvedBy: 'Resolved By',
	assigneToMe: 'Przypisz do mnie',
	averageRating: 'Average Rating',
	orderMaxShouldBeGreaterThanOrderMin: 'Maksymalna kwota powinna być większa od minimalnej',
	orderMinShouldBeLessThanOrderMax: 'Minimalna kwota powinna być mniejsza od maksymalnej',
	merchantPay: 'Merchant Pays',
	orderMaxShouldNotBeGreaterThanFiftyThousand: 'Maksymalna kwota nie może być większa niż 50,000',
	orderMinShouldNotBeGreaterThanFiftyThousand: 'Minimalna kwota nie może być większa niż 50,000',
	buyMin: 'Kup Min',
	buyMax: 'Kup Max',
	sellMin: 'Sprzedaż Min',
	sellMax: 'Sprzedaż Max',
	accessDenied: 'Brak dostępu',
	orderNumberProvidedIsInvalid: 'Podany numer zamówienia jest nieprawidłowy',
	errorProccessingMT5: 'Błąd przetwarzania Konto handlowe',
	amountDosentSatisfyLimit: 'Kwota nie spełnia limitu',
	chatHasBeenClosed: 'Czat został zamknięty',
	emailAlreadyExist: 'e-mail już istnieje',
	phoneNumberDoesNotMatchTheRequirement: 'Numer telefonu nie odpowiada wymaganiom',
	newPasswordAndConfirmPasswordNotSame: 'Nowe hasło i potwierdź, że hasło nie jest takie samo',
	userIsSuspended: 'Użytkownik jest zawieszony',
	userIsBlocked: 'Użytkownik jest zablokowany',
	merchantNotFound: 'Nie znaleziono sprzedawcy',
	invalidOTP: 'Nieprawidłowe hasło jednorazowe',
	suspendedConfirmation: 'Czy na pewno chcesz zawiesić',
	unsuspendedConfirmation: 'Czy na pewno chcesz anulować zawieszenie?',
	blockConfirmation: 'Czy na pewno chcesz zablokować',
	unblockConfirmation: 'Czy na pewno chcesz odblokować',
	notificationNewOrderMessage: `Nowe zamówienie {{orderType}} o numerze {{orderNumber}} na kwotę {{amount}} zostało otwarte.`,
	notificationOrderNewMessage: `Nowa wiadomość w zamówieniu #{{orderNumber}} o {{createdAt}}`,
	notificationOrderExpiryWarning: `Twoje zamówienie #{{orderNumber}} jest o krok od wygaśnięcia o {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `Zamówienie #{{orderNumber}} wygasło.`,
	notificationOrderStatusUpdate: `Zmiana statusu zamówienia #{{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `Twoja oferta {{offerType}} została zdjęta z powodu {{reason}}`,
	notificationAccountBalanceLow: `Saldo konta Twojego handlowca jest niskie. Obecne saldo: {{amount}}`,
	notificationSellAvailableLow: `Ilość oferty sprzedaży, którą określiłeś, jest na wyczerpaniu. Zaktualizuj ilość, aby oferta pozostała ważna. Obecne dostępne saldo sprzedaży: {{sellAvailable}}`,
	yesterday: 'wczoraj',
	today: 'dzisiaj',
	daysAgo: 'dni temu',
	withdrawal: 'Wypłata',
	deposit: 'Depozyt',
	dashboardActiveOrders: 'Aktywne zamówienia',
	dashboardAppealOrders: 'Zamówienia odrzucone',
	dashboardTotalOrders: 'Łącznie zamówienia',
	dashboardTotalMerchants: 'Łącznie sprzedawcy',
	dashboardTotalClients: 'Łącznie klienty',
	reports: 'Reporty',
	thisMonth: 'w tym miesiącu',
	thisWeek: 'w tym tygodniu',
	thisYear: 'w tym roku',
	thisQuarter: 'w tym kwartalu',
	last60Days: 'ostatnie 60 dni',
	totalAnalyticsOrder: 'Łącznie zamówienia',
	buyOrders: 'Kup',
	sellOrders: 'Sprzedaż',
	myOffersSubtitle: 'To są oferty, które zostaną przedstawione Twoim klientom na Merchant.',
	calculatedPrice: 'Final Offer Price',
	notFound: 'Order Not Found',
	actions: 'Actions',
	orderId: 'Order ID',
	noPaymentMethod: 'No Payment Method',
	buyMaxShouldNotBeGreaterThanFiftyThousand: 'Maksymalna kwota nie może być większa niż 50,000',
	buyMinShouldNotBeGreaterThanFiftyThousand: 'Minimalna kwota nie może być większa niż 50,000',
	sellMaxShouldNotBeGreaterThanFiftyThousand: 'Maksymalna kwota nie może być większa niż 50,000',
	sellMinShouldNotBeGreaterThanFiftyThousand: 'Minimalna kwota nie może być większa niż 50,000',
	sellMinShouldBeLessThanSellMax: 'Minimalna kwota powinna być mniejsza od maksymalnej',
	sellMaxShouldBeGreaterThanSellMin: 'Maksymalna kwota powinna być wyższa od minimalnej',
	buyMinShouldBeLessThanBuyMax: 'Minimalna kwota powinna być mniejsza od maksymalnej',
	buyMaxShouldBeGreaterThanBuyMin: 'Maksymalna kwota powinna być wyższa od minimalnej',
	invalidType: 'Nieprawidłowy typ pliku. Proszę przesłać plik JPG, JPEG, PNG lub PDF.',
	fileExceedLimit: 'Rozmiar pliku przekracza dozwolony limit (5 MB).',
	expireIn: 'Wygasa za',
	enterOtp: 'Wprowadź kod OTP',
	exchangeRate: 'Kurs wymiany',
	sortBy: 'sortuj według',
	enterYourOtp: 'Wprowadź kod weryfikacyjny swojego e-maila',
	notificationPendingAppeal:
		'Apelacja z numerem zamówienia {{orderNumber}} nie została jeszcze odpowiedziana przez {{oldAssigneeFullName}} w ciągu {{inactiveTimeMinutes}} minut. Kliknij tutaj, aby przypisać ponownie.',
	cannotEnableOffer: 'Nie można aktywować oferty z powodu niskiego salda',
	ActivateConfirmation: 'Czy na pewno chcesz aktywować',
	deactivateConfirmation: 'Czy na pewno chcesz dezaktywować',
	passwordChange: 'Zmień hasło',
	didNotreceiveOtp: 'Vous n avez pas reçu le code ?',
	resend: 'Wyślij ponownie',
	biggerThanZero: 'Wartość musi być większa niż 0',
	lessThanhundred: 'Wartość musi być mniejsza niż 100 lub równa 100',
	inactive: 'Nieaktywny',
	appealReassignNewAdmin:
		'Nowe zadanie odwoławcze zostało przypisane do Ciebie przez {{oldAssigneeFullName}} przez {{reassignMethod}} przypisanie',
	NEW_ORDER: 'Nowe zamówienie',
	ORDER_NEW_MESSAGE: 'Nowa wiadomość w zamówieniu',
	ORDER_EXPIRY_WARNING: 'Ostrzeżenie o wygaśnięciu zamówienia',
	ORDER_EXPIRED: 'Zamówienie wygasło',
	ORDER_STATUS_UPDATE: 'Aktualizacja statusu zamówienia',
	OFFER_DISABLED: 'Oferta wyłączona',
	ACCOUNT_BALANCE_LOW: 'Niski stan konta',
	SELL_AVAILABLE_LOW: 'Niska dostępność sprzedaży',
	NEW_APPEAL_TASK_ASSIGNED: 'Nowe zadanie odwoławcze przypisane',
	APPEAL_NEW_MESSAGE: 'Nowa wiadomość w odwołaniu',
	APPEAL_REMINDER: 'Przypomnienie odwoławcze',
	PENDING_APPEAL: 'Oczekujące odwołanie',
	APPEAL_REASSIGNED: 'Odwołanie ponownie przypisane',
	APPEAL_REASSIGNED_NEW_ADMIN: 'Odwołanie ponownie przypisane nowemu administratorowi',
	handleAppeal: 'Obsłuż apel',
	appealTasks: 'Zadania odwoławcze',
	transferred: 'Przeniesiono',
	completed: 'Zakończono',
	rejected: 'Odrzucono',
	INSUFFICIENT_BALANCE: 'Niewystarczający stan konta',
	COOLDOWN: 'Okres ostygnięcia',
	BY_ADMIN: 'Przez administratora',
	MANUAL: 'Ręczny',
	INACTIVE: 'Nieaktywny',
	typeMessageHere: 'Wpisz tutaj wiadomość',
	OTPIsrequired: 'Wymagane jest wprowadzenie kodu OTP!',
	Pleaseuploadthepaymentproof: 'Proszę załadować dowód płatnośc',
	agent: 'Agent',
	chatIsDisabled: 'Czat jest wyłączony',
	sessionExpired: 'Sesja wygasła',
	block: 'Blok',
	unblock: 'Odblokować',
	suspend: 'Wstrzymać',
	unsuspend: 'Cofnij zawieszenie',
	search: 'szukaj',
	allRightsReserved: 'Wszelkie prawa zastrzeżone',
	customeRange: 'Niestandardowy zakres',
	confirm: 'Potwierdź',
	suspendClient: 'Zawiesić klienta',
	suspendMerchant: 'Zawiesić handlowca',
	unsuspendMerchant: 'Odwiesić handlowca',
	unsuspendClient: 'Odwiesić klienta',
	download: 'Pobierz',
	chatnow: 'Rozmawiaj teraz',
	dashboardTotalWithdrawalAmount: 'Całkowita kwota wypłat',
	dashboardTotalDepositAmount: 'Całkowita kwota depozytów',
	completionRate: 'Wskaźnik ukończenia',
	merchantDetailsDescription:
		'Eksploruj szczegółowe profile sprzedawców i ich opinie, oferując cenne wskazówki, aby poprawić swoje doświadczenia z handlu P2P Tether USDt.',
	merchantsReviewSubtitle: 'Podtytuł recenzji handlowców: Wspieranie zaufania i jakości na całej platformie',
	appealTaskSubtitle: 'Centrala Odwołań P2P: Gdzie Wyzwania Spotykają się z Wzmocnionymi Rozwiązaniami',
	CountryAndPaymentMethods:
		'Kraje i Metody Płatności: Precyzja w Każdej Transakcji: Dostosowanie Krajów i Płatności dla Twojego Sukcesu',
	accountGroupLimitsSubtitle: 'Administrator Kształtuje Sukces Poprzez Limity Zamówień',
	tradingPaltform: 'Platforma handlowa',
	accountGroup: 'Grupa konta',
	accountNumber: 'Numer konta',
	balance: 'Saldo',
	onHoldBalance: 'Saldo zablokowane',
	otpexpired: 'Kod OTP wygasł!',
	reachedotplimit: 'Osiągnąłeś maksymalną liczbę prób dla OTP!!',
	maintenance: 'Konserwacja',
	maintenanceSubtitle: 'Łączenie rąk dla bezproblemowych rozwiązań konserwacyjnych',
	startTime: 'Czas rozpoczęcia',
	endTime: 'Czas zakończenia',
	enabled: 'Włączony',
	deleteMaintanceConfirmation: 'Czy na pewno chcesz usunąć tę konserwację?',
	annoncement: 'Zaplanowane utrzymanie portalu od {{from}} do {{to}}',
	depositBouns: 'Bonus depozytowy',
	withdrawBouns: 'Bonus wypłaty',
	CurrencySubtitle: 'Poszerz swoje możliwości, dodaj walutę już dziś',
	add: 'Dodaj',
	INVALID_MIN_MAX_RANGE: 'Nieprawidłowy zakres od minimalnego do maksymalnego',
	FORM_IS_INVALID: 'Formularz jest nieprawidłowy',
	walletTransactions: 'Wallet Transactions',
	walletTransactionsDescription: 'Zwiększ swoje doświadczenie P2P dzięki naszemu wszechstronnemu portfelowi',
	assignee: 'Mandatariusz',
	assignedOn: 'Przypisane dnia',
	reassign: 'Przypisz ponownie',
	approve: 'Zatwierdzić',
	reject: 'Odrzucić',
	withdrawCommission: 'Wycofaj prowizję',
	wallet: 'Portfel',
	contactUser: 'Skontaktuj się z użytkownikiem',
	transactionReport: 'Raport transakcji',
	searchWallet: 'Wyszukaj portfel',
	user: 'Użytkownik',
	walletNumber: 'Numer portfela',

	PENDING: 'Aż do',
	COMPLETED: 'Zakończony',
	REVERTED: 'Przywrócony',
	PENDING_APPROVAL: 'Oczekujące na zatwierdzenie',
	CANCELLED: 'Odwołany',
	P2P_BUY: 'Kup P2P',
	P2P_SELL: 'Sprzedaż P2P',
	P2P_REFUND: 'Zwrot kosztów P2P',
	TRADING_DEPOSIT: 'Depozyt handlowy',
	TRADING_WITHDRAW: 'Wycofanie transakcji',
	EXCHANGE: 'Giełda',
	WALLET: 'Portfel',
	canHandleRequest: ' Może obsłużyć żądanie',

	offers: 'Oferty',
	tradingAccount: 'Konto transakcyjne',
	walletsAccount: 'Konta portfeli',
	Currencycodealreadyexist: 'Kod waluty już istnieje',
	Walletnotexist: 'Portfel nie istnieje',
	Walletbalanceunsatisfied: 'Niezadowalający stan salda portfela',
	Merchantwalletscurrencyisdifferent: 'Waluta portfeli handlowców jest różna',
	Walletonholdbalanceunsatisfied: 'Niezadowalający stan salda portfela zablokowanego',
	Erroroccurredwhiledoingtransaction: 'Wystąpił błąd podczas wykonywania transakcji',
	Youarenotallowedtoperformthisaction: 'Nie masz uprawnień do wykonania tej operacji',
	p2pBuyOrder: 'Zamówienie kupna P2P',
	p2pSellOrder: 'Zamówienie sprzedaży P2P',
	depositToTradingAccount: 'Wpłata na konto handlowe',
	withdrawFromTradingAccount: 'Wypłata z konta handlowego',
	actionBy: 'Akcja przez',

	requestConfirmation: 'Czy na pewno chcesz {{type}} tę transakcję?',

	userName: 'Nazwa użytkownika',
	transactionNumber: 'Numer transakcji',
	sendEmail: 'Wyślij e-mail',
	withdrawRequest: 'Żądanie wypłaty',
	withdrawAmount: 'Kwota wypłaty',
	transactionId: 'ID transakcji',

	REQUEST_REMINDER: 'PRZYPOMNIENIE O WNIOSKU',
	PENDING_REQUEST: 'OCZEKUJĄCY WNIOSEK',
	REQUEST_REASSIGNED: 'PRZYPISANY PONOWNIE WNIOSEK',
	REQUEST_REASSIGNED_NEW_ADMIN: 'WNIOSEK PRZYPISANY PONOWNIE DO NOWEGO ADMINISTRATORA',
	P2P_TRANSACTION_REQUESTS: 'WNIOSKI O TRANSAKCJE P2P',
	requestRemindar: 'Masz oczekujący wniosek, który zostanie ponownie przypisany za {{reassignedInMinutes}} godzin',
	pendingRequest:
		'Żądanie transakcji o numerze {{transactioNumber}} nie zostało odpowiednio zareagowane przez {{oldAssigneeFullName}} w ciągu {{inactiveTimeMinutes}} godzin(y), kliknij tutaj, aby przypisać ponownie.',
	requestReassign:
		'Twój wniosek o transakcję został przypisany ponownie do {{newAssigneeFullName}} przez metodę przypisania {{reassignMethod}}',
	requestReassignedNewAdmin:
		'Nowy wniosek o transakcję został przypisany ponownie do ciebie przez {{oldAssigneeFullName}} przez metodę przypisania {{reassignMethod}}',
	p2pTransactionRequest:
		'Wykonałeś {{transactionType}} na kwotę {{amount}}. Twój wniosek o {{transactionType}} na kwotę {{amount}} został {{requestState}}.',
	OrderWalletTransaction: 'Transakcja portfela zlecenia',
	depositWithdrawRequest: 'Zgadzam się na {{type}} transakcji w wysokości {{amount}}',
	approveTransaction: 'Zatwierdź transakcję',
	rejectTransaction: 'Odrzuć transakcję',
	transactionRequest:
		'Został Ci przypisany wniosek o {{transactionType}} o numerze {{transactionNumber}} na kwotę {{amount}}',
	TRANSACTION_REQUEST: 'Żądanie transakcji',
	handleRequest: 'obsłużyć żądanie',
	ordersAmountStatistic: 'Statystyka wartości zamówień',
	searchAdmin: 'Wyszukaj administratora',
	reassignSuccessfully: 'Przypisano ponownie pomyślnie',
	buyAmount: 'Kwota depozytu',
	sellAmount: 'Kwota sprzedaży',
	validationChatMessage: 'specjalne <, >, & są niedozwolone.',
	withdrawRequesttitle: 'Wypłata z portfela',
	depositRequestTitle: 'Wpłata do portfela',
	withdrawRequestSubtitle: 'Kwota zostanie pobrana z portfela w ciągu minuty.',
	depositRequestSubtitle: 'Kwota zostanie zaksięgowana w portfelu w ciągu minuty.',
	withdrawAction: 'wypłatę',
	depositAction: 'wpłatę',

	errorAvalibleWalletBalance: 'powinno być mniejsze niż dostępne saldo portfela',
	successfullyWithdraw: 'Pomyślnie wypłacono {{amount}} {{currency}}',
	successfullyDeposit: 'Pomyślnie wpłacono {{amount}} {{currency}}',
	merchantsDashborad: 'Panel sprzedawców',
	depositOrder: 'Zlecenie wpłaty',
	withdrawOrder: 'Zlecenie wypłaty',
	completedOrders: 'Zrealizowane zamówienia',
	cancelledOrders: 'Anulowane zamówienia',
	completedOrdersSubtitle: 'Zamówienia zakończone przez sprzedawcę.',
	cancelledOrdersSubtitle: 'Zamówienia anulowane przez sprzedawcę.',
	expiredOrders: 'Zamówienia wygasłe',
	expiredOrdersSubtitle: 'Zamówienia wygasłe sprzedawcy.',
	appealOrders: 'Zamówienia odwoławcze',
	appealOrdersSubtitle: 'Zamówienia odwoławcze sprzedawcy.',
	CustomerReport: 'Raport klienta',
	withdraw: 'Wypłata',
	typeSearch: 'Wpisz, aby wyszukać',
	customers: 'Klienci',
	repeatedOrders: 'Powtórzone zamówienie przez klientów',
	oldCustomers: 'Zamówienie od starych klientów',
	newCustomers: 'Zamówienie od nowych klientów',
	report: 'Raport',
	noData: 'Brak danych',
	repeated: 'Powtórzony',
	new: 'Nowy',
	old: 'Stary',
	clientsDashborad: 'Panel klientów',
	maintanceError: 'Data zakończenia musi być późniejsza niż data rozpoczęcia',
	otpResendMessage: 'Kod OTP został ponownie wysłany pomyślnie',
	otpSendMessage: 'Kod OTP został pomyślnie wysłany',
	FileExtensionNotSupported: 'Rozszerzenie pliku nieobsługiwane',
	FileTooLarge: 'Plik jest za duży',
	InvalidFile: 'Nieprawidłowy plik',
	ADMIN_WALLET_DEPOSIT: 'Depozyt portfela administratora',
	ADMIN_WALLET_WITHDRAW: 'Wypłata z portfela administratora',
	contactInfo: 'Informacje kontaktowe',
	frozenBalance: 'Zamrożone saldo',
	walletBalance: 'Dostępne saldo',
	ACCEPTED: 'Zaakceptowany',
	REJECTED: 'Odrzucony',
	SimultaneousTransactions: 'Transakcje równoczesne nie są dozwolone',
	recaptchaError: 'Nie udało się zrealizować Twojego żądania. Odśwież stronę i spróbuj ponownie',
	'Currency/Wallet': 'Waluta/Portfel',
	createdBy: 'Utworzone przez',
	both: 'Seller and Buyer',
	Comment: 'Comment',
	AddyourComments: 'Add your Comments',
	withdrawRequests: 'Żądania wypłaty',
	suspended: 'Zawieszony',
	accept: 'Accept',
	'Filter Options': 'Opcje filtrowania',
	languageError: 'Nie można zaktualizować tego samego języka',
	'Country/Payment Methods': 'Kraj/Metody płatności',
	Message: 'Wiadomość',
	'Select Country': 'Select Country',
	valueBetweenZeroAandhundred: 'Value Should be between 0 and 100',
	accountGroupName: 'Nazwa grupy kont',
	'TradingAccount(s)NotSupported': 'Konto(a) handlowe nie są obsługiwane w systemie P2P',
	blocked: 'BLOCKED',
	dashboardTotalBalance: 'Całkowity balans',
	dashboardClientsBalance: 'Balans klientów',
	dashboardMerchantsBalance: 'Balans sprzedawców',
	complained: 'Złożono skargę',
	dashboardClientsWalletsBalance: 'Całkowity balans',
	dashboardMerchantsWallestsBalance: 'Balans klientów',
	withdrawalOffer: 'Offre de retrait',
	depositOffer: 'Offre de dépôt',
	availableDeposit: 'Disponible',
	minimumAmountDeposit: 'Montant minimum',
	maximumAmountDeposit: 'Montant maximum',
	availableWithdraw: 'Disponible',
	minimumAmountWithdraw: 'Montant minimum',
	maximumAmountWithdraw: 'Montant maximum',
	amountmustbelessThanavailabel: 'Le montant doit être inférieur au solde disponible {{amount}} {{currency}}',
	amountmustbeBiggerThanMinmum: 'Le montant doit être supérieur au minimum',
	minAllowedValue: 'Le montant minimum doit être supérieur ou égal à {{amount}} {{currency}}',
	maxAllowedValue: 'Le montant minimum doit être supérieur ou égal à {{amount}} {{currency}}',
	maxValueRange: 'La valeur maximale doit être comprise entre 1 et {{amount}}',
	noteMinLength: 'La note doit contenir plus de 20 caractères',
	noteMaxLength: 'La note doit contenir moins de 1000 caractères',
	noteIsRequred: 'Proszę wprowadzić notatkę',
};
