import { LanguagePhrases } from '../models/LanguagePhrases';

export const frenchPhrases: LanguagePhrases = {
	home: 'maison',
	signinTitle: 'Connectez-vous à votre compte de paiement',
	signinDesc: "Connectez-vous à l'aide des identifiants du portail client",
	email: 'Email',
	password: 'Mot de passe',
	forgetPassword: 'Mot de passe oublié',
	signIn: 'Se connecter',
	emailRequired: 'Le champ email ne doit pas être vide!',
	passwordRequired: 'Le champ mot de passe ne doit pas être vide!',
	somethingWrong: "Quelque chose s'est mal passé!",
	emailMsg: 'Veuillez vous assurer que votre adresse email est valide!',
	passwordMsg:
		"Veuillez vous assurer que votre mot de passe comprend au moins une lettre majuscule, une lettre minuscule, un caractère spécial et que sa longueur est d'au moins 8 caractères!",
	orders: 'Ordres',
	problemLoadingListOfOrders: 'Il y a un problème de chargement de la liste des commandes',
	date: 'Date',
	orderType: 'Type de commande',
	country: 'Pays',
	amount: 'Montante',
	merchant: 'Marchande',
	rate: 'Taux',
	status: 'Statut',
	buy: 'Acheter',
	sell: 'Vendre',
	pending: 'En attente',
	active: 'Actif',
	appealed: 'Appel',
	cancelled: 'Annulé',
	closed: 'Fermé',
	problemLoadingCountries: 'Il y a un problème lors du chargement de la liste des pays',
	becomeMerchant: 'devenir marchand',
	yourAccountIsInactive: "Votre compte est inactif. Veuillez contacter votre administrateur pour l'activer",
	regularUserHome: "Accueil de l'utilisateur régulier",
	merchantHome: 'Accueil marchand',
	merchantsOffer: 'Offres des commerçants',
	searchInMerchantsTable: 'Rechercher dans le tableau des marchands',
	problemLoadingListOfMerchants: 'Il y a un problème lors du chargement de la liste des marchands',
	name: 'nom',
	rating: 'notation',
	totalReview: 'Révision totale',
	ordersCompleted: 'Commandes terminées',
	price: 'prix',
	available: 'dostępny',
	minLimit: 'limite minimale',
	maxLimit: 'limite maximale',
	accountVerification: 'Vérification de compte',
	problemLoadingMerchant: 'Un problème est survenu lors du chargement des informations sur le marchand',
	merchantInfo: 'informations sur le marchand',
	wantToSell: 'je veux vendre',
	wantToBuy: 'je veux acheter',
	amountIsRequired: 'le montant est requis',
	willReceive: 'Je vais recevoir',
	willSend: "j'enverrai",
	paymentMethod: 'mode de paiement',
	paymentMethodIsRequired: 'Le mode de paiement est requis',
	valueInRangeError: 'le montant doit être dans cette fourchette :',
	merchants: 'Marchands',
	countryCurrency: 'Pays/Devise',
	paymentMethods: 'méthodes de payement',
	myDetails: 'Mes Details',
	changePassword: 'Changer le mot de passe',
	myDetailsForm: 'Formulaire Mes informations',
	myProfile: 'Mon profil',
	firstName: 'Prénom',
	surname: 'Nom de famille',
	phone: 'Téléphone',
	address: 'Adresse',
	city: 'Ville',
	zip: 'Zipper',
	state: 'État',
	registrationDate: "Date d'inscription",
	newPassword: 'Nouveau mot de passe',
	currentPassword: 'Mot de passe actuel',
	confirmPassword: 'Confirmez le mot de passe',
	updatePassword: 'Mettre à jour le mot de passe',
	changePasswordForm: 'Formulaire de changement de mot de passe',
	yourPasswordGotUpdated: 'Votre mot de passe a été mis à jour',
	somethingWentWrongWithUpdatingPassword: "Une erreur s'est produite lors de la mise à jour du mot de passe",
	pleaseMakeSurePasswordIsValid:
		"Veuillez vous assurer que votre mot de passe comprend au moins une lettre majuscule, une lettre minuscule, un caractère spécial et que la longueur du mot de passe doit être d'au moins 8",
	twoPasswordsDoNotMatch: 'Les deux mots de passe doivent être identiques',
	myOffers: 'Mes offres',
	yourSellerFormGotUpdated: 'Votre formulaire vendeur a été mis à jour',
	somethingWentWrongWithUpdatingSellerForm: "Une erreur s'est produite lors de la mise à jour du formulaire du vendeur",
	yourBuyerFormGotUpdated: "Votre formulaire d'acheteur a été mis à jour",
	somethingWentWrongWithUpdatingBuyerForm:
		"Une erreur s'est produite lors de la mise à jour du formulaire de l'acheteur",
	sellOffer: 'Offre de vente',
	'country/currency': 'Pays / Devise',
	profitRatio: 'Taux de profit',
	profitRatioIsRequired: 'Le taux de profit est requis',
	profitRatioInRangeError: 'Le ratio de profit ne peut pas être supérieur à',
	atleastSelectOneMethod: 'Veuillez sélectionner au moins un mode de paiement',
	maximumAmount: 'Quantité maximale',
	valueMustBePositive: 'La valeur doit être positive',
	mustBeGreaterThanMinValue: 'La valeur doit être supérieure à la valeur minimale',
	minimumAmount: 'Montant minimal',
	mustBeLessThanMaxValue: 'La valeur doit être inférieure à la valeur maximale',
	visibleForUsers: 'Visible pour les utilisateurs',
	no: 'Non',
	yes: 'Oui',
	merchantId: 'Identifiant du marchand',
	fullName: 'Nom complet',
	currency: 'Devise',
	update: 'Mise à jour',
	buyOffer: "Offre d'achat",
	maxOrders: 'Commandes maximales',
	newSellers: 'Nouveaux marchands',
	bestSellers: 'Meilleures ventes',
	minOrders: 'Commandes minimales',
	logout: 'Se déconnecter',
	companies: 'Entreprises',
	news: 'Nouvelles',
	privacyPolicy: 'politique de confidentialité',
	termsOfUse: "Conditions d'utilisation",
	merchantsOfferSubtitle:
		"Transactions sans couture, Possibilités illimitées : Élevez votre entreprise avec l'excellence P2P",
	limit: 'Limite',
	countries: 'Des pays',
	min: 'Le minimum',
	max: 'Maximum',
	all: 'Toute',
	merchantProfile: 'Profil marchand',
	availableLimit: 'Disponible / Limite',
	trade: 'Commerce',
	fees: 'Frais',
	reviews: 'Commentaires',
	buyers: 'Acheteurs',
	sellers: 'Les vendeurs',
	seller: 'Marchand',
	buyer: 'Acheteur',
	termsAndConditions: 'termes et conditions',
	unitPrice: 'Prix ​​unitaire',
	youSale: 'Vous vendez',
	merchantType: 'Type de marchand',
	youReceive: 'Vous recevez',
	selectPaymentMethods: 'Sélectionnez les méthodes de paiement',
	account: 'Comptes',
	note: 'Note',
	pleaseWriteYourNote: "S'il vous plaît écrivez votre note",
	certainPaymentMethodsMayHaveFees:
		'Certaines méthodes de paiement peuvent avoir des frais et des limites quotidiennes fixées par le fournisseur de paiement.',
	youPay: 'Tu payes',
	orderHeading: 'Commande',
	orderSubtitle: 'L histoire se répète : des réorganisations sans effort, à chaque fois !',
	dateRange: 'Plage de dates',
	show: 'Afficher',
	entries: 'Entrées',
	searchOrder: 'Rechercher une commande',
	id: 'Identifiant',
	client: 'Client',
	type: 'Type',
	creationDate: 'Date de création',
	availability: 'Disponibilité',
	buyersTerm: "Conditions et modalités de l'acheteur",
	sellersTerm: 'Conditions et modalités du vendeur',
	bankDeposit: 'Dépôt bancaire uniquement',
	accHolder: "Titulaire du compte d'origine",
	thirdParty: 'Paiement par tiers non accepté',
	wedFri: 'Mer-Ven',
	merchantReview: 'Avis du commerçant',
	apiNotFound: 'API introuvable',
	unableToAuthenticateTheUser: 'Session invalide ou expirée',
	somethingWentWrong: `Quelque chose s'est mal passé`,
	constraintViolated: 'Contrainte violée',
	recordIsLinked: `L'enregistrement est lié`,
	parametersCannotBeNull: 'Les paramètres ne peuvent pas être nuls',
	methodArgumentsAreInvalid: 'Les arguments de la méthode ne sont pas valides',
	fileUploadingError: 'Erreur de téléchargement de fichier',
	parameterIsNotValid: `Le paramètre de requête n'est pas valide`,
	requiredParametersAreMissing: 'Les paramètres requis sont manquants',
	pleaseEnterValidEmailAddress: `S'il vous plaît, mettez une adresse email valide`,
	passwordDoesNotMatchRequirements:
		'Le mot de passe ne correspond pas aux exigences : minimum 8 et maximum 16 caractères, dont au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
	invalidMerchantOfferRequest: `Demande d'offre marchande invalide`,
	invalidTradingAccountGroup: 'Groupe de comptes de trading invalide',
	countryIsNotAllowed: `Le pays n'est pas autorisé dans le système P2P`,
	orderIdProvidedIsInvalid: `Le numéro de commande fourni n'est pas valide`,
	orderIsClosed: `La commande est clôturée`,
	actionNotApplicable: 'Action non applicable',
	errorOccurredWhileProcessingYourRequest: `Une erreur s'est produite lors du traitement de votre demande`,
	problemOccurredWhileLoadingRecords: 'Un problème est survenu lors du chargement des enregistrements',
	problemOccurredWhileLoadingRecord: `Un problème est survenu lors du chargement de l'enregistrement`,
	problemOccurredWhileAddingRecord: `Un problème est survenu lors de l'ajout d'un enregistrement`,
	problemOccurredWhileUpdatingRecord: `Un problème est survenu lors de la mise à jour de l'enregistrement`,
	problemOccurredWhileDeletingRecord: `Un problème est survenu lors de la suppression de l'enregistrement`,
	recordAlreadyExists: `L'enregistrement existe déjà`,
	noRecordFound: 'Aucun Enregistrement Trouvé',
	accessDeniedMissingKyc: 'Accès refusé en raison de la disparition de Kyc',
	invalidCredentials: `les informations d'identification invalides`,
	invalidPaymentMethod: 'Mode de paiement invalide',
	invalidOfferType: `Type d'offre invalide`,
	orderIsInProgress: 'La commande est en cours',
	orderReviewIsAlreadyPresent: 'La revue de commande est déjà présente',
	orderIsClosedMoreThanWeekAgo: `La commande est clôturée il y a plus d'une semaine`,
	userIsInactive: `L'utilisateur est inactif`,
	userCountryNotAllowed: 'Pays utilisateur non autorisé',
	orderIsExpiredOrClosed: 'La commande est expirée ou fermée',
	invalidOrderAction: 'Action de commande invalide',
	onlyOneOrderAllowedAtTime: 'Une seule commande autorisée à la fois',
	invalidTradingAccount: 'Compte de trading invalide',
	merchantBelongsToDifferentCountry: `Impossible d'échanger! Le marchand appartient à un autre pays`,
	orderAmountIsMoreThanAvailableBalance: 'Le montant de la commande est supérieur au solde disponible!',
	merchantOfferNotAvailable: 'Offre marchande non disponible!',
	amountDoesNotSatisfyAvailableLimit: 'Le montant ne satisfait pas à la limite disponible!',
	countriesPaymentMethods: 'Pays / Méthodes de paiement',
	countriesPaymentSubtitle:
		"Effectuez des échanges P2P TetherUS aujourd'hui sans frais et avec votre méthode de paiement préférée !",
	countryCode: 'Code de pays',
	currencyCode: 'Code de devise',
	maxProfitRatio: 'Ratio de profit maximal',
	public: 'Public',
	administration: 'Administration',
	searchInCountriesAndPaymTable: 'Rechercher des pays/méthodes de paiement',
	welcomeBack: 'Content de te revoir',
	loginToAdminPortal: "Connectez-vous au portail d'administration",
	enterYourEmail: 'Entrer votre Email',
	enterPassword: 'Tapez votre mot de passe',
	readOur: 'Lisez notre',
	clients: 'Clients',
	clientProfile: 'Profil du Client',
	searchInClientsTable: 'Rechercher la table des clients',
	clientsPageHeading: "Centre d'autonomisation P2P : Connecter les utilisateurs, libérer les possibilités",
	orderWith: '{{type}} order from {{name}}',
	timeCreated: 'Time Created',
	orderNumber: 'Order Number',
	clientDetails: 'Client Details',
	merchantDetails: 'Merchant Details',
	orderDetails: 'Order Details',
	paymentDetails: 'Payment Details',
	clientPay: 'Client Pay',
	clientAccount: 'Client Account',
	merchantReceive: 'Merchant Receive',
	orderHistory: 'Order History',
	copiedSuccessfully: 'Copied Successfully',
	accountGroupLimits: 'Limites du Groupe de Comptes',
	orderMax: 'Commande Maximale',
	orderMin: 'Commande Minimum',
	tradingPlatform: "Plateforme d'échanges",
	tradingAccountGroup: 'Groupe de comptes de Trading',
	addAccountGroupLimits: 'Ajouter un nouveau groupe de comptes',
	submit: 'Soumettre',
	editAccountGroupLimits: 'Modifier la Limite du Groupe de Comptes',
	deleteConfirmation: 'Voulez-vous vraiment supprimer cet enregistrement ?',
	confirmDelete: 'Confirmation de suppression',
	ok: "D'accord",
	cancel: 'Annuler',
	supportRoom: 'Support Room',
	theorder: 'The Order',
	color: 'Coleur',
	backgroundColor: 'Fond de couleur',
	confirmNewPaymentMethodDelete: 'Voulez-vous vraiment supprimer ce mode de paiement ?',
	currencyCodeIsRequired: 'Le code de devise est requis',
	countryCodeIsRequired: 'Le code de pays est requis',
	countryNameIsRequired: 'Le nom du pays est requis',
	maxProfitRatioIsRequired: 'Le ratio de profit maximal est requis',
	reverseToPreviousStage: 'Reverse To Previous Stage',
	releasePayment: 'Release Payment',
	closeAppeal: 'Clocse Order',
	cancelOrder: 'Cancel Order',
	cancelThisOrder: 'Cancel This Order',
	cancelOrderMessage: 'I made sure that no payment was made by Client/Merchant',
	closeOrderMessage:
		'I made sure that both parties transferred the payment. On confirmation order will be marked as completed.',
	closeThisAppeal: 'Close This Appeal',
	releaseMerchantPayment: 'Release Merchant Payment',
	releaseMerchantPaymentMessage1: 'I have confirm that client has transferred the payment',
	releaseMerchantPaymentMessage2: 'I agree to release Merchant payment to the Client.',
	releaseMerchantPaymentMessageSell: 'I have confirm that Merchant has transferred the payment',
	releaseMerchantPaymentMessageSell2: 'I agree to release clinet payment to the merchant.',
	revertToPreviousStage: 'Revert To Previous Stage',
	revertToPreviousStageMessage: 'Are you sure that you want to change order status from Appeal to Transferred?',
	Assignee: 'Assignee',
	Resolved: 'Resolved',
	AssignedOn: 'Resolved',
	Reassign: 'Reassign',
	invalidmerchantOffer: 'Invalid Merchant Offer',
	invalidtradingaccountgroup: 'Invalid Trading account group',
	orderidisinvalid: 'Order ID provided is Invalid',
	Orderisclosed: 'Order is closed',
	invalidorderaction: 'Invalid Order Action',
	onlyoneorderallowed: 'Only one order allowed at time',
	cannotttrade: 'Cant trade, merchant belongs to different country',
	orderamountismorethanbalance: 'Order amount is more than available balance!',
	merchantoffernotavailable: 'Merchant Offer not available!',
	Amountdoesnotsatisfyavailablelimit: 'Amount does not satisfy available limit!',
	ErroroccurredwhileprocessingMT5request: 'Error occurred while processing Compte de trading request.!',
	Amountdoesnotsatisfyofferallowedlimit: 'Amount does not satisfy offer allowed limit!',
	cancelAppeal: 'Cancel Appeal',
	revert: 'REVERT',
	release: 'RELEASE',
	paymentMethodName: 'Nom du Mode de Paiement',
	adminusers: 'Admin Users',
	admin: 'Admin',
	adminDetailsforAddEditAdmin: 'Unité d administration : Ajouter des leaders, Élever l excellence ensemble',
	emailAddress: 'Email Address',
	role: 'Role',
	phoneNumber: 'Phone Number',
	canHandleAppeal: 'Can Handle Appeal',
	addNewAdmin: 'Add new Admin',
	lastName: 'Last Name',
	accountGroups: 'Groupes de Comptes',
	notificationsHeading: 'Notifications',
	noNotificationsFound: "Aucune notification n'a été trouvé",
	opened: 'Ouvert',
	appeal: 'Appel',
	expired: 'Expiré',
	placed: 'Placé',
	unknown: 'Inconnu',
	quantity: 'Quantity',
	notAvailable: 'non disponible',
	currentpassword: 'Current Password',
	newpassword: 'New Password',
	matchedPassword: 'The new password that you entered do not match!',
	appealTask: 'Appeal Task',
	paymentReceived: 'Payment Received',
	activeTask: 'Active Task',
	paymentTransfered: 'Payment Transfered',
	accepted: 'Accepted',
	notificationNewAppealTaskAssigned:
		'Un appel a été ouvert pour la commande de type {{orderType}} numéro #{{orderNumber}} pour un montant de {{amount}}.',
	notificationAppealReassigned:
		"Votre tâche d'appel a été réaffectée à {{newAssigneeFullName}} en raison de {{reassignMethod}}.",
	notificationAppealNewMessage: 'Nouveau message dans la commande #{{orderNumber}} à {{createdAt}}.',
	notificationAppealReminder: 'Vous avez un appel en attente qui sera réaffecté dans {{reassignedInMinutes}} heures.',
	seeMore: 'Voir plus',
	finishedSuccessfuly: 'Terminé avec succès',
	adminUser: 'Admin user',
	enterValidValue: 'Entrer une valeur valide',
	requiredField: 'Champ obligatoire',
	resolvedBy: 'Resolved By',
	assigneToMe: 'Assigner à moi',
	averageRating: 'Average Rating',
	orderMaxShouldBeGreaterThanOrderMin: 'Le montant maximum doit être plus grand que le montant minimum',
	orderMinShouldBeLessThanOrderMax: 'Le montant minimum doit être plus petit que le montant maximum',
	merchantPay: 'Merchant Pays',
	orderMaxShouldNotBeGreaterThanFiftyThousand: 'Le montant maximum ne doit pas être plus grand que 50,000',
	orderMinShouldNotBeGreaterThanFiftyThousand: 'Le montant minimum ne doit pas être plus grand que 50,000',
	buyMin: 'Achat Min',
	buyMax: 'Achat Max',
	sellMin: 'Vente Min',
	sellMax: 'Vente Max',
	accessDenied: 'Accès refusé',
	orderNumberProvidedIsInvalid: `Le numéro de commande fourni n'est pas valide`,
	errorProccessingMT5: 'Erreur lors du traitement de Compte de trading',
	amountDosentSatisfyLimit: 'La quantité dosée satisfait à la limite',
	chatHasBeenClosed: 'Le chat a été fermé',
	emailAlreadyExist: `L'e-mail existe déjà`,
	phoneNumberDoesNotMatchTheRequirement: 'Le numéro de téléphone ne correspond pas aux exigences',
	newPasswordAndConfirmPasswordNotSame: 'Nouveau mot de passe et confirmation du mot de passe différent',
	userIsSuspended: `L'utilisateur est suspendu`,
	userIsBlocked: `L'utilisateur est bloqué`,
	merchantNotFound: 'Marchand introuvable',
	invalidOTP: 'Mot de passe unique invalide',
	suspendedConfirmation: 'Êtes-vous sûr de vouloir suspendre',
	unsuspendedConfirmation: 'Êtes-vous sûr de vouloir annuler la suspension',
	blockConfirmation: 'Êtes-vous sûr de vouloir bloquer',
	unblockConfirmation: 'Êtes-vous sûr de vouloir débloquer',

	notificationNewOrderMessage: `Nouvelle commande {{orderType}} #{{orderNumber}} pour un montant de {{amount}} a été ouverte.`,
	notificationOrderNewMessage: `Nouveau message dans la commande #{{orderNumber}} à {{createdAt}}`,
	notificationOrderExpiryWarning: `Votre commande #{{orderNumber}} est sur le point d'expirer à {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `La commande #{{orderNumber}} a expiré.`,
	notificationOrderStatusUpdate: `Changement de statut de la commande #{{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `Votre offre {{offerType}} a été retirée en raison de {{reason}}`,
	notificationAccountBalanceLow: `Le solde du compte de votre commerçant est faible. Solde actuel : {{amount}}`,
	notificationSellAvailableLow: `La quantité d'offre de vente que vous avez spécifiée est sur le point de s'épuiser. Mettez à jour la quantité pour que l'offre reste valable. Solde actuel de vente disponible : {{sellAvailable}}`,
	yesterday: 'Hier',
	today: 'Aujourd’hui',
	daysAgo: 'Il y a',
	withdrawal: 'Retrait',
	deposit: 'Dépôt',
	dashboardActiveOrders: 'Commandes en cours',
	dashboardAppealOrders: 'Appels en attente',
	dashboardTotalOrders: 'Total des commandes',
	dashboardTotalMerchants: 'Total des commerçants',
	dashboardTotalClients: 'Total des clients',
	reports: 'Report',
	thisMonth: 'Ce mois',
	thisYear: 'Cette année',
	thisWeek: 'Cette semaine',
	thisQuarter: 'Ce trimestre',
	last60Days: '60 derniers jours',
	totalAnalyticsOrder: 'Total des commandes',
	buyOrders: 'Achats',
	sellOrders: 'Ventes',
	myOffersSubtitle: 'Ce sont les offres qui seront présentées à vos clients sur Merchant.',
	calculatedPrice: 'Final Offer Price',
	notFound: 'Order Not Found',
	actions: 'Actions',
	orderId: 'Order ID',
	noPaymentMethod: 'No Payment Method',
	buyMaxShouldNotBeGreaterThanFiftyThousand: 'Le montant maximum ne doit pas être plus grand que 50,000',
	buyMinShouldNotBeGreaterThanFiftyThousand: 'Le montant minimum ne doit pas être plus grand que 50,000',
	sellMaxShouldNotBeGreaterThanFiftyThousand: 'Le montant maximum ne doit pas être plus grand que 50,000',
	sellMinShouldNotBeGreaterThanFiftyThousand: 'Le montant minimum ne doit pas être plus grand que 50,000',
	sellMaxShouldBeGreaterThanSellMin: 'Le montant maximum doit être supérieur au montant minimum',
	buyMaxShouldBeGreaterThanBuyMin: 'Le montant maximum doit être supérieur au montant minimum',
	sellMinShouldBeLessThanSellMax: 'Le montant minimum doit être inférieur au montant maximum',
	buyMinShouldBeLessThanBuyMax: 'Le montant minimum doit être inférieur au montant maximum',
	invalidType: 'Type de fichier non valide. Veuillez télécharger un fichier JPG, JPEG, PNG ou PDF.',
	fileExceedLimit: 'La taille du fichier dépasse la limite autorisée (5 Mo).',
	expireIn: 'Expire dans',
	enterOtp: 'Entrez le code OTP',
	exchangeRate: 'Taux de change',
	sortBy: 'trié par',
	enterYourOtp: 'Entrez le code de vérification de votre e-mail',
	notificationPendingAppeal:
		'L appel avec {{orderNumber}} n a pas reçu de réponse de la part de {{oldAssigneeFullName}} en {{inactiveTimeMinutes}} minutes. Cliquez ici pour ',
	cannotEnableOffer: "Impossible d'activer l'offre en raison d'un solde insuffisant",
	ActivateConfirmation: 'Êtes-vous sûr de vouloir activer',
	deactivateConfirmation: 'Êtes-vous sûr de vouloir désactiver',
	passwordChange: 'Changer le mot de passe',
	didNotreceiveOtp: 'Nie otrzymałeś kodu?',
	resend: 'Renvoyer',
	biggerThanZero: 'La valeur doit être supérieure à 0',
	lessThanhundred: 'La valeur doit être inférieure à 100 ou égale à 100',
	inactive: 'Inactif',
	appealReassignNewAdmin:
		"Nouvelle tâche d'appel vous a été réattribuée par {{oldAssigneeFullName}} par {{reassignMethod}} affectation",
	NEW_ORDER: 'Nouvelle commande',
	ORDER_NEW_MESSAGE: 'Nouveau message dans la commande',
	ORDER_EXPIRY_WARNING: "Avertissement d'expiration de commande",
	ORDER_EXPIRED: 'Commande expirée',
	ORDER_STATUS_UPDATE: 'Mise à jour du statut de la commande',
	OFFER_DISABLED: 'Offre désactivée',
	ACCOUNT_BALANCE_LOW: 'Solde du compte faible',
	SELL_AVAILABLE_LOW: 'Disponibilité de vente faible',
	NEW_APPEAL_TASK_ASSIGNED: "Nouvelle tâche d'appel assignée",
	APPEAL_NEW_MESSAGE: "Nouveau message dans l'appel",
	APPEAL_REMINDER: "Rappel d'appel",
	PENDING_APPEAL: 'Appel en attente',
	APPEAL_REASSIGNED: 'Appel réaffecté',
	APPEAL_REASSIGNED_NEW_ADMIN: 'Appel réaffecté à un nouvel administrateur',
	handleAppeal: 'Traiter l appel',
	appealTasks: 'Tâches d appel',
	transferred: 'Transféré',
	completed: 'Terminé',
	rejected: 'Rejeté',
	INSUFFICIENT_BALANCE: 'Solde insuffisant',
	COOLDOWN: 'Période de refroidissement',
	BY_ADMIN: "Par l'administrateur",
	MANUAL: 'Manuel',
	INACTIVE: 'Inactif',
	typeMessageHere: 'Tapez un message ici',
	OTPIsrequired: 'OTP est requis !',
	Pleaseuploadthepaymentproof: 'Veuillez télécharger la preuve de paiement',
	agent: 'Agent',
	chatIsDisabled: 'Le chat est désactivé',
	sessionExpired: 'Session expirée',
	block: 'Bloc',
	unblock: 'Débloquer',
	suspend: 'Suspendre',
	unsuspend: 'Annuler la suspension',
	search: 'recherche',
	allRightsReserved: 'Tous les droits sont réservés',
	customeRange: 'Plage personnalisée',
	confirm: 'Confirmer',
	suspendClient: 'Suspendre le client',
	suspendMerchant: 'Suspendre le commerçant',
	unsuspendMerchant: 'Rétablir le commerçant',
	unsuspendClient: 'Rétablir le client',
	download: 'Télécharger',
	chatnow: 'Discutez maintenant',
	dashboardTotalWithdrawalAmount: 'Montant total des retraits',
	dashboardTotalDepositAmount: 'Montant total des dépôts',
	completionRate: 'Taux de réalisation',
	merchantDetailsDescription:
		'Découvrez des profils de commerçants détaillés et leurs avis, offrant des informations précieuses pour améliorer votre expérience de trading P2P Tether USDt.',
	merchantsReviewSubtitle: ' Cultiver la confiance et la qualité à travers la plateforme',
	appealTaskSubtitle: ' Quand les défis rencontrent des résolutions autonomes',
	CountryAndPaymentMethods: 'Précision dans chaque transaction : Adapter les pays et les paiements pour votre succès',
	accountGroupLimitsSubtitle: 'L administrateur forge le succès à travers les limites de commande',
	tradingPaltform: 'Plateforme de trading',
	accountGroup: 'Groupe de compte',
	accountNumber: 'Numéro de compte',
	balance: 'Solde',
	onHoldBalance: 'Solde en attente',
	otpexpired: 'Le code OTP a expiré!',
	reachedotplimit: 'Vous avez atteint le nombre maximum de tentatives pour l OTP!!',
	maintenance: 'Maintenance',
	maintenanceSubtitle: 'Rapprocher les mains pour des solutions de maintenance transparentes',
	startTime: 'Heure de début',
	endTime: 'Heure de fin',
	enabled: 'Activé',
	deleteMaintanceConfirmation: 'Êtes-vous sûr de vouloir supprimer cette maintenance ?',
	annoncement: 'Maintenance planifiée du portail de {{from}} à {{to}}',
	depositBouns: 'Bonus de dépôt',
	withdrawBouns: 'Bonus de retrait',
	CurrencySubtitle: 'Élargissez votre portée, ajoutez des devises dès aujourd hui',
	add: 'Ajouter',
	INVALID_MIN_MAX_RANGE: 'Plage minimale à maximale non valide',
	FORM_IS_INVALID: "Le formulaire n'est pas valide",
	walletTransactions: 'Transactions de portefeuille',
	walletTransactionsDescription: 'Améliorez votre expérience P2P avec notre portefeuille polyvalent',
	assignee: 'Cessionnaire',
	assignedOn: 'Attribué le',
	reassign: 'Réaffecter',
	approve: 'Approuver',
	reject: 'Rejeter',
	withdrawCommission: 'Retirer la commission',
	wallet: 'Portefeuille',
	contactUser: "Contacter l'utilisateur",
	transactionReport: 'Rapport de transaction',
	searchWallet: 'Rechercher un portefeuille',
	user: 'Utilisatrice',
	walletNumber: 'Numéro de portefeuille',

	PENDING: 'En attente',
	COMPLETED: 'Complété',
	REVERTED: 'Rétablie',
	PENDING_APPROVAL: 'En attente de validation',
	CANCELLED: 'Annulé',
	P2P_BUY: 'Acheter P2P',
	P2P_SELL: 'Vente P2P',
	P2P_REFUND: 'Remboursement P2P',
	TRADING_DEPOSIT: 'Dépôt de trading',
	TRADING_WITHDRAW: 'Retrait de trading',
	EXCHANGE: 'Échange',

	WALLET: 'Portefeuille',
	canHandleRequest: 'Peut gérer la demande',

	offers: 'Offres',
	tradingAccount: 'Compte de trading',
	walletsAccount: 'Comptes de portefeuille',
	Currencycodealreadyexist: 'Le code de devise existe déjà',
	Walletnotexist: "Le portefeuille n'existe pas",
	Walletbalanceunsatisfied: 'Solde du portefeuille insatisfaisant',
	Merchantwalletscurrencyisdifferent: 'La devise des portefeuilles marchands est différente',
	Walletonholdbalanceunsatisfied: 'Solde du portefeuille en attente insatisfaisant',
	Erroroccurredwhiledoingtransaction: "Une erreur s'est produite lors de la transaction",
	Youarenotallowedtoperformthisaction: "Vous n'êtes pas autorisé à effectuer cette action",
	p2pBuyOrder: 'Ordre d achat P2P',
	p2pSellOrder: 'Ordre de vente P2P',
	depositToTradingAccount: 'Dépôt sur le compte de trading',
	withdrawFromTradingAccount: 'Retrait du compte de trading',
	actionBy: 'Action par',

	requestConfirmation: 'Êtes-vous sûr de vouloir {{type}} cette transaction ?',
	userName: 'Nom d utilisateur',
	transactionNumber: 'Numéro de transaction',
	sendEmail: '"Envoyer un e-mail',
	withdrawRequest: 'Demande de retrait',
	withdrawAmount: 'Montant du retrait',
	transactionId: 'Identifiant de transaction',

	REQUEST_REMINDER: 'RAPPEL DE DEMANDE',
	PENDING_REQUEST: 'DEMANDE EN ATTENTE',
	REQUEST_REASSIGNED: 'DEMANDE RÉAFFECTÉE',
	REQUEST_REASSIGNED_NEW_ADMIN: 'DEMANDE RÉAFFECTÉE AU NOUVEL ADMINISTRATEUR',
	P2P_TRANSACTION_REQUESTS: 'DEMANDES DE TRANSACTION P2P',
	requestRemindar: 'Vous avez une demande en attente qui sera réaffectée dans {{reassignedInMinutes}} heures',
	pendingRequest:
		'La demande de transaction avec {{transactioNumber}} n a pas reçu de réponse de la part de {{oldAssigneeFullName}} dans les {{inactiveTimeMinutes}} heure(s), cliquez ici pour réaffecter.',
	requestReassign:
		'Votre demande de transaction a été réaffectée à {{newAssigneeFullName}} par affectation {{reassignMethod}}',
	requestReassignedNewAdmin:
		'La nouvelle demande de transaction vous a été réaffectée par {{oldAssigneeFullName}} par affectation {{reassignMethod}}',
	p2pTransactionRequest:
		'Vous avez effectué une {{transactionType}} de {{amount}}. Votre demande de {{transactionType}} de {{amount}} a été {{requestState}}.',
	OrderWalletTransaction: 'Transaction de portefeuille de commande',
	depositWithdrawRequest: 'j accepte de {{type}} la transaction de {{amount}}',
	approveTransaction: 'Approuver la transaction',
	rejectTransaction: 'Rejeter la transaction',
	transactionRequest:
		'Une demande {{transactionType}} avec le numéro {{transactionNumber}} vous est assignée pour un montant de {{amount}}',
	TRANSACTION_REQUEST: 'Demande de transaction',
	handleRequest: 'gérer la demande',
	ordersAmountStatistic: 'Statistique du montant des commandes',
	searchAdmin: 'Rechercher un administrateur',
	reassignSuccessfully: 'Réaffectation réussie',
	buyAmount: 'Montant du dépôt',
	sellAmount: 'Montant de la vente',
	validationChatMessage: 'les caractères spéciaux <, >, & ne sont pas autorisés.',
	withdrawRequesttitle: 'Retrait du portefeuille',
	depositRequestTitle: 'Dépôt dans le portefeuille',
	withdrawRequestSubtitle: 'Le montant sera débité du portefeuille dans une minute.',
	depositRequestSubtitle: 'Le montant sera crédité sur le portefeuille dans une minute.',
	withdrawAction: 'retrait',
	depositAction: 'dépôt',
	errorAvalibleWalletBalance: 'doit être inférieur au solde disponible du portefeuille',
	successfullyWithdraw: 'Retrait réussi de {{amount}} {{currency}}',
	successfullyDeposit: 'Dépôt réussi de {{amount}} {{currency}}',
	merchantsDashborad: 'Tableau de bord des commerçants',
	depositOrder: 'Ordre de dépôt',
	withdrawOrder: 'Ordre de retrait',
	completedOrders: 'Commandes terminées',
	cancelledOrders: 'Commandes annulées',
	completedOrdersSubtitle: 'Commandes complétées par le commerçant.',
	cancelledOrdersSubtitle: 'Commandes annulées par le commerçant.',
	expiredOrders: 'Commandes expirées',
	expiredOrdersSubtitle: 'Commandes expirées du commerçant.',
	appealOrders: 'Commandes en appel',
	appealOrdersSubtitle: 'Commandes en appel du commerçant.',
	CustomerReport: 'Rapport client',
	withdraw: 'Retirer',
	typeSearch: 'Tapez pour rechercher',
	customers: 'Clients',
	repeatedOrders: 'Commande répétée par les clients',
	oldCustomers: 'Commande par les anciens clients',
	newCustomers: 'Commande par les nouveaux clients',
	report: 'Rapport',
	noData: 'Pas de données',
	repeated: 'Répété',
	new: 'Nouveau',
	old: 'Ancien',
	clientsDashborad: 'Tableau de bord des clients',
	maintanceError: 'La date de fin doit être après la date de début',
	otpResendMessage: 'Code OTP renvoyé avec succès',
	otpSendMessage: 'Code OTP envoyé avec succès',
	FileExtensionNotSupported: 'Extension de fichier non prise en charge',
	FileTooLarge: 'Le fichier est trop volumineux',
	InvalidFile: 'Fichier invalide',
	ADMIN_WALLET_DEPOSIT: 'Dépôt de portefeuille administrateur',
	ADMIN_WALLET_WITHDRAW: 'Retrait de portefeuille administrateur',
	contactInfo: 'Informations de contact',
	frozenBalance: 'Solde gelé',
	walletBalance: 'Solde disponible',
	ACCEPTED: 'Accepté',
	REJECTED: 'Rejeté',
	SimultaneousTransactions: 'Transactions simultanées non autorisées',
	recaptchaError: "Votre demande n'a pas pu être complétée. Veuillez actualiser la page et réessayer",
	'Currency/Wallet': 'Devise/Portefeuille',
	createdBy: 'Créé par',
	both: 'Seller and Buyer',
	Comment: 'Comment',
	AddyourComments: 'Add your Comments',
	withdrawRequests: 'Demandes de retrait',
	suspended: 'Suspendu',
	accept: 'Accept',
	'Filter Options': 'Options de filtrage',
	languageError: 'Impossible de mettre à jour la même langue',
	'Country/Payment Methods': 'Pays/Méthodes de paiement',
	Message: 'Message',
	'Select Country': 'Select Country',
	valueBetweenZeroAandhundred: 'Value Should be between 0 and 100',
	accountGroupName: 'Nom du groupe de comptes',
	'TradingAccount(s)NotSupported': 'Compte(s) de trading non pris en charge dans le système P2P',
	blocked: 'BLOCKED',
	dashboardTotalBalance: 'Solde total',
	dashboardClientsBalance: 'Solde des clients',
	dashboardMerchantsBalance: 'Solde des commerçants',
	complained: 'Réclamé',
	dashboardClientsWalletsBalance: 'Solde des clients',
	dashboardMerchantsWallestsBalance: 'Solde des commerçants',
	withdrawalOffer: 'Oferta wypłaty',
	depositOffer: 'Oferta depozytu',
	availableDeposit: 'Dostępne',
	minimumAmountDeposit: 'Minimalna kwota',
	maximumAmountDeposit: 'Maksymalna kwota',
	availableWithdraw: 'Dostępne',
	minimumAmountWithdraw: 'Minimalna kwota',
	maximumAmountWithdraw: 'Maksymalna kwota',
	amountmustbelessThanavailabel: 'Kwota musi być mniejsza niż dostępne saldo {{amount}} {{currency}}',
	amountmustbeBiggerThanMinmum: 'Kwota musi być większa niż minimalna',
	minAllowedValue: 'Minimalna kwota musi być większa lub równa {{amount}} {{currency}}',
	maxAllowedValue: 'Minimalna kwota musi być większa lub równa {{amount}} {{currency}}',
	maxValueRange: 'Maksymalna wartość powinna być między 1 - {{amount}}',
	noteMinLength: 'Notatka powinna mieć więcej niż 20 znaków',
	noteMaxLength: 'Notatka powinna mieć mniej niż 1000 znaków',
	noteIsRequred: 'Veuillez entrer une note',
};
