import { useTranslation } from 'react-i18next';
import { OrderType } from '../../types/OrderType';
import './OrderLifeCycleHeader.scss';
import { useMemo } from 'react';
import { Skeleton, notification } from 'antd';
import { CopyFilled } from '@ant-design/icons';

type OrderLifeCycleHeaderProps = {
	orderType: OrderType;
	user: string;
	orderNumber: string;
	openTime: string;
};

function OrderLifeCycleHeader({ orderType, user, orderNumber, openTime }: OrderLifeCycleHeaderProps) {
	const { t } = useTranslation();
	const isSellOrder = useMemo(() => orderType === 'SELL', [orderType]);
	const isDev = window.location.hostname.includes('devb');
	const orderTypeLabel = useMemo(
		() => (isSellOrder ? t<string>(isDev ? 'sell' : 'withdrawal') : t<string>(isDev ? 'buy' : 'deposit')),
		[isSellOrder, t, isDev],
	);

	const copyMyText = () => {
		let textToCopy = document.getElementById('orderNumber') as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};
	return (
		<section className='order-life-cycle-header-main-wrapper'>
			<div className='order-life-cycle-header-wrapper'>
				{orderNumber ? (
					<>
						<div className='order-life-cycle-left-side'>
							<div className='order-life-cycle-details'>
								<span
									className='order-life-cycle-type-status mx-2'
									style={{ backgroundColor: isSellOrder ? '#F6465D' : '#33A867' }}
								>
									{orderTypeLabel}
								</span>
								<h3 className='order-life-cycle-user'>
									{t<string>('orderWith', {
										type: isSellOrder ? t<string>(isDev ? 'sell' : 'withdrawal') : t<string>(isDev ? 'buy' : 'deposit'),
										name: user,
									})}
								</h3>
							</div>
						</div>
						<div className='order-life-cycle-right-side mt-2 xss:flex justify-between md:flex-col xss:w-full md:w-[50%]'>
							<div className='order-life-cycle-right-side-row xss:flex-col md:flex-row'>
								<div className='label mx-2'>{t<string>('orderNumber')}</div>
								<div>
									<span id='orderNumber'>{orderNumber}</span>
									<CopyFilled className='mx-2 cursor-pointer' onClick={copyMyText} />
								</div>
							</div>
							<div className='order-life-cycle-right-side-row xss:flex-col md:flex-row'>
								<div className='label mx-2'>{t<string>('timeCreated')}</div>
								<p>{openTime}</p>
							</div>
						</div>
					</>
				) : (
					<Skeleton active paragraph={{ rows: 1 }} />
				)}
			</div>
		</section>
	);
}

export default OrderLifeCycleHeader;
