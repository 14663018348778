import styled from 'styled-components';

export const StyledPagination = styled.div<{ bgColor: string; color: string; isClikable?: boolean }>`
	.ant-pagination-item-active {
		background-color: ${({ bgColor }) => bgColor} !important;
		opacity: 0.9;
	}
	.ant-pagination .ant-pagination-item-active a {
		color: ${({ color }) => color} !important;
	}
	tr {
		cursor: ${({ isClikable }) => (isClikable ? 'pointer' : 'unset')} !important;
	}
`;
