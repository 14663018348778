import { Button, Form, Input, InputNumber, Select, Spin } from 'antd';
import { ValidateStatus } from 'antd/es/form/FormItem';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useRouteContext } from '../../components/CurrentRouteProvider';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { AdminGroup, Group } from '../../models/AdminGroup';
import { OTPModal } from '../Country/OTPModal';

import './AccountGroup.scss';
import { useConfigurationContext } from '../../store/configurationContext';
import { headerImage as defaultheaderImage } from '../../constant/fallback-config';
interface FieldItem {
	value: string;
	errorMessage: string;
	validationStatus: ValidateStatus;
}

const AccountGroup = () => {
	const params = useParams();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const [tradingPlatformsList, setTradingPlatformsList] = useState<AdminGroup[]>([]);
	const [tradingPlatforms, setTradingPlatforms] = useState<string[]>([]);
	const [tradingGroups, setTradingGroups] = useState<Group[]>([]);
	const [tradingGroup, setTradingGroup] = useState<any>();
	const [selectedTradingPlatform, setSelectedTradingPlatform] = useState('');

	/**
	 * This is the currency text in the dropdown for Deposit Bonus and Withdraw Bonus.
	 * It is changed according to the selected group's currency.
	 *
	 * @see {Group}
	 */
	const [currencyCode, setCurrencyCode] = useState<string | null>(null);

	const [selectedBuyMin, setSelectedBuyMin] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [selectedBuyMax, setSelectedBuyMax] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [selectedSellMax, setSelectedSellMax] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [selectedSellMin, setSelectedSellMin] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [groupName, setGroupName] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [depositBonus, setDepositBonus] = useState<null | number>(null);
	const [withdrawRatio, setWithdrawRatio] = useState<boolean>(true);
	const [withdrawBonus, setWithdrawBonus] = useState<null | number>(null);
	const [depositRatio, setDepositRatio] = useState<boolean>(true);

	const [customErrorDeposit, setCustomErrorDeposit] = useState('');
	const [customErrorWithdraw, setCustomErrorWithdraw] = useState('');

	const maxOrderLimit = 50000;
	const [loading, setLoading] = useState(false);
	const [showOTPModal, setShowOTPModal] = useState(false);
	const token = getUserInfo()?.token;
	const queryParams = new URLSearchParams(location.search);
	const { routeVariable, updateRouteVariable } = useRouteContext();

	const buyMin = queryParams.get('buyMin') ?? '';
	const buyMax = queryParams.get('buyMax') ?? '';
	const sellMin = queryParams.get('sellMin') ?? '';
	const sellMax = queryParams.get('sellMax') ?? '';
	const depositBonusParams = queryParams.get('depositBonus') ?? '';
	const withdrawCommission = queryParams.get('withdrawCommission') ?? '';
	const depositRatioParams = queryParams.get('depositRatio') ?? '0';
	const withdrawRatioParams = queryParams.get('withdrawRatio') ?? '0';
	const groupNameParams = queryParams.get('groupName') ?? '';

	/**
	 * Load all admin groups for the form.
	 */
	useEffect(() => {
		axios
			.get<AdminGroup[]>(`${API_ENDPOINTS.accountGroups}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((response) => {
				setTradingPlatformsList(response.data);
				setTradingPlatforms(response.data.map((adminGroup) => adminGroup.tradingPlatform));
			});
	}, [token]);

	useEffect(() => {
		if (selectedTradingPlatform) {
			const groups = tradingPlatformsList.find((platform) => platform.tradingPlatform === selectedTradingPlatform)
				?.groups;

			if (groups) {
				setTradingGroups(groups);

				const tradingAccountGroups = queryParams.get('tradingAccountGroups');
				if (tradingAccountGroups) {
					const currencyCode = tradingGroups.find((tradingGroup) => tradingGroup.groupId === tradingAccountGroups)
						?.currencyCode;
					setCurrencyCode(currencyCode ?? 'USD');
				}
			}
		}
	}, [selectedTradingPlatform, tradingPlatformsList]);

	useEffect(() => {
		updateRouteVariable('/account-group');
	}, [location.pathname, updateRouteVariable]);

	const submitRecord = (otp: string) => {
		setLoading(true);
		axios(`${API_ENDPOINTS.accountGroupsLimits}`, {
			method: params.tradingPlatform ? 'PUT' : 'POST',
			headers: { Authorization: `Bearer ${token}` },
			data: {
				sellMin: selectedSellMin.value,
				sellMax: selectedSellMax.value,
				buyMin: selectedBuyMin.value,
				buyMax: selectedBuyMax.value,
				group: tradingGroup,
				groupName: groupName.value,
				tradingPlatform: selectedTradingPlatform,
				depositBonus: depositRatio && depositBonus ? depositBonus / 100 : depositBonus,
				withdrawCommission: withdrawRatio && withdrawBonus ? withdrawBonus / 100 : withdrawBonus,
				depositRatio,
				withdrawRatio,
				otp: otp,
			},
		})
			.then((_) => navigate('/account-groups'))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (!selectedTradingPlatform && params.tradingPlatform) {
			setSelectedTradingPlatform(params.tradingPlatform);
			const tradingAccountGroups = queryParams.get('tradingAccountGroups');
			if (tradingAccountGroups) {
				setTradingGroup(tradingAccountGroups);
			}

			setSelectedBuyMin({ value: buyMin, errorMessage: '', validationStatus: 'success' });
			setSelectedSellMin({ value: sellMin, errorMessage: '', validationStatus: 'success' });
			setSelectedBuyMax({ value: buyMax, errorMessage: '', validationStatus: 'success' });
			setSelectedSellMax({ value: sellMax, errorMessage: '', validationStatus: 'success' });
			setGroupName({ value: groupNameParams, errorMessage: '', validationStatus: 'success' });
			setDepositBonus(depositRatioParams === '1' ? Number(depositBonusParams) * 100 : Number(depositBonusParams));
			setWithdrawBonus(withdrawRatioParams === '1' ? Number(withdrawCommission) * 100 : Number(withdrawCommission));
			setDepositRatio(depositRatioParams === '1');
			setWithdrawRatio(withdrawRatioParams === '1');
		}
	}, [selectedTradingPlatform]);

	const selectAfterWithdraw = (
		<Select
			className='w-20 select-after'
			value={withdrawRatio}
			defaultValue={withdrawRatio}
			onChange={setWithdrawRatio}
		>
			{currencyCode && <Select.Option value={false}>{currencyCode}</Select.Option>}
			<Select.Option value={true}>%</Select.Option>
		</Select>
	);

	const selectAfterDeposit = (
		<Select className='w-20 select-after' value={depositRatio} defaultValue={depositRatio} onChange={setDepositRatio}>
			{currencyCode && <Select.Option value={false}>{currencyCode}</Select.Option>}
			<Select.Option value={true}>%</Select.Option>
		</Select>
	);

	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';

	const isValid = useMemo(() => {
		const deposit = depositRatioParams === '1' ? Number(depositBonusParams) * 100 : Number(depositBonusParams);
		const withdraw = withdrawRatioParams === '1' ? Number(withdrawCommission) * 100 : Number(withdrawCommission);
		if (
			!selectedBuyMin.value ||
			!selectedBuyMax.value ||
			!selectedSellMin.value ||
			!selectedSellMax.value ||
			!tradingGroup ||
			!tradingPlatforms ||
			!groupName.value
		)
			return false;
		if (
			selectedBuyMin.value === buyMin &&
			selectedBuyMax.value === buyMax &&
			selectedSellMin.value === sellMin &&
			selectedSellMax.value == sellMax &&
			groupName.value === groupNameParams &&
			Boolean(withdrawRatioParams === '1') === withdrawRatio &&
			Boolean(depositRatioParams === '1') === depositRatio &&
			withdraw === withdrawBonus &&
			deposit === depositBonus
		)
			return false;
		return true;
	}, [
		selectedBuyMin,
		selectedBuyMax,
		currencyCode,
		selectedSellMin,
		selectedSellMax,
		groupName,
		depositBonus,
		withdrawBonus,
		withdrawRatio,
		depositRatio,
	]);

	return (
		<>
			<div>
				<div className='merchants-head py-10' style={{ backgroundImage: `url(${headerImage})` }}>
					<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>
						{params.tradingPlatform ? t<string>('editAccountGroupLimits') : t<string>('addAccountGroupLimits')}
					</h1>
					<p className='text-white text-sm text-center'>{t<string>('clientsPageHeading')}</p>
				</div>
				<div className='border border-solid border-[#E6E6E6] rounded-md p-4 max-w-[900px] mx-auto py-10 my-10 bg-white'>
					<Spin spinning={loading}>
						<div>
							<Form layout='vertical'>
								<div>
									<div className='w-full flex justify-center'>
										<Form.Item required label={<b>{t<string>('tradingPlatform')}</b>} className='w-[300px]'>
											<Select
												defaultValue={''}
												value={selectedTradingPlatform}
												loading={false}
												onChange={(e: string) => {
													setSelectedTradingPlatform(e);
												}}
												disabled={Boolean(params.tradingPlatform)}
											>
												{tradingPlatforms.map((item, index) => (
													<Select.Option key={index} value={item}>
														{item}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item required label={<b>{t<string>('tradingAccountGroup')}</b>} className='w-[300px]'>
											<Select
												disabled={!selectedTradingPlatform || Boolean(params.tradingPlatform)}
												defaultValue={''}
												value={tradingGroup}
												loading={false}
												onChange={(e: string) => {
													setTradingGroup(e);
													let currencyCode = tradingGroups.find((tradingGroup) => tradingGroup.groupId === e)
														?.currencyCode;
													setCurrencyCode(currencyCode ?? 'USD');
												}}
											>
												{tradingGroups.map((item, index) => (
													<Select.Option key={index} value={item.groupId}>
														{item.groupId}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={groupName.validationStatus}
											help={groupName.validationStatus !== 'success' ? groupName.errorMessage : null}
											label={<b>{t<string>('accountGroupName')}</b>}
											className='w-[300px]'
										>
											<Input
												className='w-full'
												value={groupName.value}
												onChange={(e) => {
													const value = e.target.value;
													if (value) {
														setGroupName({
															value,
															errorMessage: '',
															validationStatus: 'success',
														});
													} else {
														setGroupName({
															value: '',
															errorMessage: t('requiredField'),
															validationStatus: 'error',
														});
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedBuyMin.validationStatus}
											help={selectedBuyMin.validationStatus !== 'success' ? selectedBuyMin.errorMessage : null}
											label={<b>{t<string>('buyMin')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedBuyMin.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedBuyMin({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) > parseFloat(selectedBuyMax.value)) {
															setSelectedBuyMin({
																value,
																errorMessage: t<string>('buyMinShouldBeLessThanBuyMax'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedBuyMin({
																value,
																errorMessage: t<string>('buyMinShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedBuyMin({ value, errorMessage: '', validationStatus: 'success' });
														}
													} else {
														setSelectedBuyMin({
															value: '',
															errorMessage: t('requiredField'),
															validationStatus: 'error',
														});
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedBuyMax.validationStatus}
											help={selectedBuyMax.validationStatus !== 'success' ? selectedBuyMax.errorMessage : null}
											label={<b>{t<string>('buyMax')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedBuyMax.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedBuyMax({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) < parseFloat(selectedBuyMin.value)) {
															setSelectedBuyMax({
																value,
																errorMessage: t<string>('buyMaxShouldBeGreaterThanBuyMin'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedBuyMax({
																value,
																errorMessage: t<string>('buyMaxShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedBuyMax({ value, errorMessage: '', validationStatus: 'success' });
														}
													} else {
														setSelectedBuyMax({
															value: '',
															errorMessage: t('requiredField'),
															validationStatus: 'error',
														});
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedSellMin.validationStatus}
											help={selectedSellMin.validationStatus !== 'success' ? selectedSellMin.errorMessage : null}
											label={<b>{t<string>('sellMin')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedSellMin.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedSellMin({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) > parseFloat(selectedSellMax.value)) {
															setSelectedSellMin({
																value,
																errorMessage: t<string>('sellMinShouldBeLessThanSellMax'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedSellMin({
																value,
																errorMessage: t<string>('sellMinShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedSellMin({ value, errorMessage: '', validationStatus: 'success' });
														}
													} else {
														setSelectedSellMin({
															value: '',
															errorMessage: t('requiredField'),
															validationStatus: 'error',
														});
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedSellMax.validationStatus}
											help={selectedSellMax.validationStatus !== 'success' ? selectedSellMax.errorMessage : null}
											label={<b>{t<string>('sellMax')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedSellMax.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedSellMax({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) < parseFloat(selectedSellMin.value)) {
															setSelectedSellMax({
																value,
																errorMessage: t<string>('sellMaxShouldBeGreaterThanSellMin'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedSellMax({
																value,
																errorMessage: t<string>('sellMaxShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedSellMax({ value, errorMessage: '', validationStatus: 'success' });
														}
													} else {
														setSelectedSellMax({
															value: '',
															errorMessage: t('requiredField'),
															validationStatus: 'error',
														});
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											label={<b>{t<string>('depositBouns')}</b>}
											className='w-[300px]'
											help={customErrorDeposit ? customErrorDeposit : null}
											validateStatus={customErrorDeposit ? 'error' : 'success'}
										>
											<InputNumber
												addonAfter={selectAfterDeposit}
												className='w-full'
												value={depositBonus}
												onChange={(value) => {
													setDepositBonus(value);
													if (depositRatio && value && (value < 0 || value > 100)) {
														setCustomErrorDeposit(t<string>('valueBetweenZeroAandhundred'));
													} else {
														setCustomErrorDeposit('');
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											label={<b>{t<string>('withdrawCommission')}</b>}
											help={customErrorWithdraw ? customErrorWithdraw : null}
											validateStatus={customErrorWithdraw ? 'error' : 'success'}
											className='w-[300px]'
										>
											<InputNumber
												addonAfter={selectAfterWithdraw}
												className='w-full'
												value={withdrawBonus}
												onChange={(value) => {
													setWithdrawBonus(value);
													if (withdrawRatio && value && (value < 0 || value > 100)) {
														setCustomErrorWithdraw(t<string>('valueBetweenZeroAandhundred'));
													} else {
														setCustomErrorWithdraw('');
													}
												}}
											/>
										</Form.Item>
									</div>
								</div>
							</Form>
							<div className='flex justify-center'>
								<Button
									disabled={
										!(
											selectedBuyMax.validationStatus === 'success' &&
											selectedBuyMin.validationStatus === 'success' &&
											selectedSellMax.validationStatus === 'success' &&
											selectedSellMin.validationStatus === 'success' &&
											groupName.validationStatus === 'success'
										) ||
										!isValid ||
										Boolean(customErrorDeposit) ||
										Boolean(customErrorWithdraw)
									}
									onClick={() => setShowOTPModal(true)}
									className='px-10 bg-[#01A2FF] font-bold'
									style={{ boxShadow: 'none' }}
									type='primary'
								>
									{t<string>('submit')}
								</Button>
							</div>
						</div>
					</Spin>
				</div>
			</div>
			<OTPModal
				showOTPModal={showOTPModal}
				setOTPModal={setShowOTPModal}
				handelAccept={submitRecord}
				code={''}
				getOtpUrl=''
				validateOtpUrl=''
				accountGroup={tradingGroup}
			/>
		</>
	);
};

export default AccountGroup;
