import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './SingleMerchant.scss';
import { LikeFilled, DislikeFilled } from '@ant-design/icons';
import MerchantReviewCarousal from '../../components/Merchants/MerchantReviewCarousal/MerchantReviewCarousal';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Merchant, Wallet } from '../../models/merchant';
import { getInitials } from '../../helpers/getStringInitials';
import { useCountriesContext } from '../../store/CountriesContext';
import { DashOutlined, PhoneFilled, RedEnvelopeFilled, HistoryOutlined, AreaChartOutlined } from '@ant-design/icons';

import { Divider, Grid, notification, Skeleton, Table, Typography } from 'antd';
import { UsersActions } from '../../components/UsersActions/UsersActions';
import { WalletsAccountscolumns } from '../../components/walletsAccountColumn/walletAccountColumn';
import { useMainContext } from '../../store/MainContext';
import { WithdrawDepositModal } from '../../components/walletRequestModal';

import OTPModal from '../../components/OTPModal/OTPModal';
import { useConfigurationContext } from '../../store/configurationContext';
import { StyledPagination } from '../../components/table.style';

const REVIEW_PERCENTAGE = 80;
const SingleMerchant = () => {
	const location = useLocation();
	const { mainState } = useMainContext();
	const { configurationState } = useConfigurationContext();
	const queryParams = new URLSearchParams(location.search);

	const [merchant, setMerchant] = useState<Merchant>();
	const [dataLoading, setDataLoading] = useState(false);
	const [requestType, setRequestType] = useState('');
	const [walletData, setWalletData] = useState<Wallet>();
	const [wallets, setWallets] = useState<Wallet[]>([]);
	const [action, setAction] = useState(queryParams.get('action') ?? 'offers');
	const [otpModal, setOTPModal] = useState(false);
	const [expireTime, setExpireTime] = useState(0);
	const [amount, setAmount] = useState<number>();
	const [comment, setComment] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [searchParams] = useSearchParams();
	const merchantId = useMemo(() => searchParams.get('id') || '', [searchParams]);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const token = getUserInfo()?.token;
	const { Text } = Typography;
	const { lg } = Grid.useBreakpoint();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	const allowed_admins_emails =
		configurationState.find((item) => item.configKey === 'allowed_admins_emails')?.value || '';
	const email = getUserInfo()?.email ?? '';
	const show = useMemo(() => {
		if (allowed_admins_emails) return allowed_admins_emails.includes(email);
		else if (getUserInfo()?.role === 'SUPER_USER') return true;
		else return false;
	}, [configurationState]);

	const { countriesState } = useCountriesContext();
	const buyOfferPaymMethods = countriesState.paymentMethods.filter(
		(method) => merchant?.buyerOffer.paymentMethods.includes(method.methodId || 0),
	);

	const sellOfferPaymMethods = countriesState.paymentMethods.filter(
		(method) => merchant?.sellerOffer.paymentMethods.includes(method.methodId || 0),
	);

	useEffect(() => {
		if (!merchantId) {
			navigate('/merchants');
		}
	}, [merchantId, navigate]);

	useEffect(() => {
		setDataLoading(true);
		axios
			.get(`${API_ENDPOINTS.getMerchant.replace('%merchantId%', merchantId)}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setMerchant(res.data);
				setWallets(res.data.wallets);
			})
			.finally(() => setDataLoading(false));
	}, []);

	const onRequest = (otp: string) => {
		setIsLoading(true);
		let payload = {
			type: requestType,
			quantity: amount,
			walletNumber: walletData?.number,
			userId: merchant?.userId,
			currency: walletData?.currency,
			otp: otp,
			comment: comment ? comment : undefined,
		};
		axios
			.post(API_ENDPOINTS.walletTransactions, payload)
			.then((res) => {
				const newWallet = wallets.map((wallet) => {
					if (wallet.number === res.data.walletNumber) {
						return {
							...wallet,
							balance: res.data.availableBalance,
						};
					} else {
						return wallet;
					}
				});
				setWallets(newWallet);
				if (requestType === 'WITHDRAW') {
					notification['success']({
						message: '',
						description: t<string>('successfullyWithdraw', {
							amount: amount,
							currency: walletData?.currency,
						}),
					});
				} else {
					notification['success']({
						message: '',
						description: t<string>('successfullyDeposit', {
							amount: amount,
							currency: walletData?.currency,
						}),
					});
				}
				setRequestType('');
				setOTPModal(false);
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const sendOtp = (value: number, resend?: boolean) => {
		let payload = {
			type: requestType,
			quantity: value,
			walletNumber: walletData?.number,
			userId: merchant?.userId,
			currency: walletData?.currency,
		};
		axios
			.post(API_ENDPOINTS.getWalletTransactionOTP, payload)
			.then((res) => {
				console.log(res.data.remainingExpiryTime);
				setExpireTime(res.data.remainingExpiryTime);
				if (!otpModal) setOTPModal(true);
				if (resend) {
					notification['success']({ message: '', description: t<string>('otpResendMessage') });
				}
			})
			.catch(console.error);
	};

	const walletTableColumns = useMemo(
		() =>
			WalletsAccountscolumns({
				isMobile: !lg,
				translate: t,
				showActions: show,
			}),
		[lg, t, configurationState],
	);
	return (
		<>
			<section className='bg-white p-6 '>
				<div className='w-full  p-6'>
					<div className='flex w-full justify-between items-center '>
						<h2 className='font-extrabold m-0'>{t('merchantProfile')}</h2>
						<div className='flex justify-between w-20'>
							<Link
								to={`/orders/?merchantId=${merchantId}`}
								className='h-9 w-9 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px] '
							>
								<HistoryOutlined className='text-black text-[20px]' />
							</Link>
							<Link
								to={`/dashboard?role=MERCHANT&id=${merchant?.userId}&email=${merchant?.email}`}
								className='h-9 w-9 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px] '
							>
								<AreaChartOutlined className='text-black text-[25px]' />
							</Link>
						</div>
					</div>
					<Divider className='my-2' />
				</div>
				<div className='single-merchant xss:w-[90%] lg:w-full'>
					{dataLoading ? (
						<>
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
						</>
					) : (
						<div className='w-full'>
							<article className='flex flex-col items-center justify-center'>
								<h2 className='name-initials'>{getInitials(merchant?.fullName ?? '')}</h2>
								<h2 className='m-1'>{merchant?.fullName}</h2>
								<p className='text-[#333333] text-[13px] m-1'>
									{merchant?.tradingAccount.tradingGroup}| {merchant?.tradingAccount?.tpAccountNumber} |{' '}
									{merchant?.tradingAccount.tradingPlatform} | {merchant?.tradingAccount.onHold}{' '}
									{merchant?.exchangeBaseCurrency}
								</p>
								<p className='text-[#333333] text-[13px] m-1 xss:text-[10px] lg:text-[13px]'>
									<PhoneFilled className='mx-1 text-gray' />
									{merchant?.phone}
									<span className='inline-block mx-1'> </span>
									<RedEnvelopeFilled className='mx-1 text-gray' />
									{merchant?.email}
								</p>
								<div>
									<span className='text-[#333333]  m-1 flex items-baseline xss:text-[10px] lg:text-[13px]'>
										<span>
											{merchant?.ordersCompleted} {t<string>('orders')}
										</span>
										<span className='inline-block xss:text-[10px] lg:text-[13px] w-[1px] h-[10px] bg-[#737E8D40] mx-2' />
										{(
											(Number(merchant?.ordersCompleted) /
												(merchant?.totalOrders === null || merchant?.totalOrders === 0
													? 1
													: Number(merchant?.totalOrders))) *
											100
										).toFixed()}
										% {t<string>('completionRate')}
									</span>
								</div>
								{merchant && (
									<div className='flex  items-baseline xss:text-[10px] lg:text-[13px]'>
										<div>
											{merchant?.totalReviews} {t<string>('reviews')}
										</div>
										<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40] mx-2' />
										<div
											className={`center-container ${
												merchant.rating < REVIEW_PERCENTAGE ? 'text-[#F6465D]' : 'text-[#33A867]'
											}`}
										>
											{merchant.rating < REVIEW_PERCENTAGE ? (
												<DislikeFilled className={`h-[13px] w-[13px] mx-1`} />
											) : (
												<LikeFilled className={`h-[13px] w-[13px] mx-1`} />
											)}
											<span>{merchant?.rating}%</span>
										</div>
									</div>
								)}
								<span className='text-[#333333] m-1'>
									{merchant?.currencyCode} {t<string>('currency')}
								</span>
							</article>
							<UsersActions role='MERCHANT' onAction={setAction} action={action} />
							{action === 'offers' && (
								<>
									<div className='section-container w-[100%] xss:px-5 my-4'>
										<section className='section'>
											<div className='text-card h-[230px]'>
												<div className='flex justify-between items-baseline'>
													<h3 className='heading'>{t<string>('buyOffer')}</h3>
													<div
														style={{ backgroundColor: merchant?.buyerOffer?.isPublic ? '#33a867' : '#f6465d' }}
														className={`px-[10px] py-[5px] rounded  text-white shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]`}
													>
														{merchant?.buyerOffer?.isPublic ? t<string>('active') : t<string>('inactive')}
													</div>
												</div>
												{merchant?.buyerOffer ? (
													<>
														<div className='text-container'>
															<span className='info'>{t<string>('price')}</span>
															<span className='content'>
																{merchant?.buyerOffer?.rate?.toFixed(3)?.toLocaleString()}{' '}
																{merchant?.buyerOffer?.rate ? merchant.currencyCode : ''}
															</span>
														</div>
														<div className='text-container'>
															<span className='info'>{t<string>('availability')}</span>
															<span className='content'>
																{merchant?.buyerOffer?.available?.toFixed(3)?.toLocaleString()}{' '}
																{merchant?.buyerOffer?.available ? merchant.exchangeBaseCurrency : ''}
															</span>
														</div>
														<div className='text-container'>
															<span className='info'>{t<string>('limit')}</span>
															<span className='content'>
																{merchant?.buyerOffer?.min?.toLocaleString()}-
																{merchant?.buyerOffer?.max?.toLocaleString()}{' '}
																{merchant?.buyerOffer?.min ? merchant.currencyCode : ''}
															</span>
														</div>
														<div className='text-container'>
															<span className='info'>{t<string>('paymentMethod')}</span>
															<span className='content flex justify-center flex-wrap'>
																{buyOfferPaymMethods.map((method) => (
																	<div className='mr-[5px] p-[5px]  bg-[#d9ecff] rounded-sm text-[12px] text-[#0187ff] flex mb-[5px] h-fit items-center w-max'>
																		{method.methodName}
																	</div>
																))}
															</span>
														</div>
													</>
												) : (
													<Text className='flex items-center m-auto text-[16px] font-[700] text-[#6d6c6c]'>
														{' '}
														{t<string>('offerNotAvailable')}
													</Text>
												)}
											</div>
											{merchant?.buyerOffer && (
												<>
													<div className='horizontal-separator' />
													<div className='text-card'>
														<h3 className='heading'>{t<string>('buyersTerm')}</h3>
														{merchant?.buyerOffer?.termsNote ? (
															<p>{merchant?.buyerOffer?.termsNote}</p>
														) : (
															<DashOutlined />
														)}
													</div>
												</>
											)}
										</section>
										<hr />
										<section className='section'>
											<div className='text-card h-[230px]'>
												<div className='flex justify-between items-baseline'>
													<h3 className='heading'>{t<string>('sellOffer')}</h3>
													<div
														style={{ backgroundColor: merchant?.sellerOffer?.isPublic ? '#33a867' : '#f6465d' }}
														className={`px-[10px] py-[5px] rounded  text-white shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]`}
													>
														{merchant?.sellerOffer?.isPublic ? t<string>('active') : t<string>('inactive')}
													</div>
												</div>
												{merchant?.sellerOffer ? (
													<>
														<div className='text-container'>
															<span className='info'>{t<string>('price')}</span>
															<span className='content'>
																{merchant?.sellerOffer?.rate?.toFixed(3)?.toLocaleString()}{' '}
																{merchant?.sellerOffer?.rate ? merchant.currencyCode : ''}
															</span>
														</div>
														<div className='text-container'>
															<span className='info'>{t<string>('availability')}</span>
															<span className='content'>
																{merchant?.sellerOffer?.available?.toFixed(3)?.toLocaleString()}{' '}
																{merchant.exchangeBaseCurrency}
															</span>
														</div>
														<div className='text-container'>
															<span className='info'>{t<string>('limit')}</span>
															<span className='content'>
																{merchant?.sellerOffer?.min?.toLocaleString()}-
																{merchant?.sellerOffer?.max?.toLocaleString()}{' '}
																{merchant?.sellerOffer?.min ? merchant.currencyCode : ''}
															</span>
														</div>
														<div className='text-container'>
															<span className='info'>{t<string>('paymentMethod')}</span>
															<span className='content flex justify-center flex-wrap'>
																{sellOfferPaymMethods.map((method) => (
																	<div className='mr-[5px] p-[5px] bg-[#d9ecff] text-[12px] text-[#0187ff] flex h-fit mb-[5px] items-center w-max'>
																		{method.methodName}
																	</div>
																))}
															</span>
														</div>
													</>
												) : (
													<Text className='flex items-center m-auto text-[16px] font-[700] text-[#6d6c6c]'>
														{' '}
														{t<string>('offerNotAvailable')}
													</Text>
												)}
											</div>
											{merchant?.sellerOffer && (
												<>
													<div className='horizontal-separator' />
													<div className='text-card'>
														<h3 className='heading'>{t<string>('sellersTerm')}</h3>
														{merchant?.sellerOffer?.termsNote ? (
															<p>{merchant?.sellerOffer?.termsNote}</p>
														) : (
															<DashOutlined />
														)}
													</div>
												</>
											)}
										</section>
									</div>
									<div className='full-width-separator' />
									<div className='reviews-section'>
										<MerchantReviewCarousal merchantId={merchantId} />
									</div>
								</>
							)}
							{action === 'wallets' && (
								<StyledPagination bgColor={themeColor} color={secondary_color} isClikable={true}>
									<Table
										className='w-full mt-6'
										columns={walletTableColumns}
										dataSource={wallets}
										loading={dataLoading}
										onRow={(record: Wallet) => ({
											onClick: (event: any) => {
												setWalletData(record);
												if (event.target?.innerHTML === t('deposit')) {
													setRequestType('DEPOSIT');
												} else if (event.target?.innerHTML === t('withdrawal')) {
													setRequestType('WITHDRAW');
												} else {
													navigate(`/wallet?walletNumber=${record.number}`);
												}
											},
										})}
										pagination={false}
										tableLayout='auto'
										rowKey='id'
									/>
								</StyledPagination>
							)}
						</div>
					)}
				</div>
			</section>
			<WithdrawDepositModal
				open={Boolean(requestType)}
				setOpen={setRequestType}
				onRequest={(value, comment) => {
					setComment(comment);
					setAmount(value);
					sendOtp(value);
				}}
				type={requestType}
				availablebalance={walletData?.balance}
			/>
			{otpModal && (
				<OTPModal
					showOTPModal={otpModal}
					setOTPModal={setOTPModal}
					handelAccept={onRequest}
					sendOtp={(resend) => sendOtp(amount!, resend)}
					expireTime={expireTime}
					isLoading={isLoading}
				/>
			)}
		</>
	);
};

export default SingleMerchant;
