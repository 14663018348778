import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { Order, OrderType } from '../../models/Orders';
import {
	Button,
	Dropdown,
	Form,
	Input,
	MenuProps,
	Select,
	Table,
	DatePicker,
	ConfigProvider,
	Grid,
	Collapse,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Row, Col } from 'antd';
import { getAppealTaskColumns } from '../../helpers/appealTaskColumn';
import { AdminList } from '../../models/AdminList';
import Header from '../../components/Header/Header';
import { DownOutlined, ReloadOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { Country } from '../../models/Country';
import { PaymentMethod } from '../../models/PaymentMethod';
import { useCountriesContext } from '../../store/CountriesContext';
import { themeConfig } from '../WalletTransactions/config';
import { TFunction } from 'i18next';
import { StyledPagination } from '../../components/table.style';
import { useConfigurationContext } from '../../store/configurationContext';
import { NumberOfRowsControl } from '../WalletTransactions/components/WalletFilters';

const DATE_FORMAT = 'YYYY-MM-DD';

const getUser = (list: AdminList[], userId: string, t: TFunction<'translation', undefined, 'translation'>) => {
	return list.find((item) => item.id === +userId)?.fullName ?? t('all');
};
function AppealTasks() {
	const { countriesState } = useCountriesContext();
	const { lg } = Grid.useBreakpoint();
	const [searchParams, setSearchParam] = useSearchParams();
	const [pagesize, setPageSize] = useState('10');
	const [orderStatusFilter, setOrderStatusFilter] = useState<string>(searchParams.get('orderStatusFilter') || '');
	const [startDateFilter, setStartDateFilter] = useState<string>(searchParams.get('startDateFilter') || '');
	const [endDateFilter, setEndDateFilter] = useState<string>(searchParams.get('endDateFilter') || '');
	const { t } = useTranslation();
	const [orders, setOrders] = useState([]);
	const [adminList, setadminList] = useState<AdminList[]>([]);
	const [ordersLoading, setOrdersLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>(searchParams.get('searchTerm') || '');
	const [sortBy, setSortBy] = useState<string>('');
	const [order, setOrder] = useState<Order | null>(null);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [offerTypeFilter, setOfferTypeFilter] = useState(searchParams.get('offerTypeFilter') || '');
	const userInfo = useMemo(() => getUserInfo(), []);
	const [admin, setadmin] = useState(searchParams.get('admin') || `${userInfo?.id || ''}`);
	const [searchValue, setSearchValue] = useState('');
	const [countries, setCountries] = useState<Country[]>([]);
	const [countriesLoading, setCountriesLoading] = useState<boolean>(false);
	const [countryFilter, setCountryFilter] = useState<any>(searchParams.get('country'));
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
	const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(false);
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);
	const [paymentMethodFilter, setPaymentMethodFilter] = useState<any>(Number(searchParams.get('payment-method')) || '');
	const { configurationState } = useConfigurationContext();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	const today = new Date();
	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);
	const isDev = window.location.hostname.includes('devb');

	const { RangePicker } = DatePicker;

	const token = getUserInfo()?.token;

	useEffect(() => {
		axios
			.get(`${API_ENDPOINTS.getAdminList}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setadminList(res.data);
			})
			.catch((err) => console.error(err));
	}, []);
	useEffect(() => {
		setOrdersLoading(true);
		let orderFilter = `order%23`;
		let TypeValue = offerTypeFilter ? `${orderFilter}type%3A${offerTypeFilter}` : '';
		let orderStatus = orderStatusFilter ? `resolved%3A${orderStatusFilter}` : '';
		let adminFilter = admin ? `assigneeId%23adminId%3A${Number(admin)}` : '';
		let startDate = startDateFilter
			? `createdAt%3E${dayjs(startDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
			: '';
		let endDate = endDateFilter
			? `createdAt%3C${dayjs(endDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
			: '';

		let sort = 'createdAt-desc';
		if (sortBy.startsWith('createdAt') || sortBy.startsWith('resolved')) {
			sort = sortBy;
		} else if (sortBy) {
			sort = `${orderFilter}${sortBy}`;
		}
		let paymentMethodsFilter = '';
		let countrtCodeFilter = '';
		let paymentMethod = paymentMethods.find((item) => item.methodId === paymentMethodFilter)?.methodName ?? '';
		if (countryFilter && !countryFilter.startsWith('All')) {
			paymentMethodsFilter += `order%23paymentMethod%23country%23countryCode%3B${countryFilter}`;
		}
		if (paymentMethodFilter && paymentMethodFilter !== 'All') {
			countrtCodeFilter += `order%23paymentMethod%23methodName%3B${paymentMethod}`;
		}
		let search = searchTerm ? `&wildSearch=${searchTerm}` : '';
		let filter;
		if (!TypeValue && !orderStatus && !adminFilter && !startDate && !endDate) {
			filter = ``;
		} else {
			filter = `search=${TypeValue},${orderStatus},${adminFilter},${startDate},${endDate},${paymentMethodsFilter},${countrtCodeFilter}&`;
		}
		axios
			.get(`${API_ENDPOINTS.getAppealTask}?${filter}page=${page}&pageSize=${pagesize}&sort=${sort}${search}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((result) => {
				setOrders(result.data.data);
				setTotalElements(result.data.totalElements);
				setOrdersLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setOrdersLoading(false);
			});
	}, [
		orderStatusFilter,
		endDateFilter,
		startDateFilter,
		pagesize,
		searchValue,
		sortBy,
		offerTypeFilter,
		page,
		admin,
		startDateFilter,
		endDateFilter,
		countryFilter,
		paymentMethodFilter,
		paymentMethods,
	]);

	const resetFilters = () => {
		setOrderStatusFilter('');
		setOfferTypeFilter('');
		setStartDateFilter('');
		setEndDateFilter('');
		setSearchTerm('');
		setSortBy('');
		setStartDateFilter('');
		setEndDateFilter('');
		setadmin(`${userInfo?.id || ''}`);
		setSearchParam('');
	};

	const getColor = (type: string) => {
		if (type === OrderType.buy) {
			return 'green';
		} else if (type === OrderType.sell) {
			return 'red';
		} else {
			return 'black';
		}
	};

	useEffect(() => {
		setCountriesLoading(true);
		setPaymentMethodsLoading(true);
		axios
			.get(`${API_ENDPOINTS.countries}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				let paymentMethods: any[] = [{ methodName: t<string>('all') }];
				const countriesData = res.data?.map((el: any) => {
					paymentMethods = [...paymentMethods, ...el.paymentMethods];
					return {
						name: el.countryName,
						code: el.countryCode,
					};
				});
				setPaymentMethods(paymentMethods);
				setCountries([{ name: t<string>('all'), code: '' }, ...countriesData]);
				setCountriesLoading(false);
				setPaymentMethodsLoading(false);
			})
			.catch((err) => {
				setCountriesLoading(false);
				console.error(err);
			});
	}, [t]);

	useEffect(() => {
		const params = new URLSearchParams(searchParams);
		orderStatusFilter ? params.set('orderStatusFilter', orderStatusFilter) : params.delete('orderStatusFilter');
		orderStatusFilter ? params.set('offerTypeFilter', offerTypeFilter) : params.delete('offerTypeFilter');
		paymentMethodFilter ? params.set('payment-method', paymentMethodFilter) : params.delete('payment-method');
		countryFilter ? params.set('country', countryFilter) : params.delete('country');
		orderStatusFilter ? params.set('admin', admin) : params.delete('admin');
		startDateFilter ? params.set('startDateFilter', startDateFilter) : params.delete('startDateFilter');
		endDateFilter ? params.set('endDateFilter', endDateFilter) : params.delete('endDateFilter');
		searchValue ? params.set('searchValue', searchValue) : params.delete('search');
		if (params.size === 0) return;
		setSearchParam(params);
	}, [
		orderStatusFilter,
		offerTypeFilter,
		startDateFilter,
		searchValue,
		endDateFilter,
		admin,
		countryFilter,
		paymentMethodFilter,
		paymentMethods,
	]);
	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);
	useEffect(() => {
		setPage(0);
	}, [
		orderStatusFilter,
		endDateFilter,
		startDateFilter,
		searchValue,
		offerTypeFilter,
		admin,
		countryFilter,
		paymentMethodFilter,
		paymentMethods,
	]);
	useEffect(() => {
		const country: any = countriesState.countries.find((item) => item.countryCode === countryFilter);
		const methods = country?.paymentMethods
			? country.paymentMethods
			: [{ methodName: t<string>('all'), methodId: '' }, ...countriesState.paymentMethods];
		setPaymentMethods(methods);
	}, [countryFilter, countries, countriesState.paymentMethods, t]);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);
	return (
		<div>
			<Header title={t<string>('appealTask')} description={t<string>('appealTaskSubtitle')} />
			<div className='min-h-[84vh] mx-auto xss:px-[20px] sm:px-[75px] py-5 bg-white'>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='border-[#000d1d]/10 rounded-md shadow-md py-3 pb-0 px-4 border border-solid border-gray-200'>
							<Form layout='vertical'>
								<Row gutter={16}>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('type')}>
											<Select
												placeholder={t('all')}
												value={offerTypeFilter}
												onChange={(e: string) => setOfferTypeFilter(e)}
											>
												{[
													{
														TypeName: t('all'),
														value: '',
													},
													{
														TypeName: isDev ? t<string>('buy') : t<string>('deposit'),
														value: 'BUY',
													},
													{
														TypeName: isDev ? t<string>('Sell') : t<string>('withdrawal'),
														value: 'SELL',
													},
												].map((item, index) => (
													<Select.Option key={index} value={item.value} style={{ color: getColor(item.TypeName) }}>
														{item.TypeName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('countries')}>
											<Select
												placeholder={t('all')}
												value={countryFilter}
												loading={countriesLoading}
												onChange={(e: string) => {
													setCountryFilter(e);
												}}
												showSearch
												filterOption={(inputValue, option: any) =>
													option?.children ? option.children[1].toLowerCase().includes(inputValue.toLowerCase()) : false
												}
											>
												{countries.map((item, index) => (
													<Select.Option key={index} value={item.code}>
														{item.name !== t('all') && (
															<img
																src={`https://flagsapi.com/${item.code}/shiny/64.png`}
																alt='country'
																className='country-flag'
															/>
														)}
														{item.name}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('paymentMethods')}>
											<Select
												placeholder={t('all')}
												value={paymentMethodFilter}
												loading={paymentMethodsLoading}
												onChange={(e: string) => {
													setPaymentMethodFilter(e);
												}}
												showSearch
												filterOption={(inputValue, option: any) =>
													option?.children ? option.children.toLowerCase().includes(inputValue.toLowerCase()) : false
												}
											>
												{paymentMethods.map((item, index) => (
													<Select.Option key={index} value={item.methodId}>
														{item.methodName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('Resolved')}>
											<Select
												placeholder={t('all')}
												value={orderStatusFilter}
												onChange={(e: string) => setOrderStatusFilter(e)}
											>
												{[
													{
														TypeName: t('all'),
														value: '',
													},
													{
														TypeName: t<string>('yes'),
														value: 'true',
													},
													{
														TypeName: t<string>('no'),
														value: 'false',
													},
												].map((item, index) => (
													<Select.Option
														key={index}
														value={item.value}
														style={{
															color: item.TypeName === t<string>('yes') ? 'green' : item.TypeName === 'No' ? 'red' : '',
														}}
													>
														{item.TypeName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('adminUser')}>
											<Select
												placeholder={t('all')}
												value={getUser(adminList, admin, t)}
												onChange={(e: string) => setadmin(e)}
											>
												<Select.Option value=''>{t<string>('all')}</Select.Option>
												{adminList.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.fullName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={24} lg={8}>
										<Form.Item label={t<string>('dateRange')}>
											<RangePicker
												onChange={(values) => {
													if (values && values.length === 2) {
														const startDate = dayjs(values[0]).format('YYYY-MM-DD 00:00:00');
														const endDate = dayjs(values[1]).format('YYYY-MM-DD 23:59:00');
														setStartDateFilter(startDate);
														setEndDateFilter(endDate);
													} else {
														setStartDateFilter('');
														setEndDateFilter('');
													}
												}}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<Row gutter={[16, 16]} align='middle' className='my-2'>
					<Col xs={12} lg={12}>
						<NumberOfRowsControl numberOfEntries={pagesize} setNumberOfEntries={setPageSize} />
					</Col>
					<Col xs={12} lg={12} className='flex gap-2 items-center justify-end !ps-0'>
						<Button
							onClick={resetFilters}
							className='bg-[#EAECEF] text-[#1E2329] border-none shadow-none'
							htmlType='button'
							type='primary'
							icon={<SyncOutlined />}
						></Button>
						<Input
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							className='xss:w-36  md:w-64 border-none h-9 bg-[#F5F5F5] search-input'
							placeholder={t<string>('searchOrder')}
							prefix={<SearchOutlined />}
						/>
					</Col>
				</Row>
				<ConfigProvider theme={themeConfig}>
					<StyledPagination bgColor={themeColor} color={secondary_color}>
						<Table
							columns={getAppealTaskColumns(t, sortBy, setSortBy, setOrder, !lg)}
							dataSource={orders}
							loading={ordersLoading}
							pagination={{
								pageSize: Number(pagesize),
								total: totalElements,
								showSizeChanger: false,
								onChange: (pageIndex) => setPage(pageIndex - 1),
								current: page + 1,
							}}
							tableLayout='fixed'
						/>
					</StyledPagination>
				</ConfigProvider>
			</div>
		</div>
	);
}

export default AppealTasks;
