export const countries = [
	{ countryName: 'Afghanistan', currencyCode: 'AFN', countryCode: 'AF' },
	{ countryName: 'Aland Islands', currencyCode: 'SEK', countryCode: 'AX' },
	{ countryName: 'Albania', currencyCode: 'ALL', countryCode: 'AL' },
	{ countryName: 'Algeria', currencyCode: 'DZD', countryCode: 'DZ' },
	{ countryName: 'American Samoa', currencyCode: 'USD', countryCode: 'AS' },
	{ countryName: 'Andorra', currencyCode: 'EUR', countryCode: 'AD' },
	{ countryName: 'Angola', currencyCode: 'AOA', countryCode: 'AO' },
	{ countryName: 'Anguilla', currencyCode: 'XCD', countryCode: 'AI' },
	{ countryName: 'Antarctica', currencyCode: 'AUD', countryCode: 'AQ' },
	{ countryName: 'Antigua and Barbuda', currencyCode: 'XCD', countryCode: 'AG' },
	{ countryName: 'Argentina', currencyCode: 'ARS', countryCode: 'AR' },
	{ countryName: 'Armenia', currencyCode: 'AMD', countryCode: 'AM' },
	{ countryName: 'Aruba', currencyCode: 'AWG', countryCode: 'AW' },
	{ countryName: 'Australia', currencyCode: 'AUD', countryCode: 'AU' },
	{ countryName: 'Austria', currencyCode: 'EUR', countryCode: 'AT' },
	{ countryName: 'Azerbaijan', currencyCode: 'AZN', countryCode: 'AZ' },
	{ countryName: 'Bahamas', currencyCode: 'BSD', countryCode: 'BS' },
	{ countryName: 'Bahrain', currencyCode: 'BHD', countryCode: 'BH' },
	{ countryName: 'Bangladesh', currencyCode: 'BDT', countryCode: 'BD' },
	{ countryName: 'Barbados', currencyCode: 'BBD', countryCode: 'BB' },
	{ countryName: 'Belarus', currencyCode: 'BYN', countryCode: 'BY' },
	{ countryName: 'Belgium', currencyCode: 'EUR', countryCode: 'BE' },
	{ countryName: 'Belize', currencyCode: 'BZD', countryCode: 'BZ' },
	{ countryName: 'Benin', currencyCode: 'XOF', countryCode: 'BJ' },
	{ countryName: 'Bermuda', currencyCode: 'BMD', countryCode: 'BM' },
	{ countryName: 'Bhutan', currencyCode: 'BTN', countryCode: 'BT' },
	{ countryName: 'Bolivia', currencyCode: 'BOB', countryCode: 'BO' },
	{ countryName: 'Bosnia and Herzegovina', currencyCode: 'BAM', countryCode: 'BA' },
	{ countryName: 'Botswana', currencyCode: 'BWP', countryCode: 'BW' },
	{ countryName: 'Bouvet Island', currencyCode: 'NOK', countryCode: 'BV' },
	{ countryName: 'Brazil', currencyCode: 'BRL', countryCode: 'BR' },
	{ countryName: 'British Indian Ocean Territory', currencyCode: 'USD', countryCode: 'IO' },
	{ countryName: 'Brunei', currencyCode: 'BND', countryCode: 'BN' },
	{ countryName: 'Bulgaria', currencyCode: 'BGN', countryCode: 'BG' },
	{ countryName: 'Burkina Faso', currencyCode: 'BF', countryCode: 'BF' },
	{ countryName: 'Burma (Myanmar)', currencyCode: 'MMK', countryCode: 'MM' },
	{ countryName: 'Burundi', currencyCode: 'BIF', countryCode: 'BI' },
	{ countryName: 'Cambodia', currencyCode: 'KHR', countryCode: 'KH' },
	{ countryName: 'Cameroon', currencyCode: 'XAF', countryCode: 'CM' },
	{ countryName: 'Canada', currencyCode: 'CAD', countryCode: 'CA' },
	{ countryName: 'Cape Verde', currencyCode: 'CVE', countryCode: 'CV' },
	{ countryName: 'Cayman Islands', currencyCode: 'KYD', countryCode: 'KY' },
	{ countryName: 'Central African Republic', currencyCode: 'CDF', countryCode: 'CF' },
	{ countryName: 'Chad', currencyCode: 'CDF', countryCode: 'TD' },
	{ countryName: 'Chile', currencyCode: 'CLP', countryCode: 'CL' },
	{ countryName: 'China', currencyCode: 'CNY', countryCode: 'CN' },
	{ countryName: 'Christmas Island', currencyCode: 'AUD', countryCode: 'CX' },
	{ countryName: 'Cocos (Keeling) Islands', currencyCode: 'AUD', countryCode: 'CC' },
	{ countryName: 'Colombia', currencyCode: 'COP', countryCode: 'CO' },
	{ countryName: 'Comoros', currencyCode: 'KMF', countryCode: 'KM' },
	{ countryName: 'Congo, Dem. Republic', currencyCode: 'CDF', countryCode: 'CD' },
	{ countryName: 'Congo, Republic', currencyCode: 'CDF', countryCode: 'CG' },
	{ countryName: 'Cook Islands', currencyCode: 'NZD', countryCode: 'CK' },
	{ countryName: 'Costa Rica', currencyCode: 'CRC', countryCode: 'CR' },
	{ countryName: 'Croatia', currencyCode: 'HRK', countryCode: 'HR' },
	{ countryName: 'Cuba', currencyCode: 'CUP', countryCode: 'CU' },
	{ countryName: 'Cyprus', currencyCode: 'CYP', countryCode: 'CY' },
	{ countryName: 'Czech Republic', currencyCode: 'CZK', countryCode: 'CZ' },
	{ countryName: 'Denmark', currencyCode: 'DKK', countryCode: 'DK' },
	{ countryName: 'Djibouti', currencyCode: 'DJF', countryCode: 'DJ' },
	{ countryName: 'Dominica', currencyCode: 'XCD', countryCode: 'DM' },
	{ countryName: 'Dominican Republic', currencyCode: 'DOP', countryCode: 'DO' },
	{ countryName: 'East Timor', currencyCode: 'USD', countryCode: 'TL' },
	{ countryName: 'Ecuador', currencyCode: 'USD', countryCode: 'EC' },
	{ countryName: 'Egypt', currencyCode: 'EGP', countryCode: 'EG' },
	{ countryName: 'El Salvador', currencyCode: 'USD', countryCode: 'SV' },
	{ countryName: 'Equatorial Guinea', currencyCode: 'GNF', countryCode: 'GQ' },
	{ countryName: 'Eritrea', currencyCode: 'ERN', countryCode: 'ER' },
	{ countryName: 'Estonia', currencyCode: 'EEK', countryCode: 'EE' },
	{ countryName: 'Ethiopia', currencyCode: 'ETB', countryCode: 'ET' },
	{ countryName: 'Falkland Islands', currencyCode: 'FKP', countryCode: 'FK' },
	{ countryName: 'Faroe Islands', currencyCode: 'DKK', countryCode: 'FO' },
	{ countryName: 'Fiji', currencyCode: 'FJD', countryCode: 'FJ' },
	{ countryName: 'Finland', currencyCode: 'EUR', countryCode: 'FI' },
	{ countryName: 'France', currencyCode: 'EUR', countryCode: 'FR' },
	{ countryName: 'French Guiana', currencyCode: 'EUR', countryCode: 'GF' },
	{ countryName: 'French Polynesia', currencyCode: 'XPF', countryCode: 'PF' },
	{ countryName: 'French Southern Territories', currencyCode: 'EUR', countryCode: 'TF' },
	{ countryName: 'Gabon', currencyCode: 'GAB', countryCode: 'GA' },
	{ countryName: 'Gambia', currencyCode: 'GMD', countryCode: 'GM' },
	{ countryName: 'Georgia', currencyCode: 'GEL', countryCode: 'GE' },
	{ countryName: 'Germany', currencyCode: 'EUR', countryCode: 'DE' },
	{ countryName: 'Ghana', currencyCode: 'GHS', countryCode: 'GH' },
	{ countryName: 'Gibraltar', currencyCode: 'GIP', countryCode: 'GI' },
	{ countryName: 'Greece', currencyCode: 'EUR', countryCode: 'GR' },
	{ countryName: 'Greenland', currencyCode: 'DKK', countryCode: 'GL' },
	{ countryName: 'Grenada', currencyCode: 'XCD', countryCode: 'GD' },
	{ countryName: 'Guadeloupe', currencyCode: 'EUR', countryCode: 'GP' },
	{ countryName: 'Guam', currencyCode: 'USD', countryCode: 'GU' },
	{ countryName: 'Guatemala', currencyCode: 'GTQ', countryCode: 'GT' },
	{ countryName: 'Guernsey', currencyCode: 'GBP', countryCode: 'GG' },
	{ countryName: 'Guinea', currencyCode: 'GNF', countryCode: 'GN' },
	{ countryName: 'Guinea-Bissau', currencyCode: 'GNF', countryCode: 'GW' },
	{ countryName: 'Guyana', currencyCode: 'GYD', countryCode: 'GY' },
	{ countryName: 'Haiti', currencyCode: 'HTG', countryCode: 'HT' },
	{ countryName: 'Heard Island and McDonald Islands', currencyCode: 'AUD', countryCode: 'HM' },
	{ countryName: 'Honduras', currencyCode: 'HNL', countryCode: 'HN' },
	{ countryName: 'Hong Kong', currencyCode: 'HKD', countryCode: 'HK' },
	{ countryName: 'Hungary', currencyCode: 'HUF', countryCode: 'HU' },
	{ countryName: 'Iceland', currencyCode: 'ISK', countryCode: 'IS' },
	{ countryName: 'India', currencyCode: 'INR', countryCode: 'IN' },
	{ countryName: 'Indonesia', currencyCode: 'IDR', countryCode: 'ID' },
	{ countryName: 'Iran', currencyCode: 'IRR', countryCode: 'IR' },
	{ countryName: 'Iraq', currencyCode: 'IQD', countryCode: 'IQ' },
	{ countryName: 'Ireland', currencyCode: 'EUR', countryCode: 'IE' },
	{ countryName: 'Israel', currencyCode: 'ILS', countryCode: 'IL' },
	{ countryName: 'Italy', currencyCode: 'EUR', countryCode: 'IT' },
	{ countryName: 'Ivory Coast', currencyCode: 'CFA', countryCode: 'CI' },
	{ countryName: 'Jamaica', currencyCode: 'JMD', countryCode: 'JM' },
	{ countryName: 'Japan', currencyCode: 'JPY', countryCode: 'JP' },
	{ countryName: 'Jersey', currencyCode: 'GBP', countryCode: 'JE' },
	{ countryName: 'Jordan', currencyCode: 'JOD', countryCode: 'JO' },
	{ countryName: 'Kazakhstan', currencyCode: 'KZT', countryCode: 'KZ' },
	{ countryName: 'Kenya', currencyCode: 'KES', countryCode: 'KE' },
	{ countryName: 'Kiribati', currencyCode: 'AUD', countryCode: 'KI' },
	{ countryName: 'Korea, Dem. Republic of', currencyCode: 'KPW', countryCode: 'KP' },
	{ countryName: 'Kuwait', currencyCode: 'KWD', countryCode: 'KW' },
	{ countryName: 'Kyrgyzstan', currencyCode: 'KGS', countryCode: 'KG' },
	{ countryName: 'Laos', currencyCode: 'LAK', countryCode: 'LA' },
	{ countryName: 'Latvia', currencyCode: 'LVL', countryCode: 'LV' },
	{ countryName: 'Lebanon', currencyCode: 'LBP', countryCode: 'LB' },
	{ countryName: 'Lesotho', currencyCode: 'LSL', countryCode: 'LS' },
	{ countryName: 'Liberia', currencyCode: 'LRD', countryCode: 'LR' },
	{ countryName: 'Libya', currencyCode: 'LYD', countryCode: 'LY' },
	{ countryName: 'Liechtenstein', currencyCode: 'CHF', countryCode: 'LI' },
	{ countryName: 'Lithuania', currencyCode: 'LTL', countryCode: 'LT' },
	{ countryName: 'Luxembourg', currencyCode: 'EUR', countryCode: 'LU' },
	{ countryName: 'Macau', currencyCode: 'MOP', countryCode: 'MO' },
	{ countryName: 'Macedonia', currencyCode: 'MKD', countryCode: 'MK' },
	{ countryName: 'Madagascar', currencyCode: 'MGA', countryCode: 'MG' },
	{ countryName: 'Malawi', currencyCode: 'MWK', countryCode: 'MW' },
	{ countryName: 'Malaysia', currencyCode: 'MYR', countryCode: 'MY' },
	{ countryName: 'Maldives', currencyCode: 'MVR', countryCode: 'MV' },
	{ countryName: 'Mali', currencyCode: 'XOF', countryCode: 'ML' },
	{ countryName: 'Malta', currencyCode: 'MTL', countryCode: 'MT' },
	{ countryName: 'Man Island', currencyCode: 'GBP', countryCode: 'IM' },
	{ countryName: 'Marshall Islands', currencyCode: 'USD', countryCode: 'MH' },
	{ countryName: 'Martinique', currencyCode: 'EUR', countryCode: 'MQ' },
	{ countryName: 'Mauritania', currencyCode: 'MRU', countryCode: 'MR' },
	{ countryName: 'Mauritius', currencyCode: 'MUR', countryCode: 'MU' },
	{ countryName: 'Mayotte', currencyCode: 'EUR', countryCode: 'YT' },
	{ countryName: 'Mexico', currencyCode: 'MXN', countryCode: 'MX' },
	{ countryName: 'Micronesia', currencyCode: 'USD', countryCode: 'FM' },
	{ countryName: 'Moldova', currencyCode: 'MDL', countryCode: 'MD' },
	{ countryName: 'Monaco', currencyCode: 'EUR', countryCode: 'MC' },
	{ countryName: 'Mongolia', currencyCode: 'MNT', countryCode: 'MN' },
	{ countryName: 'Montenegro', currencyCode: 'EUR', countryCode: 'ME' },
	{ countryName: 'Montserrat', currencyCode: 'XCD', countryCode: 'MS' },
	{ countryName: 'Morocco', currencyCode: 'MAD', countryCode: 'MA' },
	{ countryName: 'Mozambique', currencyCode: 'MZN', countryCode: 'MZ' },
	{ countryName: 'Namibia', currencyCode: 'NAD', countryCode: 'NA' },
	{ countryName: 'Nauru', currencyCode: 'AUD', countryCode: 'NR' },
	{ countryName: 'Nepal', currencyCode: 'NPR', countryCode: 'NP' },
	{ countryName: 'Netherlands', currencyCode: 'EUR', countryCode: 'NL' },
	{ countryName: 'Netherlands Antilles', currencyCode: 'AWG', countryCode: 'AN' },
	{ countryName: 'New Caledonia', currencyCode: 'XPF', countryCode: 'NC' },
	{ countryName: 'New Zealand', currencyCode: 'NZD', countryCode: 'NZ' },
	{ countryName: 'Nicaragua', currencyCode: 'NIO', countryCode: 'NI' },
	{ countryName: 'Niger', currencyCode: 'NGN', countryCode: 'NE' },
	{ countryName: 'Nigeria', currencyCode: 'NGN', countryCode: 'NG' },
	{ countryName: 'Niue', currencyCode: 'NZD', countryCode: 'NU' },
	{ countryName: 'Norfolk Island', currencyCode: 'AUD', countryCode: 'NF' },
	{ countryName: 'Northern Mariana Islands', currencyCode: 'USD', countryCode: 'MP' },
	{ countryName: 'Norway', currencyCode: 'NOK', countryCode: 'NO' },
	{ countryName: 'Oman', currencyCode: 'OMR', countryCode: 'OM' },
	{ countryName: 'Pakistan', currencyCode: 'PKR', countryCode: 'PK' },
	{ countryName: 'Palau', currencyCode: 'USD', countryCode: 'PW' },
	{ countryName: 'Palestinian Territories', currencyCode: 'ILS', countryCode: 'PS' },
	{ countryName: 'Panama', currencyCode: 'PAB', countryCode: 'PA' },
	{ countryName: 'Papua New Guinea', currencyCode: 'PGK', countryCode: 'PG' },
	{ countryName: 'Paraguay', currencyCode: 'PYG', countryCode: 'PY' },
	{ countryName: 'Peru', currencyCode: 'PEN', countryCode: 'PE' },
	{ countryName: 'Philippines', currencyCode: 'PHP', countryCode: 'PH' },
	{ countryName: 'Pitcairn', currencyCode: 'NZD', countryCode: 'PN' },
	{ countryName: 'Poland', currencyCode: 'PLN', countryCode: 'PL' },
	{ countryName: 'Portugal', currencyCode: 'EUR', countryCode: 'PT' },
	{ countryName: 'Puerto Rico', currencyCode: 'USD', countryCode: 'PR' },
	{ countryName: 'Qatar', currencyCode: 'QAR', countryCode: 'QA' },
	{ countryName: 'Reunion Island', currencyCode: 'EUR', countryCode: 'RE' },
	{ countryName: 'Romania', currencyCode: 'RON', countryCode: 'RO' },
	{ countryName: 'Russian Federation', currencyCode: 'RUB', countryCode: 'RU' },
	{ countryName: 'Rwanda', currencyCode: 'RWF', countryCode: 'RW' },
	{ countryName: 'Republic of Kosovo', currencyCode: 'RSD', countryCode: 'XK' },
	{ countryName: 'Saint Barthelemy', currencyCode: 'EUR', countryCode: 'BL' },
	{ countryName: 'Saint Kitts and Nevis', currencyCode: 'XCD', countryCode: 'KN' },
	{ countryName: 'Saint Lucia', currencyCode: 'XCD', countryCode: 'LC' },
	{ countryName: 'Saint Martin', currencyCode: 'EUR', countryCode: 'MF' },
	{ countryName: 'Saint Pierre and Miquelon', currencyCode: 'EUR', countryCode: 'PM' },
	{ countryName: 'Saint Vincent and the Grenadines', currencyCode: 'XCD', countryCode: 'VC' },
	{ countryName: 'Samoa', currencyCode: 'WST', countryCode: 'WS' },
	{ countryName: 'San Marino', currencyCode: 'EUR', countryCode: 'SM' },
	{ countryName: 'Saudi Arabia', currencyCode: 'SAR', countryCode: 'SA' },
	{ countryName: 'Senegal', currencyCode: 'XOF', countryCode: 'SN' },
	{ countryName: 'Serbia', currencyCode: 'RSD', countryCode: 'RS' },
	{ countryName: 'Seychelles', currencyCode: 'SCR', countryCode: 'SC' },
	{ countryName: 'Sierra Leone', currencyCode: 'SLL', countryCode: 'SL' },
	{ countryName: 'Singapore', currencyCode: 'SGD', countryCode: 'SG' },
	{ countryName: 'Slovakia', currencyCode: 'SKK', countryCode: 'SK' },
	{ countryName: 'Slovenia', currencyCode: 'SIT', countryCode: 'SI' },
	{ countryName: 'Solomon Islands', currencyCode: 'SBD', countryCode: 'SB' },
	{ countryName: 'Somalia', currencyCode: 'SOS', countryCode: 'SO' },
	{ countryName: 'South Africa', currencyCode: 'ZAR', countryCode: 'ZA' },
	{ countryName: 'South Georgia and the South Sandwich Islands', currencyCode: 'GBP', countryCode: 'GS' },
	{ countryName: 'South Korea', currencyCode: 'KRW', countryCode: 'KR' },
	{ countryName: 'Spain', currencyCode: 'EUR', countryCode: 'ES' },
	{ countryName: 'Sri Lanka', currencyCode: 'LKR', countryCode: 'LK' },
	{ countryName: 'Sudan', currencyCode: 'SDG', countryCode: 'SD' },
	{ countryName: 'Suriname', currencyCode: 'SRD', countryCode: 'SR' },
	{ countryName: 'Svalbard and Jan Mayen', currencyCode: 'NOK', countryCode: 'SJ' },
	{ countryName: 'Swaziland', currencyCode: 'SZL', countryCode: 'SZ' },
	{ countryName: 'Sweden', currencyCode: 'SEK', countryCode: 'SE' },
	{ countryName: 'Switzerland', currencyCode: 'CHF', countryCode: 'CH' },
	{ countryName: 'Syria', currencyCode: 'SYP', countryCode: 'SY' },
	{ countryName: 'São Tomé and Príncipe', currencyCode: 'STN', countryCode: 'ST' },
	{ countryName: 'Taiwan', currencyCode: 'TWD', countryCode: 'TW' },
	{ countryName: 'Tajikistan', currencyCode: 'TJS', countryCode: 'TJ' },
	{ countryName: 'Tanzania', currencyCode: 'TZS', countryCode: 'TZ' },
	{ countryName: 'Thailand', currencyCode: 'THB', countryCode: 'TH' },
	{ countryName: 'Togo', currencyCode: 'TGD', countryCode: 'TG' },
	{ countryName: 'Tokelau', currencyCode: 'NZD', countryCode: 'TK' },
	{ countryName: 'Tonga', currencyCode: 'TOP', countryCode: 'TO' },
	{ countryName: 'Trinidad and Tobago', currencyCode: 'TTD', countryCode: 'TT' },
	{ countryName: 'Tunisia', currencyCode: 'TND', countryCode: 'TN' },
	{ countryName: 'Turkey', currencyCode: 'TRY', countryCode: 'TR' },
	{ countryName: 'Turkmenistan', currencyCode: 'TMT', countryCode: 'TM' },
	{ countryName: 'Turks and Caicos Islands', currencyCode: 'USD', countryCode: 'TC' },
	{ countryName: 'Tuvalu', currencyCode: 'AUD', countryCode: 'TV' },
	{ countryName: 'Uganda', currencyCode: 'UGX', countryCode: 'UG' },
	{ countryName: 'Ukraine', currencyCode: 'UAH', countryCode: 'UA' },
	{ countryName: 'United Arab Emirates', currencyCode: 'AED', countryCode: 'AE' },
	{ countryName: 'United Kingdom', currencyCode: 'GBP', countryCode: 'GB' },
	{ countryName: 'United States', currencyCode: 'USD', countryCode: 'US' },
	{ countryName: 'United States Minor Outlying Islands', currencyCode: 'USD', countryCode: 'UM' },
	{ countryName: 'Uruguay', currencyCode: 'UYU', countryCode: 'UY' },
	{ countryName: 'Uzbekistan', currencyCode: 'UZS', countryCode: 'UZ' },
	{ countryName: 'Vanuatu', currencyCode: 'VUV', countryCode: 'VU' },
	{ countryName: 'Vatican City State', currencyCode: 'EUR', countryCode: 'VA' },
	{ countryName: 'Venezuela', currencyCode: 'VEF', countryCode: 'VE' },
	{ countryName: 'Vietnam', currencyCode: 'VND', countryCode: 'VN' },
	{ countryName: 'Virgin Islands (British)', currencyCode: 'USD', countryCode: 'VG' },
	{ countryName: 'Virgin Islands (U.S.)', currencyCode: 'USD', countryCode: 'VI' },
	{ countryName: 'Wallis and Futuna', currencyCode: 'XPF', countryCode: 'WF' },
	{ countryName: 'Western Sahara', currencyCode: 'MAD', countryCode: 'EH' },
	{ countryName: 'Yemen', currencyCode: 'YER', countryCode: 'YE' },
	{ countryName: 'Zambia', currencyCode: 'ZMW', countryCode: 'ZM' },
	{ countryName: 'Zimbabwe', currencyCode: 'ZWL', countryCode: 'ZW' },
	{ countryName: 'South Sudan', currencyCode: 'SSP', countryCode: 'SS' },
];
