import { useTranslation } from 'react-i18next';

type propsType = {
	deposit: number;
	withdrawa: number;
};
const WithdrawDepositCount = ({ deposit, withdrawa }: propsType) => {
	const { t } = useTranslation();
	return (
		<div className='flex justify-between w-full items-center xss:flex-col md:flex-row'>
			<p className='flex items-center md:w-[45%] xss:w-[100%] justify-between'>
				<span className='flex items-center '>
					<span className='bg-[#33a867] w-4 h-4 rounded-full inline-block text-center me-1' />
					{t('deposit')}
				</span>
				<span className='font-bold'>{deposit}</span>
			</p>
			<p className='flex items-center md:w-[45%] xss:w-[100%] justify-between'>
				<span className='flex items-center  '>
					<span className='bg-[#f6465d] w-4 h-4 rounded-full inline-block text-center me-1' />
					{t('withdrawal')}
				</span>
				<span className='font-bold'>{withdrawa}</span>
			</p>
		</div>
	);
};
export default WithdrawDepositCount;
