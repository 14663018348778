import { Button, Collapse, ConfigProvider, Dropdown, Form, Grid, Input, MenuProps, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Merchant, MerchantFilters } from '../../models/Merchants';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { getMerchantsColumns } from '../../helpers/merchantsTableColumns';
import MerchantsHead from '../../components/Merchants/MerchantsHead/MerchantsHead';
import './Merchants.scss';
import { PaymentMethod } from '../../models/PaymentMethod';
import { Country } from '../../models/Country';

import { getSortByValue } from '../../helpers/getMerchantSortByValue';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { UserStatusModal } from '../../components/UserStatusModal/UserStatusModal';
import { UserState } from '../../models/UserState';
import { DownOutlined, ReloadOutlined, SearchOutlined, AreaChartOutlined } from '@ant-design/icons';
import { useSearchParams, useLocation, Link } from 'react-router-dom';
import { useCountriesContext } from '../../store/CountriesContext';
import { themeConfig } from '../WalletTransactions/config';
import { StyledPagination } from '../../components/table.style';
import { useConfigurationContext } from '../../store/configurationContext';

const Merchants = () => {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const { countriesState } = useCountriesContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const [merchantsLoading, setMerchantsLoading] = useState(false);
	const [merchants, setMerchants] = useState([]);
	const [searchTerm, setSearchTerm] = useState<string>(searchParams.get('search') || '');
	const [countries, setCountries] = useState<Country[]>([]);
	const [countriesLoading, setCountriesLoading] = useState<boolean>(false);
	const [countryFilter, setCountryFilter] = useState<any>(searchParams.get('country'));
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
	const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(false);
	const [paymentMethodFilter, setPaymentMethodFilter] = useState<any>(
		Number(searchParams.get('payment-method')) ? Number(searchParams.get('payment-method')) : null,
	);
	const [pageSize, setPageSize] = useState(10);
	const [sortBy, setSortBy] = useState<string>('');
	const [sortOrder, setSortOrder] = useState(searchParams.get('sort-order') || '');
	const [page, setPage] = useState(0);
	const [merchantTypeFilter, setMerchantTypeFilter] = useState<any>(searchParams.get('type-filter'));
	const [totalElements, setTotalElements] = useState(0);
	const [merchant, setMerchant] = useState<Merchant>();
	const [showSuspended, setShowSuspended] = useState(false);
	const [showBlock, setShowBlock] = useState(false);
	const [showData, setShowData] = useState(false);
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);
	const [searchValue, setSearchValue] = useState(searchParams.get('search') || '');
	const token = getUserInfo()?.token;
	const location = useLocation();
	const { configurationState } = useConfigurationContext();
	const getMerchantsData = (
		page?: number,
		pageSize?: number,
		sortBy?: string,
		searchTerm?: string,
		merchantTypeFilter?: string,
		countryFilter?: string,
		sortOrder?: string,
		paymentMethodFilter?: string,
	) => {
		if (sortBy?.startsWith('availableBalance') || sortBy?.startsWith('frozenBalance')) {
			sortBy = `user%23defaultWallet%23${sortBy}`;
		}
		if (sortBy?.startsWith('countryCode')) {
			sortBy = `user%23country%23${sortBy}`;
		}

		let searchQuery = '';
		if (merchantTypeFilter && merchantTypeFilter !== t('all')) {
			if (merchantTypeFilter === 'Seller') {
				searchQuery = `activeSeller%3A${true}`;
			} else if (merchantTypeFilter === 'Buyer') {
				searchQuery = `activeBuyer%3A${true}`;
			} else if (merchantTypeFilter === 'Both') {
				searchQuery = `activeBuyer%3A${true}%2CactiveSeller%3A${true}`;
			}
		}
		if (countryFilter && !countryFilter.startsWith(t('all'))) {
			if (searchQuery.length) {
				searchQuery += `%2Cuser%23country%23countryCode%3B${countryFilter}`;
			} else {
				searchQuery = `user%23country%23countryCode%3B${countryFilter}`;
			}
		}
		let paymentMethod = paymentMethods.find((item) => item.methodId === paymentMethodFilter)?.methodName ?? '';
		return axios.get(
			`${API_ENDPOINTS.merchants}?page=${page}&pageSize=${pageSize}${
				sortBy || sortOrder ? `&sort=${sortBy || sortOrder}` : ''
			}${
				searchQuery || paymentMethodFilter
					? `&search=${searchQuery}${paymentMethodFilter ? `,paymentMethod:${paymentMethod}` : ''}`
					: ''
			}${searchTerm ? `&wildSearch=${searchTerm.replace(/\+/g, '%2B')}` : ''}`,
			{
				headers: { Authorization: `Bearer ${token}` },
			},
		);
	};
	const getCountriesData = () => {
		return axios.get(`${API_ENDPOINTS.countries}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
	};
	useEffect(() => {
		setCountriesLoading(true);
		setPaymentMethodsLoading(true);
		getCountriesData()
			.then((res: any) => {
				let paymentMethods: any[] = [{ methodName: t('all'), methodId: '' }];
				const countriesData = res.data?.map((el: any) => {
					paymentMethods = [...paymentMethods, ...el.paymentMethods];
					return {
						name: el.countryName,
						code: el.countryCode,
					};
				});
				setPaymentMethods(paymentMethods);
				setCountries([{ name: t('all'), code: '' }, ...countriesData]);
				setCountriesLoading(false);
			})
			.catch((err) => {
				setCountriesLoading(false);
				console.error(err);
			});
	}, [t]);

	useEffect(() => {
		setMerchantsLoading(true);
		Promise.all([
			getMerchantsData(
				page,
				pageSize,
				sortBy,
				searchTerm,
				merchantTypeFilter,
				countryFilter,
				sortOrder,
				paymentMethodFilter,
			).catch((_) => setMerchantsLoading(false)),
		])
			.catch((err) => {
				console.error(err);
				setMerchantsLoading(false);
			})
			.then((res: any) => {
				const merchantsData = res[0]?.data?.data.map((el: any) => {
					return {
						...el,
						createdAt: el.createdAt ? new Date(el.createdAt).toLocaleDateString('en-GB') : t('notAvailable'),
						currency: el.currencyCode,
						country: {
							name: '',
							code: el.countryCode,
						},
					};
				});
				setMerchants(merchantsData);
				setTotalElements(res[0]?.data?.totalElements);
			})
			.finally(() => {
				setMerchantsLoading(false);
				setPaymentMethodsLoading(false);
				setCountriesLoading(false);
			});
	}, [pageSize, sortBy, page, showData, location]);

	useEffect(() => {
		const params = new URLSearchParams(searchParams);
		paymentMethodFilter ? params.set('payment-method', paymentMethodFilter) : params.delete('payment-method');

		countryFilter ? params.set('country', countryFilter) : params.delete('country');
		searchValue !== '' ? params.set('search', searchValue) : params.delete('search');
		merchantTypeFilter ? params.set('type-filter', merchantTypeFilter) : params.delete('type-filter');

		if (sortOrder && sortOrder !== '') {
			sortOrder !== '' ? params.set('sort-order', sortOrder) : params.delete('type-filter');
		}

		if (params.size === 0) return;
		setSearchParams(params);
	}, [countryFilter, merchantTypeFilter, pageSize, paymentMethodFilter, searchValue, sortBy, sortOrder]);

	useEffect(() => {
		setPage(0);
		setPageSize(10);
	}, [countryFilter, merchantTypeFilter, paymentMethodFilter, searchValue]);

	const resetFilters = () => {};
	const pageSizeItems: MenuProps['items'] = [
		{
			label: '10',
			key: '1',
			onClick: () => setPageSize(10),
		},
		{
			label: '20',
			key: '2',
			onClick: () => setPageSize(20),
		},
		{
			label: '50',
			key: '3',
			onClick: () => setPageSize(50),
		},
		{
			label: '100',
			key: '4',
			onClick: () => setPageSize(100),
		},
	];

	const sortByItems: MenuProps['items'] = [
		{
			label: t('bestSellers'),
			key: '1',
			onClick: () => {
				setSortOrder(MerchantFilters.bestSellers);
			},
		},
		{
			label: t('maxOrders'),
			key: '2',
			onClick: () => {
				setSortOrder(MerchantFilters.maxOrders);
			},
		},
		{
			label: t('newSellers'),
			key: '3',
			onClick: () => {
				setSortOrder(MerchantFilters.newSellers);
			},
		},
		{
			label: t('minOrders'),
			key: '4',
			onClick: () => {
				setSortOrder(MerchantFilters.minOrders);
			},
		},
	];

	const handleSuspendState = () => {
		let state: UserState = merchant?.status.startsWith('SUSPEND') ? 'UNSUSPEND' : 'SUSPEND';
		let merchantId = `${merchant?.merchantId ?? ''}`;
		axios(
			`${API_ENDPOINTS.performAdminMerchantAction.replace('%merchantId%', merchantId).replace('%action%', state)}`,
			{
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			},
		)
			.then((_) => setShowData(!showData))
			.catch((err) => console.error(err));
	};
	const handleBlockState = () => {
		let state: UserState = merchant?.status.startsWith('BLOCK') ? 'UNBLOCK' : 'BLOCK';
		let merchantId = `${merchant?.merchantId ?? ''}`;
		axios(
			`${API_ENDPOINTS.performAdminMerchantAction.replace('%merchantId%', merchantId).replace('%action%', state)}`,
			{
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			},
		)
			.then((_) => setShowData(!showData))
			.catch((err) => console.error(err));
	};
	const merchantTypes = ['all', 'Buyer', 'Seller', 'Both'];

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);
	useEffect(() => {
		const country: any = countriesState.countries.find((item) => item.countryCode === countryFilter);
		const methods = country?.paymentMethods
			? country.paymentMethods
			: [{ methodName: t<string>('all'), methodId: '' }, ...countriesState.paymentMethods];
		setPaymentMethods(methods);
	}, [countryFilter, countries, countriesState.paymentMethods, t]);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div>
			<MerchantsHead />
			<div className='min-h-[84vh] xss:px-[20px] mx-auto md:px-[75px] py-5 bg-white'>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='border-[#000d1d]/10 rounded-md shadow-md pt-3 px-4 border border-solid border-gray-200 '>
							<Form layout='vertical'>
								<div className='flex xss:flex-col md:flex-row md:flex-wrap '>
									<Form.Item label={t<string>('countries')} className='md:w-[300px] xss:w-full  md:mx-5 '>
										<Select
											placeholder={t('all')}
											value={countryFilter}
											loading={countriesLoading}
											onChange={(e: string) => {
												setCountryFilter(e);
											}}
											showSearch
											filterOption={(inputValue, option: any) =>
												option?.children ? option.children[1].toLowerCase().includes(inputValue.toLowerCase()) : false
											}
										>
											{countries.map((item, index) => (
												<Select.Option key={index} value={item.code}>
													{item.name !== t('all') && (
														<img
															src={`https://flagsapi.com/${item.code}/shiny/64.png`}
															alt='country'
															className='country-flag'
														/>
													)}
													{item.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label={t<string>('paymentMethods')} className='md:w-[300px] xss:w-full md:mx-5'>
										<Select
											placeholder={t('all')}
											value={paymentMethodFilter}
											loading={paymentMethodsLoading}
											onChange={(e: string) => {
												setPaymentMethodFilter(e);
											}}
											showSearch
											filterOption={(inputValue, option: any) =>
												option?.children ? option.children.toLowerCase().includes(inputValue.toLowerCase()) : false
											}
										>
											{paymentMethods.map((item, index) => (
												<Select.Option key={index} value={item.methodId}>
													{item.methodName}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label={t<string>('merchantType')} className='md:w-[300px] xss:w-full  md:mx-5'>
										<Select
											placeholder={t('all')}
											value={merchantTypeFilter}
											loading={merchantsLoading}
											onChange={(e: string) => {
												setMerchantTypeFilter(e);
											}}
										>
											{merchantTypes.map((item, index) => (
												<Select.Option key={index} value={item}>
													{t(item.toLocaleLowerCase())}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</div>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<div className='xss:mb-0 md:my-2 flex justify-between w-full xss:flex-col-reverse md:flex-row md:flex-wrap'>
					<div className='flex items-center m-0'>
						<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
						<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
							<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
								{pageSize}
								<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
							</span>
						</Dropdown>
						<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
					</div>
					<div className='flex items-center xss:my-2 md:m-0'>
						<div className='flex justify-between gap-2'>
							<Link
								to={'/dashboard?role=MERCHANT'}
								className='h-9 w-9 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px]'
							>
								<AreaChartOutlined className='text-black text-[25px]' />
							</Link>
							<Button
								onClick={resetFilters}
								className='h-9 w-9 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px]'
							>
								<ReloadOutlined className='h-[14px] w-[14px]' />
							</Button>
							<Dropdown className='me-2' trigger={['click']} menu={{ items: sortByItems }}>
								<span className='bg-[#F5F5F5] h-9 min-w-[120px] flex items-center justify-between px-2 rounded-md text-[#1E2329] text-sm cursor-pointer'>
									{sortOrder === '' ? t<string>('sortBy') : getSortByValue(sortOrder, t)}
									<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
								</span>
							</Dropdown>
						</div>
						<Input
							className='border-none h-9 bg-[#F5F5F5] search-input m-0'
							placeholder={t<string>('searchInMerchantsTable')}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							size='large'
							prefix={<SearchOutlined className='mr-2' />}
						/>
					</div>
				</div>
				<StyledPagination bgColor={themeColor} color={secondary_color}>
					<Table
						columns={getMerchantsColumns(t, sortBy, setSortBy, setShowSuspended, setShowBlock, setMerchant, !lg)}
						dataSource={merchants}
						className='custom-table-header'
						loading={merchantsLoading}
						pagination={{
							pageSize,
							total: totalElements,
							onChange: (pageIndex) => setPage(pageIndex - 1),
							current: page + 1,
						}}
						tableLayout='fixed'
					/>
				</StyledPagination>
			</div>
			{showSuspended && (
				<UserStatusModal
					showModal={showSuspended}
					setShowModal={setShowSuspended}
					handleUserState={handleSuspendState}
					state={merchant?.status.startsWith('SUSPEND') ? 'UNSUSPEND' : 'SUSPEND'}
					user={t<string>('merchant')}
				/>
			)}
			{showBlock && (
				<UserStatusModal
					showModal={showBlock}
					setShowModal={setShowBlock}
					handleUserState={handleBlockState}
					state={merchant?.status.startsWith('BLOCK') ? 'UNBLOCK' : 'BLOCK'}
					user={t<string>('merchant')}
				/>
			)}
		</div>
	);
};

export default Merchants;
