import { Button, Card, Col, DatePicker, Divider, Form, Grid, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { BarChartOutlined, CreditCardFilled, DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { debounce } from 'lodash';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDirectionContext } from '../../store/DirectionContext';

import Deposit from '../../assets/images/icons/deposit.svg';
import Withdraw from '../../assets/images/icons/withdrawDashboardIcon.svg';
import Active from '../../assets/images/icons/active.svg';
import Orders from '../../assets/images/icons/orders.svg';
import MerchantsIcon from '../../assets/images/icons/merchants.svg';

import ClientsIcon from '../../assets/images/icons/totalclient.svg';
import ReportResponsiveLine from './lineDashboard';
import DountOrdersChart from './dounatsOrdersChart';

import './dashboard.scss';
import DountCharts from './dounatsCharts';
import CustomerCharts from './customerReportChart';
import CustomerReport from './GrediantReport';
import WithdrawDepositCount from './withdrawDepositCount';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useConfigurationContext } from '../../store/configurationContext';
import { getUserInfo } from '../../helpers/localStorageHandler';

type Option = {
	label: string;
	value: string;
};
const { RangePicker } = DatePicker;

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Dashboard = () => {
	const { t } = useTranslation();
	const { directionState } = useDirectionContext();
	const query = useQuery();
	const lastFilter = useRef('THIS_WEEK');
	const lastStartDate = useRef('');
	const lastEndDate = useRef('');
	const dashboardRef = useRef<HTMLDivElement>(null);

	const paramValue: any[] = query.get('id') ? [Number(query.get('id'))] : [];
	const paramsEmail = query.get('email') ? query.get('email') : '';
	const role = query.get('role') ? query.get('role') : 'MERCHANT';

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [selectedRange, setSelectedRange] = useState('THIS_WEEK');
	const [selectedValue, setSelectedValue] = useState('THIS_WEEK');
	const [searchValue, setSearchValue] = useState(paramsEmail);
	const [users, setUsers] = useState<Option[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<string[]>(paramValue);
	const [countries, setCountries] = useState<Option[]>([]);
	const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
	const [loading, setLoading] = useState(false); // Loading state
	const [data, setData] = useState<any>({});
	const [clientBalance, setClientsBalance] = useState('');
	const [merchantBalance, setMerchantBalance] = useState('');
	const [open, setOpen] = useState(true);
	const [stateLoading, setIsLoading] = useState(false);
	const { configurationState } = useConfigurationContext();
	const email = getUserInfo()?.email ?? '';
	const allowed_emails = configurationState.find((item) => item.configKey === 'allowed_admins_emails')?.value || '';
	const shouldShowCards = useMemo(() => {
		if (allowed_emails) return allowed_emails.includes(email);
		else if (getUserInfo()?.role === 'SUPER_USER') return true;
		else return false;
	}, [configurationState]);
	const validSelectedRanges = [
		{ value: 'TODAY', label: t('today') },
		{ value: 'YESTERDAY', label: t('yesterday') },
		{ value: 'THIS_WEEK', label: t('thisWeek') },
		{ value: 'THIS_MONTH', label: t('thisMonth') },
		{ value: 'LAST_60_DAYS', label: t('last60Days') },
		{ value: 'THIS_QUARTER', label: t('thisQuarter') },
		{ value: 'THIS_YEAR', label: t('thisYear') },
		{ value: 'DATE_RANGE', label: t('dateRange') },
	];

	const params = useMemo(() => {
		// Check if selectedRange is 'Date_Range' and both startDate and endDate are empty
		if (selectedRange === 'DATE_RANGE' && !startDate && !endDate) {
			return {}; // Return an empty object or any default value if the condition is met
		}

		return [
			['criteria', selectedRange],
			['startDate', startDate],
			['endDate', endDate],
		]
			.filter(([, value]) => !!value)
			.reduce(
				(params, [key, value]) => ({
					...params,
					[key as string]: value,
				}),
				{},
			);
	}, [startDate, endDate, selectedRange]);

	const handleDateChange = (value: string) => {
		if (value !== 'DATE_RANGE') {
			lastFilter.current = value;
			lastStartDate.current = '';
			lastEndDate.current = '';
			setStartDate('');
			setEndDate('');
		} else {
			setOpen(true);
		}
		setSelectedRange(value);
		setSelectedValue(value);
	};

	useEffect(() => {
		if (!open && !startDate && !endDate) {
			setSelectedValue(lastFilter.current);
		}
		if (!open && lastStartDate.current && lastEndDate.current) {
			setSelectedValue(
				`${dayjs(lastStartDate.current).format('YYYY-MM-DD')} - ${dayjs(lastEndDate.current).format('YYYY-MM-DD')}`,
			);
		}
	}, [open, startDate, endDate]);

	useEffect(() => {
		if (shouldShowCards && configurationState.length > 1) {
			setIsLoading(true);
			axios
				.get(API_ENDPOINTS.totalBalancesStats)
				.then((res) => {
					setClientsBalance(res.data.clientBalance);
					setMerchantBalance(res.data.merchantBalance);
				})
				.finally(() => setIsLoading(false));
		}
	}, [shouldShowCards, configurationState]);

	useEffect(() => {
		axios.get(API_ENDPOINTS.countries).then((res) => {
			let countriesArr = [
				...res.data.map((country: any) => ({
					label: (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img
								src={`https://flagsapi.com/${country.countryCode}/shiny/64.png`}
								alt='country'
								className='country-flag'
								style={{ marginRight: '8px' }} // Add margin for spacing
							/>
							{country.countryName} {/* Assuming item has a name property */}
						</div>
					),
					value: country.countryCode,
				})),
			];
			setCountries(countriesArr);
		});
	}, []);

	useEffect(() => {
		if (!searchValue) {
			setUsers([]);
			return;
		}
		setLoading(true);
		axios
			.post(`${API_ENDPOINTS.systemUsers}`, { term: searchValue, roles: [role], countries: selectedCountries })
			.then((res) => {
				const merchants = res.data.map((systemUser: any) => ({
					label: (
						<div style={{ lineHeight: '1.2' }}>
							<span>{systemUser.fullName}</span>
							<br />
							<span style={{ fontSize: '10px', color: '#888', margin: '0px' }}>{systemUser.email}</span>
							<Divider type='vertical' className='m-1 bg-[#b8b7b7] text-xs' />
							<span style={{ fontSize: '10px', color: '#888', margin: '0px' }}>{systemUser.phone}</span>
						</div>
					),
					value: systemUser.id,
					fullName: systemUser.fullName,
				}));
				setUsers(merchants);
				setLoading(false);
			});
	}, [t, selectedCountries, searchValue]);

	useEffect(() => {
		if (selectedRange === 'DATE_RANGE' && !startDate && !endDate) {
			return; // Exit early and prevent the API call
		}
		axios
			.post(
				`${API_ENDPOINTS.merchnatsDashBoard}`,
				{
					countries: selectedCountries,
					userIds: selectedUsers,
					role: role,
				},
				{
					params,
				},
			)
			.then((res) => {
				setData(res.data);
			})
			.catch(console.error);
	}, [params, selectedUsers, selectedCountries]);

	const downloadPDF = () => {
		const input = dashboardRef.current;

		if (input) {
			html2canvas(input, { scale: 2 }).then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF('p', 'mm', 'a4');

				const imgWidth = 210; // A4 page width in mm
				const pageHeight = 297; // A4 page height in mm
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				let heightLeft = imgHeight;

				let position = 0;

				// Add the first page
				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;

				// Handle multi-page PDFs
				while (heightLeft > 0) {
					position = heightLeft - imgHeight;
					pdf.addPage();
					pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
					heightLeft -= pageHeight;
				}

				pdf.save('dashboard.pdf');
			});
		}
	};

	// Debounced search handler
	const debouncedSearch = useCallback(
		debounce((value) => {
			setSearchValue(value);
		}, 800),
		[],
	);

	// Clean up debounce on unmount
	useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);
	return (
		<div className='m-6 overflow-hidden'>
			<div className='flex w-full justify-between items-center'>
				<h2 className='font-extrabold m-0'>{role === 'MERCHANT' ? t('merchantsDashborad') : t('clientsDashborad')}</h2>
				<Button
					className='h-10 w-10 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px]'
					onClick={downloadPDF}
				>
					<DownloadOutlined />
				</Button>
			</div>
			<Card style={{ margin: '10px 0px', padding: '0px' }}>
				<Form layout='vertical' className='lg:flex items-center w-full xss:grid grid-cols-1 lg:flex-row gap-2'>
					{selectedValue !== 'DATE_RANGE' && (
						<Form.Item label={t<string>('dateRange')} className='xss:w-[100%] sm:w-[300px] my-0'>
							<Select
								defaultValue={selectedValue}
								onChange={handleDateChange}
								options={validSelectedRanges.map((range) => ({
									value: range.value,
									label: range.label,
								}))}
							/>
						</Form.Item>
					)}
					{selectedValue === 'DATE_RANGE' && (
						<Form.Item label={t<string>('dateRange')} className='xss:w-[100%] sm:w-[300px] my-0'>
							<RangePicker
								open={open}
								onOpenChange={(openState) => {
									setOpen(openState); // Toggle open state based on user interaction
								}}
								onChange={(values) => {
									if (values) {
										setStartDate(dayjs(values[0]).format('YYYY-MM-DD'));
										setEndDate(dayjs(values[1]).format('YYYY-MM-DD'));
										lastStartDate.current = dayjs(values[0]).format('YYYY-MM-DD');
										lastEndDate.current = dayjs(values[1]).format('YYYY-MM-DD');
										setSelectedValue(
											`${dayjs(values[0]).format('YYYY-MM-DD')} - ${dayjs(values[1]).format('YYYY-MM-DD')}`,
										);
									}
								}}
							/>
						</Form.Item>
					)}
					<Form.Item label={t<string>('countries')} className='xss:w-[100%] sm:w-[300px] my-0'>
						<Select
							placeholder={t('typeSearch')}
							mode='multiple'
							allowClear
							onChange={(e: string[]) => {
								if (e.includes('all')) {
									setSelectedCountries([]);
								} else {
									setSelectedCountries(e);
								}
							}}
							options={countries}
							showSearch
							className='select-box'
						/>
					</Form.Item>
					<Form.Item
						label={role === 'MERCHANT' ? t<string>('merchants') : t<string>('clients')}
						className='xss:w-[100%] sm:w-[300px] my-0'
					>
						<Select
							placeholder={t('typeSearch')}
							onChange={(e: string[]) => {
								setSelectedUsers(e);
							}}
							options={users}
							mode='multiple'
							allowClear
							value={selectedUsers}
							optionLabelProp='fullName'
							notFoundContent={loading ? <Spin size='small' /> : null}
							filterOption={false} // Disable default filtering
							onSearch={debouncedSearch}
							className='select-box'
							onClear={() => {
								setSelectedUsers([]);
								setSearchValue('');
								setSelectedUsers([]);
							}}
						/>
					</Form.Item>
				</Form>
			</Card>
			<div ref={dashboardRef} className='charts'>
				<div className='justify-between items-center grid  xss:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2'>
					{[
						...(role === 'MERCHANT'
							? [
									{
										item: data.totalMerchants,
										color: '#fff7cc',
										title: t<string>('dashboardTotalMerchants'),
										icon: MerchantsIcon,
									},
									...(shouldShowCards
										? [
												{
													item: merchantBalance,
													color: '#fff7cc',
													title: t<string>('dashboardMerchantsBalance'),
													icon: <BarChartOutlined className='text-[#f5ae22] xss:w-3 md:w-4' />,
												},
										  ]
										: []),
							  ]
							: []),

						// Check if the role is client and include totalClients
						...(role === 'CLIENT'
							? [
									{
										item: data.totalClients,
										color: '#fccfcf',
										title: t<string>('dashboardTotalClients'),
										icon: ClientsIcon,
									},
									...(shouldShowCards
										? [
												{
													item: clientBalance,
													color: '#b4f5d1',
													title: t<string>('dashboardClientsBalance'),
													icon: <CreditCardFilled className='text-[#33A867] xss:w-3 md:w-4' />,
												},
										  ]
										: []),
							  ]
							: []),
						{ item: data.totalOrders, color: '#ccefff', title: t<string>('dashboardTotalOrders'), icon: Orders },
						{
							item: data.activeOrders,
							color: '#fbe6ce',
							title: t<string>('dashboardActiveOrders'),
							icon: Active,
						},
						{
							item: data.depositOrders,
							color: '#d6eee1',
							title: t<string>('depositOrder'),
							icon: Deposit,
						},
						{ item: data.withdrawOrders, color: '#fddadf', title: t<string>('withdrawOrder'), icon: Withdraw },
					].map((obj) => (
						<Card
							className='flex w-[100%] xss:h-[80px] md:h-[100px] xss:mb-1 md:mb-0 top-cards text-[13px] '
							loading={
								(obj.title === t<string>('dashboardClientsBalance') ||
									obj.title === t<string>('dashboardMerchantsBalance')) &&
								stateLoading
							}
						>
							<div className='flex items-start justify-center w-full  md:text-base h-full'>
								<div className='flex w-full justify-between '>
									<div className='flex flex-col w-[80%]'>
										<p className='color-[#030229] font-normal xss:text-[10px] md:text-[14px]  m-0'>{obj.title}</p>
										<p
											style={{
												fontWeight: 'bold',
												whiteSpace: 'nowrap', // Prevents text from wrapping
												overflow: 'hidden', // Ensures text that overflows the container is hidden
												textOverflow: 'ellipsis', // Displays ellipsis for overflowing text
												width: '140px',
												cursor: 'pointer',
											}}
											title={obj.item}
											className='circle-value my-2'
										>
											{obj.item}
										</p>
									</div>
									<div
										className='circle xss:w-[1.8rem] xss:h-[1.8rem] md:w-[2.5rem] md:h-[2.5rem]'
										style={{
											borderRadius: '50%',
											backgroundColor: obj.color,
											alignContent: 'center',
											textAlign: 'center',
										}}
									>
										{typeof obj.icon === 'string' ? (
											<img src={obj.icon} alt='item-icon' className='xss:w-3 md:w-4' />
										) : (
											obj.icon
										)}
									</div>
								</div>
							</div>
						</Card>
					))}
				</div>
				<div className='charts-container xss:block lg:grid my-2 '>
					<div className='grid-item1 h-[460px] '>
						<Card className='p-0 h-full'>
							<h1 className='p-0 m-0 -mt-2'>{t('reports')}</h1>
							<div className='h-[400px]'>
								<ReportResponsiveLine data={data.data} />
							</div>
						</Card>
					</div>
					<div className='grid-item2'>
						<Card className='xss:mt-2 lg:mt-0 h-[146px]'>
							<div className='mx-3'>
								<p className='text-[#4f4e69] font-bold text-[16px] mt-0 mb-1'>{t<string>('ordersAmountStatistic')}</p>
								<div className='flex items-center my-4 justify-between'>
									<span className='flex items-center'>
										<img src={Deposit} className='mx-1' alt='item-icon' />
										{t<string>('buyAmount')}
									</span>
									<span className='font-bold'>{data.totalDepositAmount}</span>
								</div>
								<div className='flex items-center my-4 justify-between'>
									<span className='flex items-center'>
										<img src={Withdraw} className='mx-1' alt='item-icon' />
										{t<string>('sellAmount')}
									</span>
									<span className='font-bold'>{data.totalWithdrawalAmount}</span>
								</div>
							</div>
						</Card>
						<Card className='my-2 xss:mt-2 lg:mt-2'>
							<h1 className='text-[18px] text-[#4f4e69] font-bold flex sm:justify-center xss:px-7 m-0'>
								{t('totalAnalyticsOrder')}
							</h1>
							<div className='h-[245px]' style={{ width: '100%' }}>
								{data.totalOrdersAnalytics && <DountOrdersChart data={data.totalOrdersAnalytics} />}
							</div>
						</Card>
					</div>
				</div>
				<Row gutter={[16, 16]}>
					<Col lg={12} xs={24}>
						<Card>
							<div className='flex justify-between'>
								<p className='font-bold text-[16px] m-0 xss:text-[#4f4e69] md:text-black'>{t('completedOrders')}</p>
								<p style={{ fontWeight: 'bold' }} className='circle-value my-1'>
									{data.completedOrders}
								</p>
							</div>
							<WithdrawDepositCount deposit={data.completedDepositOrders} withdrawa={data.completedWithdrawOrders} />
							<Divider className='m-0' />
							<div className='h-[300px]' style={{ width: '100%' }}>
								{data.completedOrdersAnalytics && <DountCharts data={data.completedOrdersAnalytics} />}
							</div>
						</Card>
					</Col>
					<Col lg={12} xs={24}>
						<Card>
							<div className='flex justify-between'>
								<p className='font-bold text-[16px] m-0 xss:text-[#4f4e69] md:text-black'>{t('cancelledOrders')}</p>
								<p style={{ fontWeight: 'bold' }} className='circle-value my-1'>
									{data.canceledOrders}
								</p>
							</div>
							<WithdrawDepositCount deposit={data.canceledDepositOrders} withdrawa={data.canceledWithdrawOrders} />
							<Divider className='m-0' />
							<div className='h-[300px]' style={{ width: '100%' }}>
								{data.canceledOrdersAnalytics && <DountCharts data={data.canceledOrdersAnalytics} />}
							</div>
						</Card>
					</Col>

					<Col lg={12} xs={24}>
						<Card>
							<div className='flex justify-between'>
								<p className='font-bold text-[16px] m-0 xss:text-[#4f4e69] md:text-black'>{t('expiredOrders')}</p>
								<p style={{ fontWeight: 'bold' }} className='circle-value my-1'>
									{data.expiredOrders}
								</p>
							</div>
							<WithdrawDepositCount deposit={data.expiredDepositOrders} withdrawa={data.expiredWithdrawOrders} />
							<Divider className='m-0' />
							<div className='h-[300px]' style={{ width: '100%' }}>
								{data.expiredOrdersAnalytics && <DountCharts data={data.expiredOrdersAnalytics} />}
							</div>
						</Card>
					</Col>
					<Col lg={12} xs={24}>
						<Card>
							<div className='flex justify-between'>
								<p className='font-bold text-[16px] m-0 xss:text-[#4f4e69] md:text-black'>{t('appealOrders')}</p>
								<p style={{ fontWeight: 'bold' }} className='circle-value my-1'>
									{data.appealOrders}
								</p>
							</div>
							<WithdrawDepositCount deposit={data.appealDepositOrders} withdrawa={data.appealWithdrawOrders} />
							<Divider className='m-0' />
							<div className='h-[300px]' style={{ width: '100%' }}>
								{data.appealOrdersAnalytics && <DountCharts data={data.appealOrdersAnalytics} />}
							</div>
						</Card>
					</Col>
				</Row>
				<Card className='mt-2'>
					<Row gutter={[16, 16]}>
						<Col xs={24}>
							<div className='border border-[#efefef] border-solid rounded-2xl w-full px-4'>
								<p className='font-extrabold text-[20px] m-4 mb-0 text-gray'>{t('CustomerReport')}</p>
								<div className='w-full flex justify-center  md:flex-row xss:h-[500px] md:h-[260px] xss:flex-col-reverse'>
									<div className='xss:w-full  md:w-[60%] m-auto xss:h-[50%] md:h-full'>
										<CustomerReport
											newOrders={data.newOrders ?? 0}
											oldOrders={data.oldOrders ?? 0}
											repeatedOrderCustomer={data.repeatedOrderCustomer ?? 0}
										/>
									</div>
									<div className='m-auto xss:h-[50%] md:h-full xss:w-full md:w-[40%] flex items-center justify-center'>
										{data.customerAnalytics && <CustomerCharts data={data.customerAnalytics} />}
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		</div>
	);
};
export default Dashboard;
