import { ResponsivePie } from '@nivo/pie';
import DepositDonutChart from './DepositDonutChart';
import WithdrawDonutChart from './withdrawDountsChart';
import { useTranslation } from 'react-i18next';

const DountCharts = ({ data }: any) => {
	const { t } = useTranslation();

	const totalBuy = data.totalBuy ?? 0;
	const totalSell = data.totalSell ?? 0;
	const newData = [
		{
			id: t('deposit'),
			label: t('deposit'),
			value: totalBuy,
			color: '#33a867',
		},
		{
			id: t('withdraw'),
			label: t('withdraw'),
			value: totalSell,
			color: '#f6465d',
		},
	];

	const highestPercentage = totalBuy > totalSell ? totalBuy : totalSell;

	return (
		<>
			<div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
				<div style={{ height: '80%', width: '50%' }}>
					<ResponsivePie
						data={newData}
						margin={{ top: 15, left: 0, right: 0, bottom: 15 }}
						startAngle={-80}
						sortByValue={true}
						innerRadius={0.6}
						activeInnerRadiusOffset={8}
						activeOuterRadiusOffset={8}
						borderColor={{
							from: 'color',
							modifiers: [['darker', 0.5]],
						}}
						enableArcLinkLabels={false}
						colors={['#33a867', '#f6465d']}
						layers={[
							'arcs',
							'legends',
							// Custom layer to render the white circle and the highest percentage inside it
							({ centerX, centerY }) => (
								<g>
									{highestPercentage === 0 && <circle cx={centerX} cy={centerY} r={80} fill='#cccccc' />}
									{/* White circle in the center */}
									<circle
										cx={centerX}
										cy={centerY}
										r={50} // Adjust the radius as needed
										fill='white'
										style={{
											filter: 'drop-shadow(0px 4px 6px rgba(0,0,0,0.1))', // Card-like shadow
										}}
									/>
									{/* Highest percentage text inside the circle */}
									<text
										x={centerX}
										y={centerY}
										textAnchor='middle'
										dominantBaseline='central'
										style={{
											fontSize: '18px', // Adjust the font size as needed
											fontWeight: 'bold',
											fill: '#333',
										}}
									>
										{highestPercentage}%
									</text>
								</g>
							),
						]}
					/>
				</div>
				<div
					style={{
						height: '80%',
						width: '50%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<DepositDonutChart data={newData} />
					<WithdrawDonutChart data={newData} />
				</div>
			</div>
			<div className='flex justify-center'>
				<div className='flex items-center'>
					<div className='w-[17px] h-[17px] rounded-full bg-[#33A867] mx-2 ' />
					{t<string>('buy')}
				</div>
				<div className='flex  items-center'>
					<div className='w-[17px] h-[17px] rounded-full bg-[#F6465D] mx-2' /> {t<string>('sell')}{' '}
				</div>
			</div>
		</>
	);
};

export default DountCharts;
