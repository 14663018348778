import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';

import { Client } from '../models/Clients';

import { PauseCircleOutlined, StopOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import './merchantsTableColumns.scss';
import { getInitials } from './getStringInitials';
import { TableColumnType, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { handleSortClick } from './sorterColumn';
import { ColumnTitle } from '../components/ColumnTitle/ColumnTitle';

export const getClientsColumns = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	setShowSuspended: (value: boolean) => void,
	setShowBlock: (value: boolean) => void,
	setClient: (client: Client) => void,
	isMobile: boolean,
) => {
	const columns: ColumnsType<Client> = [
		{
			title: <ColumnTitle title={t<string>('clientProfile')} />,
			dataIndex: 'fullName',
			ellipsis: true,
			width: '23%',
			responsive: ['lg'],
			render: (value, record) => {
				const blocked = record.status === 'BLOCKED';
				const suspended = record.status === 'SUSPENDED';
				const blockedText = blocked ? t('unblock') : t('block');
				const suspendedText = suspended ? t('unsuspend') : t('suspend');

				return (
					<div className='flex items-baseline'>
						<div>
							<Tooltip title={suspendedText}>
								<PauseCircleOutlined
									style={{ color: suspended ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowSuspended(true);
										setClient(record);
									}}
								/>
							</Tooltip>
							<Tooltip title={blockedText}>
								<StopOutlined
									style={{ color: blocked ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowBlock(true);
										setClient(record);
									}}
								/>
							</Tooltip>
						</div>
						<div
							style={{
								borderStyle: 'solid',
								borderWidth: '1px',
								borderColor: '#000',
							}}
							className={'h-[30px] w-[20px] mx-2 !rounded-full flex items-center justify-center p-4 '}
						>
							{getInitials(value)}
						</div>
						<div className='w-full word-break-column'>
							<Link to={`/client/?id=${record.clientId}`} className=' text-[#0ea5e9] underline '>
								{value}
							</Link>
							<div className='text-xs text-[#01081E99] m-0 flex items-center'>
								{record.ordersCompleted} {t<string>('orders')}
								<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
								{(
									(Number(record.ordersCompleted) /
										(record.totalOrders === null || record.totalOrders === 0 ? 1 : Number(record.totalOrders))) *
									100
								).toFixed()}
								% {t<string>('completionRate')}
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: <ColumnTitle title={t<string>('contactInfo')} />,
			responsive: ['lg'],
			dataIndex: 'emailAddress',
			key: 'emailAddress',
			width: '20%',
			render: (value, record) => {
				return (
					<>
						<div className='word-break-column text-black'>{value}</div>
						<div className='word-break-column text-xs text-[#999999]'>{record.phoneNumber}</div>
					</>
				);
			},
		},
		{
			title: (
				<div className='text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('walletBalance')} />
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'availableBalance-asc'))}
							style={{ color: sortBy === 'availableBalance-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'availableBalance-desc'))}
							style={{ color: sortBy === 'availableBalance-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'walletBalance',
			key: 'walletBalance',
		},
		{
			title: (
				<div className='text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('frozenBalance')} />
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'frozenBalance-asc'))}
							style={{ color: sortBy === 'frozenBalance-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'frozenBalance-desc'))}
							style={{ color: sortBy === 'frozenBalance-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'frozenBalance',
			key: 'frozenBalance',
		},
		{
			title: <ColumnTitle title={t<string>('countryCurrency')} />,
			dataIndex: 'country',
			key: 'country',
			responsive: ['lg'],
			render: (_: string, row: Client) => (
				<div className='font-medium text-lightBlue flex items-center'>
					{row.country.code}/{row.currency}
				</div>
			),
			ellipsis: true,
		},
		{
			title: (
				<div className='text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('creationDate')} />
					<div className='flex flex-col mx-2 '>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-asc'))}
							style={{ color: sortBy === 'createdAt-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-desc'))}
							style={{ color: sortBy === 'createdAt-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'createdAt',
			key: 'createdAt',
			responsive: ['lg'],
			render: (value) => {
				return <div className=' text-[#999999]'>{value}</div>;
			},
		},
		{
			title: <ColumnTitle title={t<string>('status')} />,
			dataIndex: 'status',
			key: 'status',
			responsive: ['lg'],
			render: (value) => {
				return (
					<div style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}>
						{t<string>(value.toLocaleLowerCase())}
					</div>
				);
			},
		},
		...(isMobile
			? [
					{
						dataIndex: 'record',
						key: 'record',
						className: 'small-screen-row',
						render: (value, record) => {
							return (
								<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
									<div className='w-full'>
										<div className='flex flex-row py-2 justify-between'>
											<div> {t<string>('client')}:</div>
											<div className='text-lightBlue text-[15px] font-medium flex items-center'>
												<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
													<PauseCircleOutlined
														className='px-1'
														onClick={() => {
															setShowSuspended(true);
															setClient(record);
														}}
													/>
													<StopOutlined
														className='px-1'
														onClick={() => {
															setShowBlock(true);
															setClient(record);
														}}
													/>
												</div>
												<Link
													to={`/client/?id=${record.clientId}`}
													className='text-xs flex items-center  text-lightBlue underline'
												>
													<p className='text-lightBlue text-sm font-medium m-0 p-0'>{record.fullName}</p>
												</Link>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('email')}:</div>
											<div className='text-[15px] font-medium text-lightBlue flex items-center'>
												{record.emailAddress}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('phone')}:</div>
											<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
												{record.phoneNumber}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('status')}:</div>
											<div
												className='flex items-center  flex-wrap rounded-sm text-[14px] font-medium text-[#000] px-2 '
												style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}
											>
												{record.status}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('walletBalance')}:</div>
											<div className='text-xs text-[15px] font-medium text-lightBlue  flex items-center '>
												{record.walletBalance}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('frozenBalance')}:</div>
											<div className='text-xs text-[15px] font-medium text-lightBlue  flex items-center '>
												{record.frozenBalance}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('countryCurrency')}:</div>
											<div className='flex flex-row items-center text-lightBlue text-[15px] font-medium'>
												{record.country.code}/{record.currency}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('Date&Time')}:</div>
											<div className='text-xs text-[15px] font-medium text-lightBlue  flex items-center '>
												{record.createdAt}
											</div>
										</div>
									</div>
								</div>
							);
						},
					} as TableColumnType<Client>,
			  ]
			: []),
	];

	return columns;
};
