export const Withdraw = () => {
	return (
		<span className='anticon anticon-wallet ant-menu-item-icon'>
			<svg
				width='16'
				height='16'
				viewBox='0 0 22 22'
				fill='currentColor'
				xmlns='http://www.w3.org/2000/svg'
				className='font-thin'
			>
				<path
					d='M2.52067 0.30508C1.66074 0.605057 0.700812 1.44499 0.280845 2.24493C-0.139121 3.04486 -0.0791262 4.86472 0.400836 5.80465C0.940793 6.84456 2.1407 7.6045 3.52059 7.74449L4.58051 7.86448L4.6405 13.724L4.7005 19.5636L5.20046 20.1035C6.12038 21.1034 6.66034 21.2034 11 21.2034C15.3397 21.2034 15.8796 21.1034 16.7995 20.1035L17.2995 19.5636L17.3595 13.724L17.4195 7.86448L18.4794 7.74449C19.8593 7.6045 21.0592 6.84456 21.5992 5.80465C22.0791 4.86472 22.1391 3.04486 21.7192 2.22493C21.2792 1.40499 20.1193 0.465069 19.2593 0.225088C18.0394 -0.114885 3.50059 -0.0548897 2.52067 0.30508ZM19.6793 2.6249C20.6192 3.72481 20.2993 5.26469 19.0194 5.78465C17.7195 6.3446 16.7795 5.46467 17.8795 4.72473C18.4994 4.32476 18.5794 3.50483 18.0194 3.20485C17.7595 3.08486 15.1797 3.00487 11 3.00487C6.82033 3.00487 4.24053 3.08486 3.98055 3.20485C3.4206 3.50483 3.50059 4.32476 4.12054 4.72473C4.82049 5.20469 4.7005 5.88464 3.90056 5.96463C3.04063 6.04463 2.0607 5.36468 1.84072 4.56474C1.64074 3.8048 1.84072 3.10486 2.44067 2.44491L2.86064 2.00495H11.02H19.1594L19.6793 2.6249ZM15.5996 11.7642C15.5996 16.9038 15.5396 18.7836 15.3597 18.9636C15.0197 19.3036 6.98032 19.3036 6.64034 18.9636C6.46036 18.7836 6.40036 16.9038 6.40036 11.7642V4.80473H11H15.5996V11.7642Z'
					fill='currentColor'
				/>
				<path
					d='M8.64002 6.24472C8.34004 6.5447 8.34004 7.26464 8.64002 7.56461C8.96 7.88459 13.0397 7.88459 13.3596 7.56461C13.6596 7.26464 13.6596 6.5447 13.3596 6.24472C13.0397 5.92474 8.96 5.92474 8.64002 6.24472Z'
					fill='currentColor'
				/>
				<path
					d='M10.54 10.9246C10.46 11.0046 10.4 11.8646 10.4 12.8445V14.6243L9.88001 14.2244C9.34005 13.8044 8.90008 13.8444 8.7201 14.3244C8.58011 14.7043 10.52 16.8042 10.9999 16.8042C11.4799 16.8042 13.4197 14.7043 13.2797 14.3244C13.0998 13.8444 12.6598 13.8044 12.1198 14.2244L11.6199 14.6243L11.5599 12.7645C11.4999 11.0646 11.4599 10.9046 11.0999 10.8446C10.8599 10.8046 10.6199 10.8446 10.54 10.9246Z'
					fill='currentColor'
				/>
			</svg>
		</span>
	);
};
