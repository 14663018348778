import { Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Orders from '../pages/Orders/Orders';
import Merchants from '../pages/Merchants/Merchants';
import SingleMerchant from '../pages/SingleMerchant/SingleMerchant';
import OrderLifeCycle from '../pages/OrderLifeCycle/OrderLifeCycle';
import CountriesAndPaymentMethods from '../pages/CountriesAndPaymentMethods/CountriesAndPaymentMethods';
import Clients from '../pages/Clients/Clients';
import Countries from '../pages/Countries/Countries';
import AccountGroups from '../pages/AccountGroups/AccountGroups';
import AccountGroup from '../pages/AccountGroup/AccountGroup';
import Country from '../pages/Country/Country';
import AdminUsers from '../pages/AdminUsers/AdminUsers';
import AddUser from '../pages/AddUser/AddUser';
import ChangePassword from '../pages/ChangePassw0rd/ChangePassword';
import Notifications from '../pages/Notifications/Notifications';
import AppealTasks from '../pages/TaskAppeals/AppealTasks';
import MerchantReview from '../pages/Reviews/Review';
import MyOffers from '../pages/MyOffers/MyOffers';
import ChangeAdminPassword from '../pages/ChangeAdminPassword/ChangeAdminPassword';
import SingleClient from '../pages/SingleClient/SingleClient';
import Maintenance from '../pages/Maintanance/Maintenance';
import AddMaintenance from '../pages/Add-Maintenance/AddMaintenance';
import Currency from '../pages/currency/Currency';
import AddCurrency from '../pages/AddCurrency/AddCurrency';
import { WalletTransactions } from '../pages/WalletTransactions';
import { WithdrawRequest } from '../pages/WithdrawRequest';
import Dashboard from '../pages/Dashboard/Dashboard';

const SuperAdminRoute = (
	<>
		<Route path='/' element={<Navigate to='/home' />} />
		<Route path='/orders' element={<Orders />} />
		<Route path='/order-life-cycle' element={<OrderLifeCycle />} />
		<Route path='/merchants' element={<Merchants />} />
		<Route path='/dashboard' element={<Dashboard />} />
		<Route path='/merchant' element={<SingleMerchant />} />
		<Route path='/home' element={<Home />} />
		<Route path='/clients' element={<Clients />} />
		<Route path='/client' element={<SingleClient />} />
		<Route path='/countries' element={<Countries />} />
		<Route path='/notifications' element={<Notifications />} />
		<Route path='/account-groups' element={<AccountGroups />} />
		<Route path='/add-country' element={<Country />} />
		<Route path='/countries/:countryCode' element={<Country />} />
		<Route path='/account-groups/:tradingPlatform' element={<AccountGroup />} />
		<Route path='/add-account-group' element={<AccountGroup />} />
		<Route path='/countries-and-payment-methods' element={<CountriesAndPaymentMethods />} />
		<Route path='/admin-users' element={<AdminUsers />} />
		<Route path='/add-admin' element={<AddUser />} />
		<Route path='/update-password' element={<ChangePassword />} />
		<Route path='/appeal-task' element={<AppealTasks />} />
		<Route path='/review' element={<MerchantReview />} />
		<Route path='/myOffers' element={<MyOffers />} />
		<Route path='/change-password' element={<ChangeAdminPassword />} />
		<Route path='/maintenance' element={<Maintenance />} />
		<Route path='/add-annoncement' element={<AddMaintenance />} />
		<Route path='/currency' element={<Currency />} />
		<Route path='/add-currency' element={<AddCurrency />} />
		<Route path='/wallet' element={<WalletTransactions />} />
		<Route path='/withdraw-request' element={<WithdrawRequest />} />
	</>
);

export default SuperAdminRoute;
