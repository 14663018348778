import { TableColumnType, TableColumnsType } from 'antd';
import { TFunction } from 'i18next';

import { DollarTwoTone } from '@ant-design/icons';
import { Wallet } from '../../models/merchant';
import { Link } from 'react-router-dom';

type ColumnTitleProps = {
	title: string;
};

const ColumnTitle = (props: ColumnTitleProps) => {
	return (
		<div className='py-3 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
			<span>{props.title}</span>
		</div>
	);
};

export const WalletsAccountscolumns = ({
	isMobile,
	translate,
	showActions, // New flag to control whether to show the actions column
}: {
	isMobile: boolean;
	translate: TFunction;
	showActions?: boolean; // Pass this flag based on your condition
}): TableColumnsType<Wallet> => {
	// Define base columns
	const columns: TableColumnsType<Wallet> = [
		{
			key: 'currency',
			dataIndex: 'currency',
			title: <ColumnTitle title={translate('currency')} />,
			responsive: ['lg'],
			render: (value, record) => (
				<div className='flex align-middle'>
					<DollarTwoTone className='lg:text-xl mx-2' /> {value}
				</div>
			),
		},
		{
			key: 'number',
			dataIndex: 'number',
			title: <ColumnTitle title={translate('walletNumber')} />,
			responsive: ['lg'],
		},
		{
			key: 'balance',
			dataIndex: 'balance',
			title: <ColumnTitle title={translate('balance')} />,
			responsive: ['lg'],
			render: (value, record) => (
				<div>
					{value} {record.currency}
				</div>
			),
		},
		{
			key: 'onHold',
			dataIndex: 'onHold',
			title: <ColumnTitle title={translate('onHoldBalance')} />,
			responsive: ['lg'],
			render: (value, record) => (
				<div>
					{value} {record.currency}
				</div>
			),
		},
	];

	// Conditionally add the actions column
	if (showActions) {
		columns.push({
			key: 'actions',
			dataIndex: 'actions',
			title: <ColumnTitle title={translate('actions')} />,
			responsive: ['lg'],
			render: (value, record) => (
				<div className='flex w-full'>
					<div className='text-[12px] font-medium bg-[#33a867] text-white flex items-center p-3 h-8 rounded-full mx-1 cursor-pointer'>
						{translate<string>('deposit')}
					</div>
					<div className='text-[12px] font-medium bg-[#f6465d] text-white flex items-center p-3 h-8 rounded-full mx-1 cursor-pointer'>
						{translate<string>('withdrawal')}
					</div>
				</div>
			),
		});
	}

	// Add mobile columns conditionally
	if (isMobile) {
		columns.push({
			key: 'wallet',
			dataIndex: 'wallet',
			render: (_value, record) => (
				<div>
					<ul className='list-none p-0 m-0 flex flex-col gap-2'>
						<li className='flex items-center justify-between'>
							<span className='text-[#616c77]'>{translate('currency')}</span>
							<span className='font-[600]'>
								<DollarTwoTone className='mx-1 text-[18px]' /> {record?.currency}
							</span>
						</li>
						<li className='flex items-center justify-between'>
							<span className='text-[#616c77]'>{translate('walletNumber')}</span>
							<span className='font-[600]'>{record?.number}</span>
						</li>
						<li className='flex items-center justify-between'>
							<span className='text-[#616c77]'>{translate('balance')}</span>
							<span className='font-[600]'>
								{record?.balance} {record.currency}
							</span>
						</li>
						<li className='flex items-center justify-between'>
							<span className='text-[#616c77]'>{translate('onHoldBalance')}</span>
							<span className='font-[600]'>
								{record?.onHold} {record.currency}
							</span>
						</li>
						<li className='flex items-center justify-between'>
							<span className='text-[#616c77]'>{translate('actions')}</span>
							<span className='flex font-[600]'>
								<div className='text-[12px] font-medium bg-[#33a867] text-white flex items-center p-3 h-8 rounded-full mx-1'>
									{translate<string>('deposit')}
								</div>
								<div className='text-[12px] font-medium bg-[#f6465d] text-white flex items-center p-3 h-8 rounded-full'>
									{translate<string>('withdrawal')}
								</div>
							</span>
						</li>
					</ul>
				</div>
			),
		});
	}

	return columns;
};
