import { CreditCardTwoTone, DollarTwoTone } from '@ant-design/icons';
import { Button, Modal, Form, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { WalletActionType } from '../pages/WalletTransactions/types/walletsActionsType';
import TextArea from 'antd/es/input/TextArea';
import { useDirectionContext } from '../store/DirectionContext';

type WithdrawDepositModalProps = {
	open?: boolean;
	setOpen: (open: string) => void;
	onRequest: (amount: number, comment: string) => void;
	type: WalletActionType | string;
	availablebalance?: number;
};

export const WithdrawDepositModal = (props: WithdrawDepositModalProps) => {
	const [amount, setAmount] = useState('');
	const [comment, setComment] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const { t } = useTranslation();
	const { directionState } = useDirectionContext();
	useEffect(() => setErrorMessage(''), [props?.open]);
	return (
		<Modal open={props?.open} footer={null} onCancel={() => props.setOpen('')} destroyOnClose={true}>
			<div className='flex flex-col gap-2'>
				<div className='mx-auto mt-6'>
					<div className='rounded-full w-16 h-16 bg-[#eee] flex items-center justify-center'>
						<CreditCardTwoTone className='text-3xl' />
					</div>
				</div>
				<p className='font-bold text-base text-center m-0 p-0'>
					{props.type === 'WITHDRAW' ? t('withdrawRequesttitle') : t('depositRequestTitle')}
				</p>
				<p className='text-sm text-center text-gray m-0 p-0'>
					{props.type === 'WITHDRAW' ? t('withdrawRequestSubtitle') : t('depositRequestSubtitle')}
				</p>
				<div>
					<p className='my-2'>{props.type === 'WITHDRAW' ? t('withdrawAmount') : t('buyAmount')}</p>
					<Form.Item name='amount' className='min-w-64 my-1 rounded flex flex-col'>
						<Input
							value={amount}
							onChange={(e) => {
								setAmount(e.target.value);
								if (e.target.value) {
									if (Number(e.target.value) < 0) setErrorMessage('valueMustBePositive');
									else if (Number(e.target.value) > Number(props.availablebalance) && props.type === 'WITHDRAW')
										setErrorMessage('errorAvalibleWalletBalance');
									else setErrorMessage('');
								} else {
									setErrorMessage('');
								}
							}}
							type='number'
							min={0}
							step={'any'}
							suffix={
								directionState === 'ltr' && (
									<>
										<DollarTwoTone className='mx-1 text-[18px]' /> USD{' '}
									</>
								)
							}
							prefix={
								directionState === 'rtl' && (
									<>
										<DollarTwoTone className='mx-1 text-[18px]' /> USD{' '}
									</>
								)
							}
							className='w-full p-2'
						/>
					</Form.Item>
					{errorMessage && <p className='my-1 text-red-600 text-[12px]'>{t(errorMessage)}</p>}
					<p className='my-2'>{t('Comment')}</p>
					<Form.Item name='comment' className='min-w-64 my-1 rounded flex flex-col'>
						<TextArea
							rows={4}
							placeholder={t('AddyourComments') || 'Add your Comments'}
							onChange={(e) => setComment(e.target.value)}
						/>
					</Form.Item>
				</div>
				<Button
					disabled={!amount || Boolean(errorMessage)}
					onClick={() => props.onRequest(Number(amount), comment)}
					className='w-full border-none shadow-none text-white mt-2 mb-8'
					style={{
						background: !amount || Boolean(errorMessage) ? 'lightGray' : 'linear-gradient(#00AFFF, #0077FF)',
						color: 'white',
					}}
					htmlType='button'
					size='large'
					type='primary'
				>
					{props.type === 'WITHDRAW' ? t('withdrawAction') : t('depositAction')}
				</Button>
			</div>
		</Modal>
	);
};
