import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';

import { Tooltip } from 'antd';
import { Modal, Switch } from 'antd';
import axios from 'axios';

import { Maintenance } from '../models/Maintenance';
import { API_ENDPOINTS } from '../assets/api/endpoints';
import dayjs from 'dayjs';
import { ColumnTitle } from '../components/ColumnTitle/ColumnTitle';
const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

export const getMaintenanceColumn = (
	t: TFunction<'translation', undefined, 'translation'>,
	reloadData: () => void,
	token: string | undefined,
) => {
	const showDeleteConfirmation = (announcementId: any) => {
		Modal.confirm({
			title: t('confirmDelete'),
			content: t('deleteMaintanceConfirmation'),
			okText: t('yes'),
			cancelText: t('no'),
			onOk() {
				axios
					.delete(`${API_ENDPOINTS.annoncement}`, {
						headers: { Authorization: `Bearer ${token}` },
						data: {
							announcementId,
						},
					})
					.then((res) => {
						reloadData();
					})
					.catch((err) => console.error(err));
			},
			onCancel() {
				Modal.destroyAll();
			},
		});
	};
	const columns: ColumnsType<Maintenance> = [
		{
			responsive: ['lg'],
			title: (
				<Link to={`/add-annoncement`}>
					<PlusOutlined className='flex items-center cursor-pointer  text-black font-bold' alt='add' />
				</Link>
			),
			dataIndex: 'add',
			key: 'add',
			ellipsis: true,
			render: (value, record) => {
				return (
					<div className='flex items-center'>
						<Tooltip placement='top' title={t<string>('edit')}>
							<Link to={`/add-annoncement?announcementId=${record.announcementId}`}>
								<EditFilled className='flex items-center cursor-pointer  text-black font-bold' />
							</Link>
						</Tooltip>
						<Tooltip placement='top' title={'delete'}>
							<DeleteFilled
								onClick={() => showDeleteConfirmation(record.announcementId)}
								className='flex mx-2 items-center cursor-pointer  text-black font-bold'
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: <ColumnTitle title={t<string>('startTime')} />,
			dataIndex: 'startTime',
			key: 'startTime',
			ellipsis: true,
			render: (value, record) => {
				return (
					<div className=' text-[#626A74] flex items-centerfont-medium'>
						{dayjs(record.startDate).format(DATE_TIME_FORMAT)}
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: <ColumnTitle title={t<string>('endTime')} />,
			dataIndex: 'endTime',
			key: 'endTime',
			render: (value, record) => {
				return (
					<div className=' text-[#626A74] flex items-centerfont-medium'>
						{dayjs(record.endDate).format(DATE_TIME_FORMAT)}
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: <ColumnTitle title={t<string>('enabled')} />,
			dataIndex: 'enabled',
			key: 'enabled',
			render: (value, record) => (
				<Switch
					size='small'
					checked={record.enabled}
					checkedChildren={t<string>('yes')}
					unCheckedChildren={t<string>('no')}
				/>
			),
		},
		{
			dataIndex: 'record',
			className: 'small-screen-row',
			render: (value, row) => {
				return (
					<div className='flex py-2 pb-0 justify-between country-and-paym-table-col'>
						<div className='flex-col'>
							<div className='flex flex-row'>
								<div className='flex'>
									<Link to={`/add-annoncement`}>
										<PlusOutlined
											className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold'
											alt='add'
										/>
									</Link>
									<Tooltip placement='top' title={t<string>('edit')} className='flex'>
										<Link to={`/add-annoncement?announcementId=${row.announcementId}`}>
											<EditFilled className='mx-2 flex items-center cursor-pointer text-[20px] text-black font-bold' />
										</Link>
									</Tooltip>
									<Tooltip placement='top' title={'delete'}>
										<DeleteFilled
											onClick={() => showDeleteConfirmation(row.announcementId)}
											className=' mx-2 flex items-center cursor-pointer text-[20px] text-black font-bold'
										/>
									</Tooltip>
								</div>
							</div>
							<div className='m-2'>
								<div className=' text-[#00142A66]'>
									<span className='text-[#00142A66] '>{t<string>('startTime')}</span>
									<span className='inline-block mx-1 text-[#000] text-[13px]'>
										{dayjs(row.startDate).format(DATE_TIME_FORMAT)}
									</span>
								</div>
							</div>
							<div className='flex items-center m-2'>
								<span className='text-[#00142A66] '>{t<string>('endTime')}</span>
								<span className=' text-lightBlue mx-2'>{dayjs(row.endDate).format(DATE_TIME_FORMAT)}</span>
							</div>
							<div className='flex items-center m-2'>
								<div className=' text-[#00142A66]'>
									<span className=' text-[#00142A66] '>{t<string>('enabled')}</span>
									<span className='inline-block text-[#000] text-[13px] mx-2'>
										<Switch
											checked={row.enabled}
											checkedChildren={t<string>('yes')}
											unCheckedChildren={t<string>('no')}
										/>
									</span>
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
