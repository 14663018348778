import { TableColumnType, TableColumnsType } from 'antd';
import { TFunction } from 'i18next';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

type AdminList = {
	id: number;
	fullName: string;
	canHandleRequests: boolean;
	activeTasks: number;
};
export const AdminsColumns = ({
	isMobile,
	t,
	sortBy,
	setSortBy,
}: {
	isMobile: boolean;
	t: TFunction;
	sortBy: string;
	setSortBy: (value: string) => void;
}): TableColumnsType<AdminList> => [
	{
		key: 'fullName',
		dataIndex: 'fullName',
		title: (
			<div className='py-1 text-[#000D1D99] text-xs flex items-center cursor-pointer'>
				{t<string>('name')}
				<div className='flex flex-col mx-2'>
					<CaretUpOutlined
						onClick={() => setSortBy(sortBy === 'name-asc' ? '' : 'name-asc')}
						style={{ color: sortBy === 'name-asc' ? 'black' : 'gray' }}
					/>
					<CaretDownOutlined
						onClick={() => setSortBy(sortBy === 'name-desc' ? '' : 'name-desc')}
						style={{ color: sortBy === 'name-desc' ? 'black' : 'gray' }}
					/>
				</div>
			</div>
		),
		render: (value) => <div className='font-bold'>{value}</div>,
		responsive: ['lg'],
	},
	{
		key: 'canHandleRequests',
		dataIndex: 'canHandleRequests',
		title: (
			<div className='py-1 text-[#000D1D99] text-xs flex items-center cursor-pointer'>
				{t<string>('canHandleRequest')}
				<div className='flex flex-col mx-2'>
					<CaretUpOutlined
						onClick={() => setSortBy(sortBy === 'canHandleRequests-asc' ? '' : 'canHandleRequests-asc')}
						style={{ color: sortBy === 'canHandleRequests-asc' ? 'black' : 'gray' }}
					/>
					<CaretDownOutlined
						onClick={() => setSortBy(sortBy === 'canHandleRequests-desc' ? '' : 'canHandleRequests-desc')}
						style={{ color: sortBy === 'canHandleRequests-desc' ? 'black' : 'gray' }}
					/>
				</div>
			</div>
		),
		render: (value, record) => {
			return (
				<div className='text-[14px] font-medium text-[#000D1D99] flex items-center'>
					{record.canHandleRequests ? t<string>('yes') : t<string>('no')}
				</div>
			);
		},
		responsive: ['lg'],
	},
	{
		key: 'activeTasks',
		dataIndex: 'activeTasks',
		title: (
			<div className='py-1 text-[#000D1D99] text-xs flex items-center cursor-pointer'>
				{t<string>('activeTask')}
				<div className='flex flex-col mx-2'>
					<CaretUpOutlined
						onClick={() => setSortBy(sortBy === 'activeTask-asc' ? '' : 'name-asc')}
						style={{ color: sortBy === 'activeTask-asc' ? 'black' : 'gray' }}
					/>
					<CaretDownOutlined
						onClick={() => setSortBy(sortBy === 'activeTask-desc' ? '' : 'activeTask-desc')}
						style={{ color: sortBy === 'activeTask-desc' ? 'black' : 'gray' }}
					/>
				</div>
			</div>
		),
		responsive: ['lg'],
	},
	// --- Mobile ---
	...(isMobile
		? [
				{
					key: 'wallet',
					dataIndex: 'wallet',
					render: (_value, record) => (
						<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
							<div className='w-full'>
								<div className='flex flex-row py-2 justify-between'>
									<div> {t<string>('name')}:</div>
									<div>{record.fullName}</div>
								</div>
								<div className='flex flex-row py-2 justify-between'>
									<div>{t<string>('canHandleAppeal')}:</div>
									<div className='text-lightBlue text-[15px] font-medium'>
										{record.canHandleRequests ? t<string>('yes') : t<string>('no')}
									</div>
								</div>
								<div className='flex flex-row py-2 justify-between'>
									<div>{t<string>('activeTask')}:</div>
									<div className='text-lightBlue text-[15px] font-medium'>{record.activeTasks}</div>
								</div>
							</div>
						</div>
					),
				} as TableColumnType<AdminList>,
		  ]
		: []),
];
