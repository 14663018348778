import { useConfigurationContext } from '../../store/configurationContext';

import { headerImage as defaultheaderImage } from '../../constant/fallback-config';
type PageSubHeaderProps = {
	title: string;
	description: string;
};

export const PageSubHeader = (props: PageSubHeaderProps) => {
	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';

	return (
		<section className='orders-head py-10' style={{ backgroundImage: `url(${headerImage})` }}>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center mt-6'>{props.title}</h1>
			<p className='text-white text-sm text-center'>{props.description}</p>
		</section>
	);
};
