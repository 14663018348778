import { t } from 'i18next';
import { NotificationActionType } from './getNotificationsActionTypes';
import dayjs from 'dayjs';
import { toHoursAndMinutes } from './formatDate';
const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

// Define the contentGenerator function
export function notificationContentGenerator(action: NotificationActionType, properties: Record<string, any>): string {
	// Generate content based on 'action' and 'properties'
	switch (action) {
		case NotificationActionType.NEW_ORDER:
			return t<string>('notificationNewOrderMessage', {
				orderType: properties.orderType,
				orderNumber: properties.orderNumber,
				amount: properties.amount?.toLocaleString(),
			});
		case NotificationActionType.ORDER_NEW_MESSAGE:
			return t<string>('notificationOrderNewMessage', {
				orderNumber: properties.orderNumber,
				createdAt: dayjs(properties.createdAt).format(DATE_TIME_FORMAT),
			});
		case NotificationActionType.ORDER_EXPIRY_WARNING:
			return t<string>('notificationOrderExpiryWarning', {
				orderNumber: properties.orderNumber,
				orderExpiresTimestamp: dayjs(properties.orderExpiresTimestamp).format(DATE_TIME_FORMAT),
			});
		case NotificationActionType.ORDER_EXPIRED:
			return t<string>('notificationOrderExpired', {
				orderNumber: properties.orderNumber,
			});
		case NotificationActionType.ORDER_STATUS_UPDATE:
			return t<string>('notificationOrderStatusUpdate', {
				orderNumber: properties.orderNumber,
				orderStatusBefore: t<string>(properties.orderStatusBefore.toLowerCase()),
				orderStatusAfter: t<string>(properties.orderStatusAfter.toLowerCase()),
			});
		case NotificationActionType.OFFER_DISABLED:
			return t<string>('notificationOfferDisabled', {
				offerType: properties.offerType,
				reason: t<string>(properties.reason),
			});
		case NotificationActionType.ACCOUNT_BALANCE_LOW:
			return t<string>('notificationAccountBalanceLow', {
				amount: properties.amount?.toLocaleString(),
			});
		case NotificationActionType.SELL_AVAILABLE_LOW:
			return t<string>('notificationSellAvailableLow', {
				sellAvailable: properties.sellAvailable,
			});
		case NotificationActionType.NEW_APPEAL_TASK_ASSIGNED:
			return t<string>('notificationNewAppealTaskAssigned', {
				orderNumber: properties.orderNumber,
				orderType: t<string>(properties.orderType.toLowerCase()),
				amount: properties.amount?.toLocaleString(),
			});
		case NotificationActionType.APPEAL_NEW_MESSAGE:
			return t<string>('notificationAppealNewMessage', {
				orderNumber: properties.orderNumber,
				messageType: properties.messageType,
				messagePreview: properties.messagePreview,
				createdAt: properties.createdAt,
			});
		case NotificationActionType.APPEAL_REMINDER:
			return t<string>('notificationAppealReminder', {
				orderNumber: properties.orderNumber,
				reassignedInMinutes: properties.reassignedInMinutes,
			});
		case NotificationActionType.APPEAL_REASSIGNED:
			return t<string>('notificationAppealReassigned', {
				orderNumber: properties.orderNumber,
				newAssigneeFullName: properties.newAssigneeFullName,
				reassignMethod: t<string>(properties.reassignMethod),
			});
		case NotificationActionType.PENDING_APPEAL:
			return t<string>('notificationPendingAppeal', {
				orderNumber: properties.orderNumber,
				oldAssigneeFullName: properties.oldAssigneeFullName,
				inactiveTimeMinutes: `${toHoursAndMinutes(properties.inactiveTimeMinutes)} h`,
			});
		case NotificationActionType.APPEAL_REASSIGNED_NEW_ADMIN:
			return t<string>('appealReassignNewAdmin', {
				oldAssigneeFullName: properties.oldAssigneeFullName,
				reassignMethod: t<string>(properties.reassignMethod),
			});
		case NotificationActionType.REQUEST_REMINDER:
			return t<string>('requestRemindar', {
				reassignedInMinutes: `${toHoursAndMinutes(properties.reassignedInMinutes)}`,
			});
		case NotificationActionType.PENDING_REQUEST:
			return t<string>('pendingRequest', {
				transactioNumber: properties.transactioNumber,
				oldAssigneeFullName: properties.oldAssigneeFullName,
				inactiveTimeMinutes: `${toHoursAndMinutes(properties.reassignedInMinutes)}`,
			});
		case NotificationActionType.REQUEST_REASSIGNED:
			return t<string>('requestReassign', {
				newAssigneeFullName: properties.newAssigneeFullName,
				reassignMethod: t<string>(properties.reassignMethod),
			});
		case NotificationActionType.REQUEST_REASSIGNED_NEW_ADMIN:
			return t<string>('requestReassignedNewAdmin', {
				oldAssigneeFullName: properties.oldAssigneeFullName,
				reassignMethod: t<string>(properties.reassignMethod),
			});
		case NotificationActionType.P2P_TRANSACTION_REQUESTS:
			return t<string>('p2pTransactionRequest', {
				transactionType: t<string>(properties.type),
				amount: properties.amount?.toLocaleString(),
				requestState: t<string>(properties.requestState),
			});
		case NotificationActionType.TRANSACTION_REQUEST:
			return t<string>('transactionRequest', {
				transactionType: t<string>(properties.type),
				transactionNumber: properties.transactionNumber,
				amount: properties.amount?.toLocaleString(),
			});
		default:
			return '';
	}
}
