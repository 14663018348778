import { useConfigurationContext } from '../../store/configurationContext';
import './OrdersHeader.scss';
import { useTranslation } from 'react-i18next';

import { headerImage as defaultheaderImage } from '../../constant/fallback-config';

const OrderHeader = () => {
	const { t } = useTranslation();

	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';
	return (
		<div className='orders-head py-10' style={{ backgroundImage: `url(${headerImage})` }}>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center mt-6'>{t<string>('orderHeading')}</h1>
			<p className='text-white text-sm text-center'>{t<string>('orderSubtitle')}</p>
		</div>
	);
};

export default OrderHeader;
