import { FileImageOutlined, ForwardOutlined, SendOutlined, MessageOutlined, WechatOutlined } from '@ant-design/icons';
import { OrderStateType } from '../../../types/OrderStateType';
import './ChatContainer.scss';
import { useState, useMemo, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTimeAgo from 'react-time-ago';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../../assets/api/endpoints';
import { getUserInfo } from '../../../../../helpers/localStorageHandler';
import { MessageType } from '../../../types/MessageType';
import MessageItem from './MessageItem/MessageItem';
import Stomp from 'stompjs';
import TextArea from 'antd/es/input/TextArea';
import { Form, Grid, Input, Skeleton, notification } from 'antd';
import { useLocation } from 'react-router-dom';
import { OrderDetailsType } from '../../../types/OrderDetailsType';
import { useDirectionContext } from '../../../../../store/DirectionContext';
import { CloseOutlined } from '@ant-design/icons';
import { getLanguage } from '../../../../../helpers/getLanguage';
import { getUserLanguageInCookie } from '../../../../../helpers/cookiesHandler';
import EmojiPicker from 'emoji-picker-react';
import { SmileOutlined } from '@ant-design/icons';
import { INPUT_CHAT_VALIDATION } from '../../../../../assets/config/validation-regex';
type ChatContainerProps = {
	isMerchant: boolean;
	orderNumber: string;
	orderState: OrderStateType;
	userName: string;
	orders: number;
	reviews: number;
	rating: number;
	stompClient: Stomp.Client | null;
	getOrderDetails: () => void;
	setIsActionsDisabled: Dispatch<SetStateAction<boolean>>;
	clientOrder: number;
	clientName: string;
	appealTask: OrderDetailsType['appealTask'];
};

const iconStyle = {
	color: '#ffffff',
	background: 'linear-gradient(#00afff, #0077ff)',
	borderRadius: '50%',
	width: '26px',
	height: '26px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '5px',
};
const UNREAD_MESSAGES = 99;
function ChatContainer({
	isMerchant,
	orderNumber,
	orderState,
	userName,
	orders,
	reviews,
	rating,
	stompClient,
	getOrderDetails,
	setIsActionsDisabled,
	clientOrder,
	clientName,
	appealTask,
}: ChatContainerProps) {
	const location = useLocation();
	const { t } = useTranslation();
	const { xs } = Grid.useBreakpoint();
	const [chatForm] = Form.useForm();
	const [showChat, setShowChat] = useState({ value: true });
	const bottomRef = useRef<null | HTMLElement>(null);
	const token = getUserInfo()?.token;
	const myUserName = useMemo(() => getUserInfo()?.fullName, []);
	const userInfo = useMemo(() => getUserInfo(), []);
	const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
	const [disabledSend, setDisabledSend] = useState(false);

	const [clientLastSeen, setClientLastSeen] = useState<Date>(new Date());
	const [merchantLastSeen, setMerchantLastSeen] = useState<Date>(new Date());
	const isChatDisabled = useMemo(
		() => orderState === 'CANCELLED' || orderState === 'REJECTED',
		[orderState, location.search],
	);
	const [isChatTerminated, setIsChatTernimated] = useState(false);
	const isIncomingMessge = (msg: any) => msg.role !== loggedInUserRole;
	const loggedInUserRole = useMemo(() => getUserInfo()?.role || 'ADMIN', []);
	const [isUserMerchantOnline, setIsUserMerchantOnline] = useState(false);
	const [isUserClientOnline, setIsUserClientOnline] = useState(false);
	const [historicalMessages, setHistoicalMessages] = useState<MessageType[]>([]);
	const [liveMessages, setLiveMessages] = useState<MessageType[]>([]);
	const [isChatConnected, setIsChatConnected] = useState(false);
	const [text, setText] = useState('');
	const { directionState } = useDirectionContext();
	const [unreadMessages, setUnreadMessages] = useState(0);
	const chatTopic = useMemo(
		() => API_ENDPOINTS.getOrderChatMsgTopic.replace('%orderNumber%', orderNumber),
		[orderNumber, location.search],
	);
	const sendTopic = useMemo(
		() => API_ENDPOINTS.sendOrderChatMsgTopic.replace('%orderNumber%', orderNumber),
		[orderNumber, location.search],
	);

	const checkAppealEnable = useMemo(() => {
		if (orderState === 'APPEAL' && userInfo?.role !== 'SUPER_USER') {
			return appealTask?.assigneeId !== userInfo?.id;
		}
		return false;
	}, [orderState, location.search, appealTask]);
	const allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];
	const maxFileSize = 5 * 1024 * 1024;
	// Get the historical messages on landing
	useEffect(() => getHistoricalMessages(), [location.search]);

	useEffect(() => {
		// Start the WS connect in case it's allowed
		if (!isChatDisabled && !isChatTerminated) {
			stompClient?.connect(
				{ orderNumber, Authorization: `Bearer ${token}` },
				() => {
					setIsChatConnected(true);
					setIsActionsDisabled(false);
					stompClient?.subscribe(chatTopic, onMessageReceived);
					stompClient?.send(sendTopic, {}, JSON.stringify({ sender: userName, type: 'JOIN' }));
					axios
						.get(API_ENDPOINTS.getChats.replace('%orderNumber%', orderNumber), {
							headers: { Authorization: `Bearer ${token}` },
						})
						.then(async (response) => {
							const messages: MessageType[] = response.data.chatMessages;
							const historicalMessagesReversed = [...messages].reverse();
							const lastUnreadMessage = historicalMessagesReversed.find((message) => !message.read);
							if (lastUnreadMessage) {
								sendReceivedNotification(lastUnreadMessage);
								setHistoicalMessages((messages) =>
									messages.map((message) => ({
										...message,
										read: message.read ? message.read : message.role !== loggedInUserRole,
									})),
								);
							}
						});
				},
				(error: any) => console.error(error),
			);
		}
	}, [stompClient, location.search]);

	useEffect(() => {
		if (isChatConnected && stompClient?.connected && (isChatDisabled || isChatTerminated)) {
			stompClient?.disconnect(
				() => {
					setIsChatConnected(false);
					setIsActionsDisabled(true);
				},
				{
					orderNumber,
					Authorization: `Bearer ${token}`,
				},
			);
		}
	}, [isChatDisabled, isChatTerminated, location.search]);

	const onMessageReceived = (data: any) => {
		const newMessage: MessageType = JSON.parse(data.body);
		setLiveMessages((oldMessages) => [...oldMessages, newMessage]);

		if (isIncomingMessge(newMessage)) {
			if (newMessage.messageId) {
				setHistoicalMessages((messages) => messages.map((message) => ({ ...message, read: true })));
				setLiveMessages((messages) => messages.map((message) => ({ ...message, read: true })));
			}

			if (
				newMessage.type === 'TEXT' ||
				newMessage.type === 'FILE' ||
				newMessage.type === 'ACTION' ||
				newMessage.type === 'JOIN'
			) {
				setShowChat((prevState) => {
					if (prevState.value) {
						sendReceivedNotification(newMessage);
					} else {
						setUnreadMessages((count) => count + 1);
					}
					return prevState;
				});
			}
			if (newMessage.type === 'RECEIVED' || newMessage.type === 'JOIN') {
				if (newMessage.role === 'MERCHANT') {
					setIsUserMerchantOnline(true);
				}
				if (newMessage.role === 'CLIENT') {
					setIsUserClientOnline(true);
				}
			} else if (newMessage.type === 'LEAVE') {
				if (newMessage.role === 'MERCHANT') {
					setIsUserMerchantOnline(false);
				}
				if (newMessage.role === 'CLIENT') {
					setIsUserClientOnline(false);
				}
				setMerchantLastSeen(new Date());
				setClientLastSeen(new Date());
			}
		}

		if (newMessage.type === 'TERMINATED') {
			setIsChatTernimated(true);
		} else if (newMessage.type === 'ACTION') {
			getOrderDetails();
		}

		scrollToChatBottom();
	};

	const onChatKeyUp = (event: any) => {
		event.preventDefault();

		if (event.keyCode === 13 && !disabledSend) {
			onMessageSend();
		}
	};

	const onMessageSend = () => {
		const content = chatForm.getFieldValue('currentMessage');

		if (stompClient && content.trim()) {
			stompClient?.send(sendTopic, {}, JSON.stringify({ sender: myUserName, content, type: 'TEXT' }));
			chatForm.setFieldValue('currentMessage', '');
		}
	};

	const sendReceivedNotification = (message: any) => {
		stompClient?.send(
			sendTopic,
			{},
			JSON.stringify({ messageId: message.messageId, role: loggedInUserRole, type: 'RECEIVED' }),
		);
	};

	const getHistoricalMessages = () => {
		axios
			.get(API_ENDPOINTS.getChats.replace('%orderNumber%', orderNumber), {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(async (response) => {
				const messages: MessageType[] = response.data.chatMessages;

				setHistoicalMessages(messages);
				const doesContainAction = messages?.some((message) => message.type === 'ACTION');
				setIsChatTernimated(!doesContainAction && orderState === 'EXPIRED');
				setClientLastSeen(response.data.clientLastActive);
				setMerchantLastSeen(response.data.merchantLastActive);
				scrollToChatBottom();
			});
	};

	const scrollToChatBottom = () => {
		setTimeout(() => {
			bottomRef?.current?.scroll({
				top: 100000,
				left: 0,
				behavior: 'smooth',
			});
		}, 500);
	};

	const handleUploadFileClick = (event: any) => {
		const files = event.target?.files;
		const uploadedFile = files[0];

		if (!uploadedFile) {
			return;
		}

		const formData = new FormData();
		formData.append('file', uploadedFile);

		axios
			.post(API_ENDPOINTS.uploadFile + orderNumber, formData, {
				headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
			})
			.then((result) => {
				if (result && stompClient) {
					const chatMessage = {
						sender: userName,
						content: '',
						type: 'FILE',
						fileId: result.data.fileId,
						fileLabel: result.data.fileLabel,
					};
					stompClient.send(sendTopic, {}, JSON.stringify(chatMessage));
				}
			})
			.catch((error) => console.error(error));
	};
	useEffect(() => {
		if (showChat.value) {
			const historicalMessagesReversed = [...historicalMessages].reverse();
			if (historicalMessagesReversed[0]) {
				sendReceivedNotification(historicalMessagesReversed[0]);
			}
		} else {
			setUnreadMessages(0);
		}
	}, [showChat]);

	const handleEmojiClick = (emojiData: any) => {
		setOpenEmojiPicker(false);
		const content = chatForm.getFieldValue('currentMessage') ?? '';
		setText((prevMessage) => prevMessage + emojiData.emoji);
		chatForm.setFieldValue('currentMessage', content + emojiData.emoji);
	};
	useEffect(() => setShowChat({ value: !Boolean(xs) }), [xs]);
	return (
		<div className='chatting-with-other-party-container'>
			{orderState ? (
				<>
					{showChat.value ? null : (
						<button
							style={{ left: directionState === 'rtl' ? '100px' : 'unset' }}
							className='open-chat-button rounded-xl'
							onClick={() => setShowChat({ value: true })}
						>
							{unreadMessages ? (
								<span className='bill-icon'>
									{unreadMessages > UNREAD_MESSAGES ? `+${UNREAD_MESSAGES}` : unreadMessages}
								</span>
							) : (
								<span className='bill-icon-empty'></span>
							)}
							<p className='text-[15px]'>{t<string>('chatnow')}</p>
							<MessageOutlined />
						</button>
					)}
					<div className={`order-life-cycle-chat-container ${showChat.value ? '' : 'hidden'}`}>
						<button className='close-chat-button' onClick={() => setShowChat({ value: false })}>
							<ForwardOutlined />
						</button>
						<div className='order-life-cycle-chat-box-header'>
							<div className='header-container'>
								<div className='user-details'>
									<div className='use-name-live-state'>
										<p className='user-name'>{t<string>('supportRoom')}</p>
										<div>
											<CloseOutlined
												className='close w-full flex justify-end'
												alt='close'
												onClick={() => setShowChat({ value: false })}
											/>
											<p>
												{userName}
												{isUserMerchantOnline ? (
													<span className='live ml-3'>Live</span>
												) : (
													<span className='last-seen ml-3'>
														{merchantLastSeen ? (
															<ReactTimeAgo date={merchantLastSeen} locale={getLanguage(getUserLanguageInCookie())} />
														) : null}
													</span>
												)}
											</p>
											<p>
												{clientName}
												{isUserClientOnline ? (
													<span className='live ml-3' id='chat_status'>
														Live
													</span>
												) : (
													<span className='last-seen ml-3' id='chat_status'>
														{clientLastSeen ? (
															<ReactTimeAgo date={clientLastSeen} locale={getLanguage(getUserLanguageInCookie())} />
														) : null}
													</span>
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div></div>
						</div>
						<div className='order-life-cycle-chat-box-body' ref={bottomRef as any}>
							<div className='chat-message-container'>
								<p style={{ display: 'flex', justifyContent: 'center', fontSize: '40px', width: '100%' }}>
									<WechatOutlined />
								</p>
							</div>
							{[...historicalMessages, ...liveMessages].map((message) => (
								<MessageItem
									isMerchant={isMerchant}
									message={message}
									merchantName={userName}
									clientName={clientName}
									orderNumber={orderNumber}
								/>
							))}
						</div>
						<div className='order-life-cycle-chat-box-footer'>
							{isChatDisabled ? (
								<span style={{ width: '100%', textAlign: 'center', fontSize: '14px', color: '#bdbdbd' }}>
									{t<string>('chatIsDisabled')}
								</span>
							) : (
								<Form
									name='chatForm'
									form={chatForm}
									className='chat-form'
									onValuesChange={() => setText(chatForm.getFieldValue('currentMessage')?.trim())}
								>
									<Form.Item style={{ width: '26px', display: 'flex', alignItems: 'center' }}>
										<label>
											<Input
												type='file'
												className='hide'
												disabled={
													isChatDisabled ||
													isChatTerminated ||
													!isChatConnected ||
													checkAppealEnable ||
													!stompClient?.connected
												}
												onChange={handleUploadFileClick}
											/>
											<FileImageOutlined style={{ ...iconStyle }} />
										</label>
									</Form.Item>
									<Form.Item
										name='currentMessage'
										rules={[
											{
												validator: (_, value) => {
													if (INPUT_CHAT_VALIDATION.test(value)) {
														setDisabledSend(false);
														return Promise.resolve();
													}
													setDisabledSend(true);
													return Promise.reject(new Error(t('validationChatMessage') as string));
												},
											},
										]}
									>
										<TextArea
											disabled={
												isChatDisabled ||
												isChatTerminated ||
												!isChatConnected ||
												checkAppealEnable ||
												!stompClient?.connected
											}
											onPressEnter={onChatKeyUp}
											placeholder={t<string>('typeMessageHere')}
											autoSize={{ minRows: 1, maxRows: 3 }}
											style={{ paddingInlineEnd: '30px' }}
										/>
									</Form.Item>
									<SmileOutlined
										className='custom-icon'
										onClick={() => setOpenEmojiPicker((prvState) => !prvState)}
										style={{
											left: directionState === 'rtl' ? '60px' : 'unset',
											right: directionState === 'ltr' ? '60px' : 'unset',
										}}
									/>
									<EmojiPicker open={openEmojiPicker} onEmojiClick={handleEmojiClick} className='emoji-picker' />
									<button
										disabled={
											isChatDisabled ||
											isChatTerminated ||
											!isChatConnected ||
											!text ||
											checkAppealEnable ||
											disabledSend ||
											!stompClient?.connected
										}
										onClick={onMessageSend}
									>
										<SendOutlined style={{ ...iconStyle, transform: 'rotate(-30deg)', padding: '5px 5px 5px 7px' }} />
									</button>
								</Form>
							)}
						</div>
					</div>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 7 }} />
			)}
		</div>
	);
}

export default ChatContainer;
