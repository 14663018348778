import { MainState } from '../../models/main-state';
import { getUserInfo } from '../../helpers/localStorageHandler';

const userInfo = getUserInfo();

export const initialMainState: MainState = {
	isLoggedIn: Boolean(userInfo?.token) ?? false,
	email: userInfo?.email ?? '',
	fullName: userInfo?.fullName ?? '',
	userName: userInfo?.userName ?? '',
	countryCode: userInfo?.countryCode ?? '',
	role: userInfo?.role ?? '',
	canHandleAppeal: userInfo?.canHandleAppeal ?? false,
	canHandleRequests: userInfo?.canHandleRequests ?? false,
	language: userInfo?.language ?? '',
};

export const resetMainState: MainState = {
	email: '',
	fullName: '',
	userName: '',
	countryCode: '',
	role: '',
	canHandleAppeal: false,
	canHandleRequests: false,
	language: '',
	isLoggedIn: false,
};
