import { Grid, Modal, Table, notification } from 'antd';
import { Wallet } from '../types/Wallet';
import { useEffect, useMemo, useState } from 'react';
import { columns } from '../columns';
import { useTranslation } from 'react-i18next';
import { TransactionReportModal } from '../modals/TransactionReportModal';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import AdminUserListTable from './AdminUserListTable';
import { ContactUserModal } from '../modals/contactUserModal';
import { useLocation } from 'react-router-dom';
import { useConfigurationContext } from '../../../store/configurationContext';
import { StyledPagination } from '../../../components/table.style';

type WalletTableProps = {
	data: Wallet[];
	loading: boolean;
	page: number;
	numberOfEntries: number;
	setPage: (page: number) => void;
	total: number;
	setOnRequest: (value: boolean) => void;
	onRequest: boolean;
};

const getOperation = (type: string) => {
	if (type.includes('P2P')) return 'P2P';
	if (type.includes('TRADING')) return 'TRADING_ACCOUNT';
	if (type.includes('ADMIN')) return 'ADMIN_OPERATION';
};

export const WalletTable = (props: WalletTableProps) => {
	const { lg } = Grid.useBreakpoint();
	const token = getUserInfo()?.token;
	const location = useLocation();
	const { t } = useTranslation();
	const { configurationState } = useConfigurationContext();
	const queryParams = new URLSearchParams(location.search);

	const [transactionReportModalOpen, setTransactionReportModalOpen] = useState(false);
	const [transactionReportData, setTransactionReportData] = useState<{ payload: any; title: string }>();
	const [showUserListPopup, setShowUserListPopup] = useState(false);
	const [showMailModal, setShowMailModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isOtpValid, setIsOtpValid] = useState(false);

	const transactionNumber = queryParams.get('transactioNumber');
	const transactionType = queryParams.get('type');

	const showRequestConfirmation = (value: string, recaptcha?: string, isEnabled?: boolean, otp?: string) => {
		// Construct headers conditionally
		const headers: Record<string, string> = {
			Authorization: `Bearer ${token}`,
		};

		if (isEnabled && recaptcha) {
			headers['RECAPTCHA-TOKEN'] = recaptcha;
		}
		setIsLoading(true);
		axios
			.post(
				API_ENDPOINTS.sendRequestAction,
				{
					action: value,
					transactionNumber: transactionReportData?.payload.transactionNumber,
					otp: otp ?? undefined,
				}, // Pass an empty object for the request body
				{ headers }, // Include headers
			)
			.then((res) => {
				if (transactionReportData?.payload?.type) {
					const operation = getOperation(transactionReportData?.payload?.type) || 'TRADING_ACCOUNT';
					onView(operation, transactionReportData?.payload.transactionNumber);
				}
				setIsOtpValid(true);
			})
			.catch((err) => {
				if (err.response?.data.code === 2050 || err.response?.data.code === 2051) {
					setIsOtpValid(false);
				}
			})
			.finally(() => {
				props.setOnRequest(!props.onRequest);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (transactionNumber && transactionType) {
			getTransaction(transactionNumber, transactionType);
		}
	}, [location.search]);

	const onView = (operation: string, transactionNumber: string) => {
		getTransaction(transactionNumber, operation);
	};

	const getTransaction = (transactionNumber: string, operation: string) => {
		axios
			.get(
				API_ENDPOINTS.operationReport
					.replace('%operation%', operation)
					.replace('%transactionNumber%', transactionNumber ?? ''),
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((response) => {
				setTransactionReportData({
					payload: {
						...response.data,
					},
					title: response.data.type as unknown as string,
				});
				setTransactionReportModalOpen(true);
			})
			.catch(console.error);
	};
	const walletTableColumns = useMemo(
		() =>
			columns({
				isMobile: !lg,
				translate: t,
				onView,
			}),
		[lg, t],
	);

	const handelAssign = (userId: number) => {
		axios
			.post(
				API_ENDPOINTS.reassignRequest
					.replace('%taskId%', `${transactionReportData?.payload?.request?.requestId}`)
					.replace('%adminId%', `${userId}`),
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.then((res) => {
				notification['success']({ message: '', description: t<string>('reassignSuccessfully') });
				setShowUserListPopup(false);
				if (transactionReportData?.payload?.type) {
					const operation = getOperation(transactionReportData?.payload?.type) || 'TRADING_ACCOUNT';
					onView(operation, transactionReportData?.payload.transactionNumber);
				}
			})
			.catch((err: any) => console.error(err))
			.finally(() => setShowUserListPopup(false));
	};
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div>
			<TransactionReportModal
				open={transactionReportModalOpen}
				payload={transactionReportData?.payload}
				setOpen={setTransactionReportModalOpen}
				title={transactionReportData?.title}
				setShowUserListPopup={setShowUserListPopup}
				handelAssign={handelAssign}
				onRequestAction={showRequestConfirmation}
				showMailModal={setShowMailModal}
				isRequestLoading={isLoading}
				isOtpValid={isOtpValid}
			/>
			<AdminUserListTable
				showClosePopup={showUserListPopup}
				setShowClosePopup={setShowUserListPopup}
				handelAssign={handelAssign}
			/>
			<ContactUserModal
				open={showMailModal}
				transactionNumber={transactionReportData?.payload.transactionNumber}
				userName={transactionReportData?.payload.userName}
				phone={transactionReportData?.payload.phone}
				email={transactionReportData?.payload.email}
				setOpen={setShowMailModal}
			/>

			<StyledPagination bgColor={themeColor} color={secondary_color}>
				<Table
					columns={walletTableColumns}
					dataSource={props.data}
					loading={props.loading}
					pagination={{
						pageSize: props.numberOfEntries,
						onChange(page, pageSize) {
							props.setPage(page - 1);
						},
						total: props.total,
						current: props.page + 1,
						showSizeChanger: false,
					}}
					tableLayout='fixed'
					rowKey='id'
				/>
			</StyledPagination>
		</div>
	);
};
