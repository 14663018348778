import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSubHeader } from '../../components/PageSubHeader';
import { RequestFilters } from './components/RequestFilters';
import { RequestTable } from './components/RequestTable';
import { themeConfig } from './config';
import { ConfigProvider } from 'antd';
import { MyAccount } from './types/MyAccount';
import { Request } from './types/Request';
import axios from 'axios';
import { RequestResponse } from './types/RequestResponse';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getUserInfo } from '../../helpers/localStorageHandler';

import dayjs, { Dayjs } from 'dayjs';

export const WithdrawRequest = () => {
	const { t } = useTranslation();

	const [numberOfEntries, setNumberOfEntries] = useState('10');
	const [data, setData] = useState<Request[]>([]);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);

	const [loading, setLoading] = useState<boolean>(false);
	const [search, setSearch] = useState('');
	const [status, setStatus] = useState<string | null>(null);
	const [dateRange, setDateRange] = useState<string[]>([]);
	const [account, setAccount] = useState<string>('');
	const [assignee, setAssignee] = useState();
	const [onRequest, setOnRequest] = useState(false);

	const token = getUserInfo()?.token;

	const refresh = useCallback(() => {
		fetchRequests();
	}, []);

	const searchParameter = useMemo(
		() =>
			[
				['createdAt', dateRange],
				['requestState', status],
				['transaction%23tradingAccount%23tpAccountNumber', account],
				['assignee%23id', assignee],
			]
				.filter(([key, value]) => {
					if (key === 'createdAt') return Boolean(value?.length);
					return Boolean(value);
				})
				.map(([key, value]) =>
					key === 'createdAt'
						? `createdAt%3E${value?.[0]}%2CcreatedAt%3C${dayjs(value?.[1]).format('YYYY-MM-DD 23:59:00')}`
						: `${key}%3A${value}`,
				)
				.join('%2C'),
		[dateRange, status, account, assignee],
	);
	const params = useMemo(
		() =>
			[
				['page', page],
				['pageSize', numberOfEntries],
				['sort', 'createdAt-desc'],
			]
				.filter(([, value]) => !!value)
				.reduce(
					(params, [key, value]) => ({
						...params,
						[key as string]: value,
					}),
					{},
				),
		[numberOfEntries, page],
	);
	useEffect(() => {
		fetchRequests();
	}, [params, token, searchParameter, search, onRequest]);

	useEffect(() => {
		setPage(0);
	}, [dateRange, status, account]);
	const fetchRequests = () => {
		setLoading(true);
		let searchParams = '';
		if (searchParameter) {
			searchParams = `?search=${searchParameter}`;
		}
		if (search) {
			searchParams += searchParams ? `&wildSearch=${search}` : `?wildSearch=${search}`;
		}
		axios
			.get<RequestResponse>(`${API_ENDPOINTS.withdrawRequest}${searchParams}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...params,
				},
			})
			.then((response) => {
				setData(response?.data?.data || []);
				setTotalElements(response?.data?.totalElements);
			})
			.catch(console.error)
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<ConfigProvider theme={themeConfig}>
			<PageSubHeader title={t('withdrawRequest')} description={t('walletTransactionsDescription')} />
			<div className='min-h-[84vh] mx-auto px-[10px] lg:px-[75px] py-5 bg-white'>
				<RequestFilters
					className='mb-0'
					numberOfEntries={numberOfEntries}
					setNumberOfEntries={setNumberOfEntries}
					search={search}
					setDateRange={setDateRange}
					setSearch={setSearch}
					status={status}
					setStatus={setStatus}
					refresh={refresh}
					setAccount={setAccount}
					account={account}
					assignee={assignee}
					setAssignee={setAssignee}
				/>
				<RequestTable
					data={data}
					loading={loading}
					page={page}
					numberOfEntries={Number(numberOfEntries)}
					setPage={setPage}
					total={totalElements}
					setOnRequest={setOnRequest}
					onRequest={onRequest}
				/>
			</div>
		</ConfigProvider>
	);
};
