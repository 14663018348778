import { Merchant } from '../models/Merchants';
import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { getInitials } from './getStringInitials';
import {
	DislikeFilled,
	LikeFilled,
	CaretDownOutlined,
	CaretUpOutlined,
	PauseCircleOutlined,
	StopOutlined,
	EditFilled,
} from '@ant-design/icons';
import './merchantsTableColumns.scss';
import { Button, TableColumnType, Tooltip } from 'antd';
import { getUserLanguageInCookie } from './cookiesHandler';

import { Link } from 'react-router-dom';
import { handleSortClick } from './sorterColumn';
import { ColumnTitle } from '../components/ColumnTitle/ColumnTitle';

export const getMerchantsColumns = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	setShowSuspended: (value: boolean) => void,
	setShowBlock: (value: boolean) => void,
	setMerchant: (merchant: Merchant) => void,
	isMobile: boolean,
) => {
	const columns: ColumnsType<Merchant> = [
		{
			title: <ColumnTitle title={t<string>('merchantProfile')} />,
			dataIndex: 'fullName',
			key: 'fullName',
			width: '22%',
			responsive: ['lg'],
			render: (value, record) => {
				const blocked = record.status === 'BLOCKED';
				const suspended = record.status === 'SUSPENDED';
				const blockedText = blocked ? t('unblock') : t('block');
				const suspendedText = suspended ? t('unsuspend') : t('suspend');

				return (
					<div className='flex'>
						<div className='w-[35%]'>
							<Tooltip title={t<string>('myOffers')}>
								<Link to={`/myOffers/?merchantId=${record.merchantId}&status=${record.status}`}>
									<EditFilled className='cursor-pointer text-black font-bold' />
								</Link>
							</Tooltip>
							<Tooltip title={suspendedText}>
								<PauseCircleOutlined
									style={{ color: suspended ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowSuspended(true);
										setMerchant(record);
									}}
								/>
							</Tooltip>
							<Tooltip title={blockedText}>
								<StopOutlined
									style={{ color: blocked ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowBlock(true);
										setMerchant(record);
									}}
								/>
							</Tooltip>
						</div>
						<div
							style={{
								borderStyle: 'solid',
								borderWidth: '1px',
								borderColor: '#000',
							}}
							className={`h-[30px] w-[30px] !rounded-full flex items-center justify-center p-4  ${
								getUserLanguageInCookie() === 'ar' ? 'ml-2' : 'mr-2'
							}`}
						>
							{getInitials(value)}
						</div>
						<div className='w-full word-break-column'>
							<Link
								to={`/merchant/?id=${record.merchantId}`}
								className='word-break-column text-[#01081E99] flex items-center text-[#0ea5e9] underline'
							>
								{value}
							</Link>
							<div className='text-xs text-[#01081E99] flex items-center'>
								{record.ordersCompleted} {t<string>('orders')}{' '}
								<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
								{(
									(Number(record?.ordersCompleted) /
										(record?.totalOrders === null || record?.totalOrders === 0 ? 1 : Number(record?.totalOrders))) *
									100
								).toFixed()}
								% {t<string>('completionRate')}
							</div>
							<div className='flex items-baseline'>
								<Link to={`/review/?merchantId=${record.merchantId}`} className='text-xs'>
									{record.totalReviews} {t<string>('reviews')}
								</Link>
								<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
								<p className={` flex m-0 p-0 items-center ${record.rating < 80 ? 'text-[#F6465D]' : 'text-[#33A867]'}`}>
									{record.rating < 80 ? (
										<DislikeFilled
											className={`h-[13px] w-[13px]  ${getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'}`}
										/>
									) : (
										<LikeFilled
											className={`h-[13px] w-[13px]  ${getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'}`}
										/>
									)}
									{record.rating}%
								</p>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: <ColumnTitle title={t<string>('contactInfo')} />,
			responsive: ['lg'],
			dataIndex: 'emailAddress',
			width: '15%',
			key: 'emailAddress',
			render: (value, record) => {
				return (
					<div className='word-break-column '>
						<div className=' text-black'>{value}</div>
						<div className='text-xs text-[#999999]'>{record.phoneNumber}</div>
					</div>
				);
			},
		},
		{
			title: (
				<div className='text-[#000D1D99]  flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('walletBalance')} />
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'availableBalance-asc'))}
							style={{ color: sortBy === 'availableBalance-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'availableBalance-desc'))}
							style={{ color: sortBy === 'availableBalance-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'walletBalance',
			key: 'walletBalance',
		},
		{
			title: (
				<div className='text-[#000D1D99]  flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('frozenBalance')} />
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'frozenBalance-asc'))}
							style={{ color: sortBy === 'frozenBalance-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'frozenBalance-desc'))}
							style={{ color: sortBy === 'frozenBalance-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'frozenBalance',
			key: 'frozenBalance',
		},
		{
			title: <ColumnTitle title={t<string>('countryCurrency')} />,
			dataIndex: 'country',
			key: 'country',
			responsive: ['lg'],
			render: (_: string, row: Merchant) => (
				<p className='font-medium text-lightBlue flex items-center m-0'>
					{row.country.code}/{row.currency}
				</p>
			),
		},
		{
			title: <ColumnTitle title={t<string>('offers')} />,
			responsive: ['lg'],
			render: (_: string, row: Merchant) => (
				<div className='flex items-baseline flex-col gap-2'>
					{row.activeBuyer ? (
						<div className='bg-[#00AFFF20] text-[#00AFFF] text-[10px] rounded py-1 px-2'>{t<string>('buyer')}</div>
					) : (
						<></>
					)}
					{row.activeSeller ? (
						<div className='bg-[#00AFFF20]  items-baseline text-[10px]  text-[#00AFFF]  rounded py-1 px-2'>
							{t<string>('seller')}
						</div>
					) : (
						<></>
					)}
				</div>
			),
			// align: 'center' as any,
		},
		{
			title: (
				<div className=' text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('creationDate')} />
					<div className='flex flex-col mx-2 '>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'registerationDate-asc'))}
							style={{ color: sortBy === 'registerationDate-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'registerationDate-desc'))}
							style={{ color: sortBy === 'registerationDate-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (value) => {
				return <div className=' text-[#999999]'>{value}</div>;
			},
		},
		{
			title: <ColumnTitle title={t<string>('status')} />,
			responsive: ['lg'],
			dataIndex: 'status',
			key: 'status',
			render: (value) => {
				return (
					<p
						style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}
						className='text-[#999999] m-0'
					>
						{t(value.toLocaleLowerCase())}
					</p>
				);
			},
		},
		...(isMobile
			? [
					{
						dataIndex: 'record',
						key: 'record',
						className: 'small-screen-row',
						render: (value, record) => {
							return (
								<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col  w-100 justify-between items-center '>
									<div className='w-full'>
										<div className='flex flex-row py-2 justify-between'>
											<div> {t<string>('merchant')}:</div>
											<div>
												<div className='flex justify-end'>
													<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center justify-center'>
														<Tooltip title={t<string>('myOffers')}>
															<Link
																to={`/myOffers/?merchantId=${record.merchantId}&status=${record.status}`}
																className='mx-2'
															>
																<EditFilled className='cursor-pointer text-black font-bold' />
															</Link>
														</Tooltip>
														<PauseCircleOutlined
															className='px-1'
															onClick={() => {
																setShowSuspended(true);
																setMerchant(record);
															}}
														/>
														<StopOutlined
															className='px-1'
															onClick={() => {
																setShowBlock(true);
																setMerchant(record);
															}}
														/>
													</div>
													<Link
														to={`/merchant/?id=${record.merchantId}`}
														className='text-sm font-bold text-[#01081E99] flex items-center underline'
													>
														{record.fullName}
													</Link>
												</div>
												<div>
													<p className='text-xs text-[#01081E99] my-2 flex items-center'>
														{record.ordersCompleted} {t<string>('orders')}{' '}
														<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
														{(
															(Number(record?.ordersCompleted) /
																(record?.totalOrders === null || record?.totalOrders === 0
																	? 1
																	: Number(record?.totalOrders))) *
															100
														).toFixed()}
														% {t<string>('completionRate')}
													</p>

													<p
														className={`text-xs flex m-0 justify-end items-center ${
															record.rating < 80 ? 'text-[#F6465D]' : 'text-[#33A867]'
														}`}
													>
														{record.rating < 80 ? (
															<DislikeFilled
																className={`h-[13px] w-[13px]  ${
																	getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'
																}`}
															/>
														) : (
															<LikeFilled
																className={`h-[13px] w-[13px]  ${
																	getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'
																}`}
															/>
														)}
														{record.rating}%
														<Link to={`/review/?merchantId=${record.merchantId}`}>
															<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />{' '}
															{record.totalReviews} {t<string>('reviews')}
														</Link>
													</p>
												</div>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('email')}:</div>
											<div className='text-[15px] font-medium text-lightBlue flex items-center'>
												{record.emailAddress}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('phone')}:</div>
											<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
												{record.phoneNumber}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('status')}:</div>
											<div
												className='flex items-center  flex-wrap rounded-sm text-[14px] font-medium text-[#000] px-2 '
												style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}
											>
												{record.status}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('walletBalance')}:</div>
											<div className='text-xs text-[15px] font-medium text-lightBlue  flex items-center '>
												{record.walletBalance}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('frozenBalance')}:</div>
											<div className='text-xs text-[15px] font-medium text-lightBlue  flex items-center '>
												{record.frozenBalance}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('countryCurrency')}:</div>
											<div className='flex flex-row items-center text-[15px] font-medium text-lightBlue'>
												{record.country.code}/{record.currency}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('type')}:</div>
											<div className='flex items-center'>
												{record.activeBuyer ? (
													<Button
														className={` mr-[5px] ${'bg-[#00AFFF20] text-[#00AFFF] font-bold border-none text-[13px]'}`}
													>
														{t<string>('buyer')}
													</Button>
												) : (
													<></>
												)}
												{record.activeSeller ? (
													<Button className={` ${'bg-[#00AFFF20] text-[#00AFFF] font-bold border-none text-[13px]'}`}>
														{t<string>('seller')}
													</Button>
												) : (
													<></>
												)}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('Date&Time')}:</div>
											<div className='flex items-center text-[15px] font-medium text-lightBlue'>{record.createdAt}</div>
										</div>
									</div>
								</div>
							);
						},
					} as TableColumnType<Merchant>,
			  ]
			: []),
	];

	return columns;
};
