import { Alert, Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreditCardOutlined } from '@ant-design/icons';
import './OrderDetails.scss';
import { useNavigate } from 'react-router-dom';
import { OrderSource } from '../../../../types/OrderSource';

type OrderDetailsProps = {
	unitPrice: string;
	quantity: string;
	amount: string;
	note: string;
	orderNumber: string;
	source: OrderSource;
};

function OrderDetails({ unitPrice, quantity, amount, note, orderNumber, source }: OrderDetailsProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<div className='order-details-wrapper'>
			<h3 className='order-details-title'>
				{t<string>('orderDetails')}
				<Tooltip placement='top' title={t<string>('OrderWalletTransaction')}>
					{source === 'WALLET' && (
						<CreditCardOutlined
							className='text-2xl cursor-pointer text-black mx-2'
							onClick={() => navigate(`/wallet?id=${orderNumber}&operation=P2P`)}
						/>
					)}
				</Tooltip>
			</h3>
			{amount !== 'undefined' ? (
				<>
					<div className='details-row'>
						<p>{t<string>('unitPrice')}</p>
						<p className='value' id='olc_unitPrice'>
							{unitPrice}
						</p>
					</div>
					<div className='details-row'>
						<p>{t<string>('quantity')}</p>
						<p className='value' id='olc_quantity'>
							{quantity}
						</p>
					</div>
					<div className='details-row'>
						<p>{t<string>('amount')}</p>
						<p className='value' id='olc_amount'>
							{amount}
						</p>
					</div>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
		</div>
	);
}

export default OrderDetails;
