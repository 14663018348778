import { TableColumnType, TableColumnsType } from 'antd';
import { Request } from './types/Request';
import { TFunction } from 'i18next';
import { RequetStatus } from './components/RequetStatus';

type ColumnTitleProps = {
	title: string;
};

const ColumnTitle = (props: ColumnTitleProps) => {
	return (
		<div className='text-[#000D1D99] flex items-center cursor-pointer'>
			<span>{props.title}</span>
		</div>
	);
};

export const columns = ({
	isMobile,
	translate,
	onView,
}: {
	isMobile: boolean;
	translate: TFunction;
	onView: (record: Request) => void;
}): TableColumnsType<Request> => [
	{
		key: 'transactionNumber',
		dataIndex: 'transactionNumber',
		title: <ColumnTitle title={translate('id')} />,
		sorter: (current, next) => Number(current.id) - Number(next.id),
		render: (value, record) => {
			return (
				<div className='cursor-pointer underline' onClick={() => onView(record)}>
					{value}
				</div>
			);
		},
		responsive: ['lg'],
	},
	{
		key: 'username',
		dataIndex: 'username',
		title: <ColumnTitle title={translate('user')} />,
		render: (value, record) => {
			return <div className='word-break-column font-[500] text-[#5bcbff]'>{value}</div>;
		},
		responsive: ['lg'],
	},
	{
		key: 'account',
		dataIndex: 'account',
		title: <ColumnTitle title={translate('tradingAccount')} />,
		render: (value, record) => {
			return <div className='cursor-pointer'>{value}</div>;
		},
		responsive: ['lg'],
	},
	{
		key: 'quantity',
		dataIndex: 'quantity',
		title: <ColumnTitle title={translate('withdrawAmount')} />,
		responsive: ['lg'],
	},
	{
		key: 'commission',
		dataIndex: 'commission',
		title: <ColumnTitle title={translate('withdrawCommission')} />,
		responsive: ['lg'],
	},
	{
		key: 'status',
		dataIndex: 'status',
		title: <ColumnTitle title={translate('status')} />,
		render: (value) => <RequetStatus status={value} />,
		responsive: ['lg'],
	},
	// --- Mobile ---
	...(isMobile
		? [
				{
					key: 'request',
					dataIndex: 'request',
					render: (_value, record) => (
						<div>
							<ul className='list-none p-0 m-0 flex flex-col gap-2'>
								<li className='flex items-center justify-between'>
									<span>{translate('id')}</span>
									<span className='cursor-pointer underline' onClick={() => onView(record)}>
										{record?.transactionNumber}
									</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('user')}</span>
									<span>{record?.username}</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('account')}</span>
									<span>{record?.account}</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('quantity')}</span>
									<span>{record?.quantity}</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('withdrawCommission')}</span>
									<span>{record?.commission}</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('status')}</span>
									<RequetStatus status={record?.status} />
								</li>
							</ul>
						</div>
					),
				} as TableColumnType<Request>,
		  ]
		: []),
];
