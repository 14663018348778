import { LanguagePhrases } from '../models/LanguagePhrases';

export const englishPhrases: LanguagePhrases = {
	signIn: 'Sign In',
	home: 'Home',
	signinTitle: 'Sign in to your payment account',
	signinDesc: 'Sign in using the credentials for the client portal',
	email: 'Email',
	password: 'Password',
	forgetPassword: 'Forget Password',
	emailRequired: 'Email field should not be empty!',
	passwordRequired: 'Password field should not be empty!',
	somethingWrong: 'Something went wrong!',
	emailMsg: 'Please make sure your email is valid!',
	passwordMsg:
		'Please make sure your password includes at least one capital letter, small letter, special character and the length of the password should be at least 8!',
	orders: 'Orders',
	problemLoadingListOfOrders: 'There is some problem loading list of orders',
	date: 'Date',
	orderType: 'Order Type',
	country: 'Country',
	amount: 'Amount',
	merchant: 'Merchant',
	rate: 'Rate',
	status: 'Status',
	buy: 'Deposit',
	sell: 'Withdraw',
	active: 'ACTIVE',
	appealed: 'Appealed',
	cancelled: 'Cancelled',
	closed: 'Closed',
	pending: 'Pending',
	problemLoadingCountries: 'There is a problem loading list of countries',
	becomeMerchant: 'Become a Merchant',
	yourAccountIsInactive: 'Your account is inactive. Please contact your Admin to activate your it',
	regularUserHome: 'Regular User Home',
	merchantHome: 'Merchant Home',
	merchantsOffer: 'Merchants',
	searchInMerchantsTable: 'Search Merchants',
	problemLoadingListOfMerchants: 'There is some problem loading the list of merchants',
	name: 'Name',
	rating: 'Rating',
	totalReview: 'Total Review',
	ordersCompleted: 'Orders Completed',
	price: 'Price',
	available: 'Available',
	minLimit: 'Min Limit',
	maxLimit: 'Max Limit',
	accountVerification: 'Account Verification',
	problemLoadingMerchant: 'There is some problem loading merchant info',
	merchantInfo: 'Merchant Info',
	wantToBuy: 'I want to buy',
	wantToSell: 'I want to sell',
	amountIsRequired: 'Amount is required',
	willReceive: 'I will receive',
	willSend: 'I will send',
	paymentMethod: 'Payment Method',
	paymentMethodIsRequired: 'Payment method is required',
	valueInRangeError: 'Amount should be in this range:',
	merchants: 'Merchants',
	countryCurrency: 'Country / Currency',
	paymentMethods: 'Payment Methods',
	myDetails: 'My Details',
	changePassword: 'Change Password',
	myDetailsForm: 'My Details Form',
	myProfile: 'My Profile',
	phone: 'Phone',
	address: 'Address',
	city: 'City',
	zip: 'Zip',
	state: 'State',
	firstName: 'First Name',
	surname: 'Surname',
	registrationDate: 'Registration Date',
	newPassword: 'New password',
	currentPassword: 'Current password',
	confirmPassword: 'Confirm Password',
	updatePassword: 'Update Password',
	changePasswordForm: 'Change Password Form',
	yourPasswordGotUpdated: 'Your password got updated',
	somethingWentWrongWithUpdatingPassword: 'Something went wrong with updating password',
	pleaseMakeSurePasswordIsValid:
		'Please make sure your password includes at least one capital letter, small letter, special character and the length of the password should be at least 8',
	twoPasswordsDoNotMatch: 'The two passwords must be the same',
	myOffers: 'My Offers',
	yourSellerFormGotUpdated: 'Your seller form got updated',
	somethingWentWrongWithUpdatingSellerForm: 'Something went wrong with updating seller form',
	yourBuyerFormGotUpdated: 'Your buyer form got updated',
	somethingWentWrongWithUpdatingBuyerForm: 'Something went wrong with updating buyer form',
	sellOffer: 'Sell offer',
	'country/currency': 'Country / Currency',
	profitRatio: 'Profit ratio',
	profitRatioIsRequired: 'Profit ratio is required',
	profitRatioInRangeError: 'Profit ratio can not be greater than',
	atleastSelectOneMethod: 'Please select atleast one payment method',
	maximumAmount: 'Maximum amount',
	valueMustBePositive: 'Value must be positive',
	mustBeGreaterThanMinValue: 'Value must be greater than minimum value',
	minimumAmount: 'Minimum amount',
	mustBeLessThanMaxValue: 'Value must be less than maximum value',
	visibleForUsers: 'Visible for users',
	no: 'No',
	yes: 'Yes',
	update: 'Update',
	buyOffer: 'Buy offer',
	logout: 'Log out',
	companies: 'Companies',
	news: 'News',
	privacyPolicy: 'Privacy Policy',
	termsOfUse: 'Terms of Use',
	merchantsOfferSubtitle: 'Seamless Transactions, Limitless Possibilities: Elevate Your Business with P2P Excellence',
	limit: 'Limit',
	countries: 'Countries',
	max: 'Max',
	min: 'Min',
	all: 'All',
	merchantProfile: 'Merchant Profile',
	availableLimit: 'Available / Limit',
	merchantType: 'Merchant Type',
	merchantId: 'Merchant ID',
	fullName: 'Full Name',
	currency: 'Currency',
	bestSellers: 'Best Sellers',
	maxOrders: 'Max Orders',
	newSellers: 'New Sellers',
	minOrders: 'Min Orders',
	fees: 'Fees',
	reviews: 'Reviews',
	trade: 'Trade',
	buyers: 'Buyers',
	sellers: 'Sellers',
	seller: 'Seller',
	buyer: 'Buyer',
	termsAndConditions: 'Terms & Conditions',
	unitPrice: 'Unit Price',
	youSale: 'You sale',
	youReceive: 'You receive',
	selectPaymentMethods: 'Select payment methods',
	account: 'Account',
	note: 'Note',
	pleaseWriteYourNote: 'Please write your note',
	certainPaymentMethodsMayHaveFees:
		'Certain payment methods may have fees and daily limits set by the payment provider.',
	youPay: 'You pay',
	orderHeading: 'Order',
	orderSubtitle: 'History Repeats Itself: Effortless Reorders, Every Time!',
	dateRange: 'Date Range',
	show: 'Show',
	entries: 'Entries',
	searchOrder: 'Search Order',
	id: 'ID',
	client: 'Client',
	type: 'Type',
	creationDate: 'Creation Date',
	availability: 'Availability',
	buyersTerm: "Buyer's Terms and Conditions",
	sellersTerm: "Seller's Terms and Conditions",
	bankDeposit: 'Bank Deposit Only',
	accHolder: 'Original Account Holder',
	thirdParty: 'Third Party Payment Not Accepted',
	wedFri: 'Wed-Fri',
	merchantReview: 'Merchant Review',
	apiNotFound: 'API Not Found',
	unableToAuthenticateTheUser: 'Invalid or expired session',
	somethingWentWrong: 'Something went wrong',
	constraintViolated: 'Constraint Violated',
	recordIsLinked: 'Record is linked',
	parametersCannotBeNull: 'Parameters cannot be null',
	methodArgumentsAreInvalid: 'Method arguments are invalid',
	fileUploadingError: 'File Uploading Error',
	parameterIsNotValid: 'Request parameter is not valid',
	requiredParametersAreMissing: 'Required parameters are missing',
	pleaseEnterValidEmailAddress: 'Please enter a valid email address',
	passwordDoesNotMatchRequirements:
		'Password does not match the requirements: minimum 8 and maximum 16 characters, include at atleast one capital letter, one small letter, one digit, and one special character',
	invalidMerchantOfferRequest: 'Invalid Merchant offer request',
	invalidTradingAccountGroup: 'Invalid Trading Account Group',
	countryIsNotAllowed: 'Country is not allowed in P2P system',
	orderIdProvidedIsInvalid: 'Order ID provided is invalid',
	orderIsClosed: 'Order is closed',
	actionNotApplicable: 'Action not applicable',
	errorOccurredWhileProcessingYourRequest: 'Error occurred while processing your request',
	problemOccurredWhileLoadingRecords: 'Problem occurred while loading records',
	problemOccurredWhileLoadingRecord: 'Problem occurred while loading record',
	problemOccurredWhileAddingRecord: 'Problem occurred while adding record',
	problemOccurredWhileUpdatingRecord: 'Problem occurred while updating record',
	problemOccurredWhileDeletingRecord: 'Problem occurred while deleting record',
	recordAlreadyExists: 'Record already exists',
	noRecordFound: 'No record found',
	accessDeniedMissingKyc: 'Access denied because of missing Kyc',
	invalidCredentials: 'Invalid credentials',
	invalidPaymentMethod: 'Invalid payment method',
	invalidOfferType: 'Invalid offer type',
	orderIsInProgress: 'Order is in progress',
	orderReviewIsAlreadyPresent: 'Order Review is already present',
	orderIsClosedMoreThanWeekAgo: 'Order is closed more than a week ago',
	userIsInactive: 'User is inactive',
	userCountryNotAllowed: 'User country not allowed',
	orderIsExpiredOrClosed: 'Order is expired or closed',
	invalidOrderAction: 'Invalid Order Action',
	onlyOneOrderAllowedAtTime: 'Only one order allowed at a time',
	invalidTradingAccount: 'Invalid Trading Account',
	merchantBelongsToDifferentCountry: 'Cannot trade! Merchant belongs to a different country',
	orderAmountIsMoreThanAvailableBalance: 'Order amount is more than available balance!',
	merchantOfferNotAvailable: 'Merchant Offer not available!',
	amountDoesNotSatisfyAvailableLimit: 'Amount does not satisfy account group limit!',
	countriesPaymentMethods: 'Countries/Payment Methods',
	countriesPaymentSubtitle: 'Make P2P TetherUS trades today with Zero Fees and your Preferred Payment Method!',
	countryCode: 'Country Code',
	currencyCode: 'Currency Code',
	maxProfitRatio: 'Max Profit Ratio',
	public: 'Public',
	administration: 'Administration',
	searchInCountriesAndPaymTable: 'Search Countries/Payment Methods',
	welcomeBack: 'Welcome back',
	loginToAdminPortal: 'Login To Admin Portal',
	enterYourEmail: 'Enter your email',
	enterPassword: 'Enter your password',
	readOur: 'Read our',
	clients: 'Clients',
	clientProfile: 'Client Profile',
	searchInClientsTable: 'Search Clients',
	clientsPageHeading: 'P2P Empowerment Hub: Connecting Users, Unleashing Possibilities',
	orderWith: '{{type}} order from {{name}}',
	timeCreated: 'Time Created',
	orderNumber: 'Order Number',
	clientDetails: 'Client Details',
	merchantDetails: 'Merchant Details',
	orderDetails: 'Order Details',
	paymentDetails: 'Payment Details',
	clientPay: 'Client Pay',
	clientAccount: 'Client Account',
	merchantReceive: 'Merchant Receive',
	orderHistory: 'Order History',
	copiedSuccessfully: 'Copied Successfully',
	accountGroupLimits: 'Account Group Limits',
	orderMin: 'Order Min',
	orderMax: 'Order Max',
	tradingPlatform: 'Trading Platform',
	tradingAccountGroup: 'Trading Account Group',
	addAccountGroupLimits: 'Add Account Group Limit',
	submit: 'Submit',
	editAccountGroupLimits: 'Edit Account Group Limit',
	deleteConfirmation: 'Are you sure you want to delete this record?',
	confirmDelete: 'Delete Confirmation',
	ok: 'Ok',
	cancel: 'Cancel',
	supportRoom: 'Support Room',
	theorder: 'The Order',
	color: 'Color',
	backgroundColor: 'Background Color',
	confirmNewPaymentMethodDelete: 'Are you sure you want to delete this payment method?',
	countryCodeIsRequired: 'Country Code is required',
	currencyCodeIsRequired: 'Currency Code is required',
	maxProfitRatioIsRequired: 'Max Profit Ratio is required',
	countryNameIsRequired: 'Country Name is required',
	reverseToPreviousStage: 'Reverse To Previous Stage',
	releasePayment: 'Release Payment',
	closeAppeal: 'Close Appeal',
	cancelOrder: 'Cancel Order',
	cancelThisOrder: 'Cancel This Order',
	cancelOrderMessage: 'I made sure that no payment was made by Client/Merchant',
	closeOrderMessage:
		'I made sure that both parties transferred the payment. On confirmation order will be marked as completed.',
	closeThisAppeal: 'Close This Appeal',
	releaseMerchantPayment: 'Release Merchant Payment',
	releaseMerchantPaymentMessage1: 'I have confirm that client has transferred the payment',
	releaseMerchantPaymentMessage2: 'I agree to release Merchant payment to the Client.',
	releaseMerchantPaymentMessageSell: 'I have confirm that Merchant has transferred the payment',
	releaseMerchantPaymentMessageSell2: 'I agree to release client payment to the merchant.',
	revertToPreviousStage: 'Revert To Previous Stage',
	revertToPreviousStageMessage: 'Are you sure that you want to change order status to the previous state?',
	Assignee: 'Assignee',
	Resolved: 'Resolved',
	AssignedOn: 'Assigned On',
	Reassign: 'Reassign',
	invalidmerchantOffer: 'Invalid Merchant Offer',
	invalidtradingaccountgroup: 'Invalid Trading account group',
	orderidisinvalid: 'Order ID provided is Invalid',
	Orderisclosed: 'Order is closed',
	invalidorderaction: 'Invalid Order Action',
	onlyoneorderallowed: 'Only one order allowed at time',
	cannotttrade: 'Cant trade, merchant belongs to different country',
	orderamountismorethanbalance: 'Order amount is more than available balance!',
	merchantoffernotavailable: 'Merchant Offer not available!',
	Amountdoesnotsatisfyavailablelimit: 'Amount does not satisfy account group limit!',
	ErroroccurredwhileprocessingMT5request: 'Error occurred while processing Trading Account request.!',
	Amountdoesnotsatisfyofferallowedlimit: 'Amount does not satisfy offer allowed limit!',
	cancelAppeal: 'Cancel Appeal',
	revert: 'REVERT',
	release: 'RELEASE',
	paymentMethodName: 'Payment Method Name',
	adminusers: 'Admin Users',
	admin: 'Admin',
	adminDetailsforAddEditAdmin: 'Admin Unity: Adding Leaders, Elevating Excellence Together',
	emailAddress: 'Email Address',
	role: 'Role',
	phoneNumber: 'Phone Number',
	canHandleAppeal: 'Can Handle Appeal',
	addNewAdmin: 'Add new Admin',
	lastName: 'Last Name',
	accountGroups: 'Account Groups',
	notificationsHeading: 'Notifications',
	noNotificationsFound: 'No Notifications Found',
	opened: 'Opened',
	appeal: 'Appeal',
	expired: 'Expired',
	placed: 'Placed',
	unknown: 'Unknown',
	quantity: 'Quantity',
	notAvailable: 'Not Available',
	currentpassword: 'Current Password',
	newpassword: 'New Password',
	matchedPassword: 'The new password that you entered do not match!',
	appealTask: 'Appeal Task',
	paymentReceived: 'Payment Received',
	activeTask: 'Active Task',
	paymentTransfered: 'Payment Transfered',
	accepted: 'Accepted',
	notificationNewAppealTaskAssigned:
		'An appeal is opened for {{orderType}} order #{{orderNumber}} for an amount of {{amount}}.',
	notificationAppealReassigned:
		'Your appeal task was reassigned to {{newAssigneeFullName}} by {{reassignMethod}} assignment.',
	notificationAppealNewMessage: 'New message in order #{{orderNumber}} at {{createdAt}}.',
	notificationAppealReminder: 'You have a pending appeal which will be reassigned in {{reassignedInMinutes}} hours.',
	seeMore: 'See More',
	finishedSuccessfuly: 'Finished Successfuly',
	adminUser: 'Admin user',
	requiredField: 'Required Field',
	enterValidValue: 'Please enter valid value',
	resolvedBy: 'Resolved By',
	assigneToMe: 'Assign To Me',
	averageRating: 'Average Rating',
	orderMaxShouldBeGreaterThanOrderMin: 'Order Max should be greater than Order Min',
	orderMinShouldBeLessThanOrderMax: 'Order Min should be less than Order Max',
	merchantPay: 'Merchant Pays',
	orderMaxShouldNotBeGreaterThanFiftyThousand: 'Order Max should not be greater than 50,000',
	orderMinShouldNotBeGreaterThanFiftyThousand: 'Order Min should not be greater than 50,000',
	buyMin: 'Deposit Min',
	buyMax: 'Deposit Max',
	sellMin: 'Withdrawal Min',
	sellMax: 'Withdrawal Max',
	accessDenied: 'Access denied',
	orderNumberProvidedIsInvalid: 'Order number provided is invalid',
	errorProccessingMT5: 'Error proccessing Trading Account',
	amountDosentSatisfyLimit: 'Amount dosent satisfy limit',
	chatHasBeenClosed: 'Chat has been closed',
	emailAlreadyExist: 'Email already exist',
	phoneNumberDoesNotMatchTheRequirement: 'Phone number does not match the requirement',
	newPasswordAndConfirmPasswordNotSame: 'New password and confirm password not same',
	userIsSuspended: 'User is suspended',
	userIsBlocked: 'User is blocked',
	merchantNotFound: 'Merchant not found',
	invalidOTP: 'Invalid one time passowrd',
	suspendedConfirmation: 'Are you sure you want to suspend',
	unsuspendedConfirmation: 'Are you sure you want to unsuspend',
	blockConfirmation: 'Are you sure you want to block',
	unblockConfirmation: 'Are you sure you want to unblock',
	notificationNewOrderMessage: `New {{orderType}} order #{{orderNumber}} for an amount of {{amount}} was opened.`,
	notificationOrderNewMessage: `New message in order #{{orderNumber}} at {{createdAt}}`,
	notificationOrderExpiryWarning: `Your order {{orderNumber}} is about to expire at {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `Order #{{orderNumber}} has expired.`,
	notificationOrderStatusUpdate: `Status change for order {{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `Your {{offerType}} offer has been taken down due to {{reason}}`,
	notificationAccountBalanceLow: `Your merchant’s account balance is running low. Current balance: {{amount}}`,
	notificationSellAvailableLow: `The sell offer amount you specified is about to run out. Update the amount in order for the offer to remain valid. Current available sell balance: {{sellAvailable}}`,
	yesterday: 'Yesterday',
	today: 'Today',
	daysAgo: 'Days ago',
	withdrawal: 'Withdrawal',
	deposit: 'Deposit',
	dashboardActiveOrders: 'Active Orders',
	dashboardAppealOrders: 'Appeal Orders',
	dashboardTotalOrders: 'Total Orders',
	dashboardTotalMerchants: 'Total Merchants',
	dashboardTotalClients: 'Total Clients',
	reports: 'Reports',
	thisMonth: 'This Month',
	thisYear: 'This Year',
	thisWeek: 'This Week',
	thisQuarter: 'This Quarter',
	last60Days: 'Last 60 Days',
	totalAnalyticsOrder: 'Total Analytics Orders',
	buyOrders: 'Deposit Orders',
	sellOrders: 'Withdrawal Orders',
	myOffersSubtitle: 'These are the offers that will be presented to your clients on Merchant',
	calculatedPrice: 'Final Offer Price',
	notFound: 'Order Not Found',
	actions: 'Actions',
	orderId: 'Order ID',
	noPaymentMethod: 'No Payment Method',
	sellMaxShouldNotBeGreaterThanFiftyThousand: 'Withdraw Max should not be greater than 50,000',
	sellMinShouldNotBeGreaterThanFiftyThousand: 'Withdraw Min should not be greater than 50,000',
	buyMaxShouldNotBeGreaterThanFiftyThousand: 'Deposit Max should not be greater than 50,000',
	buyMinShouldNotBeGreaterThanFiftyThousand: 'Deposit Min should not be greater than 50,000',
	sellMaxShouldBeGreaterThanSellMin: 'Withdraw Max should be greater than Withdraw Min',
	sellMinShouldBeLessThanSellMax: 'Withdraw Min should be less than Withdraw Max',
	buyMaxShouldBeGreaterThanBuyMin: 'Deposit Max should be greater than Deposit Min',
	buyMinShouldBeLessThanBuyMax: 'Deposit Min should be less than Deposit Max',
	invalidType: 'Invalid file extension. Please upload a JPG, JPEG, PNG, or PDF file.',
	fileExceedLimit: 'File size exceeds the allowed limit (5MB).',
	expireIn: 'Expire In',
	enterOtp: 'Enter OTP',
	exchangeRate: 'Exchange Rate',
	sortBy: 'Sort By',
	enterYourOtp: 'Enter your email verification code',
	notificationPendingAppeal:
		'Appeal with {{orderNumber}} is not responded by {{oldAssigneeFullName}} in {{inactiveTimeMinutes}} click here to reassign',
	cannotEnableOffer: 'Can not Enable Offer Due to Low Balance',
	ActivateConfirmation: 'Are you sure you want to activate',
	deactivateConfirmation: 'Are you sure you want to deactivate',
	passwordChange: 'Change Password',
	didNotreceiveOtp: 'Didn’t get the code?',
	resend: 'Resend',
	biggerThanZero: 'Value must be bigger than 0',
	lessThanhundred: 'Value must be less than 100 or equal 100',
	inactive: 'INACTIVE',
	appealReassignNewAdmin:
		'New appeal task was reassigned to you by {{oldAssigneeFullName}} by  {{reassignMethod}} assignment',
	NEW_ORDER: 'New Order',
	ORDER_NEW_MESSAGE: 'Order New Message',
	ORDER_EXPIRY_WARNING: 'Order Expiry Warning',
	ORDER_EXPIRED: 'Order Expired',
	ORDER_STATUS_UPDATE: 'Order Status Update',
	OFFER_DISABLED: 'Offer Disabled',
	ACCOUNT_BALANCE_LOW: 'Account Balance Low',
	SELL_AVAILABLE_LOW: 'Sell Available Low',
	NEW_APPEAL_TASK_ASSIGNED: 'New Appeal Task Assigned',
	APPEAL_NEW_MESSAGE: 'Appeal New Message',
	APPEAL_REMINDER: 'Appeal Remindar',
	PENDING_APPEAL: 'Pending Appeal',
	APPEAL_REASSIGNED: 'Appeal Reassigned',
	APPEAL_REASSIGNED_NEW_ADMIN: 'Appeal Reassign New Admin',
	handleAppeal: 'Handle Appeals',
	appealTasks: 'Appeal Tasks',
	transferred: 'Transferred',
	completed: 'Completed',
	rejected: 'Rejected',
	INSUFFICIENT_BALANCE: 'Insufficient Balance',
	COOLDOWN: 'Cooldown',
	BY_ADMIN: 'By Admin',
	MANUAL: 'Manual',
	INACTIVE: 'Inactive',
	typeMessageHere: 'Type a message here',
	OTPIsrequired: 'OTP is required!',
	Pleaseuploadthepaymentproof: 'Please upload the payment proof',
	agent: 'Agent',
	chatIsDisabled: 'Chat is disabled',
	sessionExpired: 'Session Expired',
	block: 'Block',
	unblock: 'Unblock',
	suspend: 'Suspend',
	unsuspend: 'Unsuspend',
	search: 'Search',
	allRightsReserved: 'All Rights Reserved',
	customeRange: 'Custome Range',
	confirm: 'Confirm',
	suspendClient: 'Suspend Client',
	suspendMerchant: 'Suspend Merchant',
	unsuspendMerchant: 'Unsuspend Merchant',
	unsuspendClient: 'UnSuspend Client',
	download: 'Download',
	chatnow: 'Chat Now',
	dashboardTotalWithdrawalAmount: 'Total Withdrawal Amount',
	dashboardTotalDepositAmount: 'Total Deposit Amount',
	merchantDetailsDescription:
		'Explore detailed merchant profiles and their reviews, offering valuable insights to enhance your P2P trading experience',
	merchantsReviewSubtitle: 'Nurturing Trust and Quality Across the Platform',
	appealTaskSubtitle: 'P2P Appeal Central: Where Challenges Meet Empowered Resolutions',
	CountryAndPaymentMethods: 'Precision in Every Transaction: Tailoring Countries and Payments for Your Success',
	accountGroupLimitsSubtitle: 'Limitless Possibilities, Defined Control: Admin Crafting Success Through Order Limits',
	completionRate: 'Completion',
	tradingPaltform: 'Trading Platform',
	accountGroup: 'Account Group',
	accountNumber: 'Account Number',
	balance: 'Balance',
	onHoldBalance: 'On Hold Balance',
	otpexpired: 'OTP has expired!',
	reachedotplimit: 'You have reached maximum attempts for OTP!!',
	maintenance: 'Maintenance',
	maintenanceSubtitle: 'Connecting hands for seamless maintenance solutions',
	startTime: 'Start Time',
	endTime: 'End Time',
	enabled: 'Enable Banner',
	deleteMaintanceConfirmation: 'Are you sure you want to delete this Maintenance?',
	annoncement: 'Scheduled portal maintenance from {{from}} - {{to}}',
	depositBouns: 'Deposit Bonus',
	withdrawBouns: 'Withdraw Bouns',
	CurrencySubtitle: 'Expand Your Reach, Add Currency Today',
	add: 'Add',
	INVALID_MIN_MAX_RANGE: 'Invalid min/max range',
	FORM_IS_INVALID: 'Form is invalid',
	walletTransactions: 'Wallet Transactions',
	walletTransactionsDescription: 'Elevate Your P2P Experience with Our Versatile Wallet',
	assignee: 'Assignee',
	assignedOn: 'Assigned on',
	reassign: 'Reassign',
	approve: 'Approve',
	reject: 'Reject',
	withdrawCommission: 'Withdraw Commission',
	wallet: 'Wallet',
	contactUser: 'Contact user',
	transactionReport: 'Transaction report',
	searchWallet: 'Search wallet',
	user: 'User',
	walletNumber: 'Wallet number',

	PENDING: 'Pending',
	COMPLETED: 'Completed',
	REVERTED: 'Reverted',
	PENDING_APPROVAL: 'Pending Approval',
	CANCELLED: 'Cancelled',
	P2P_BUY: 'P2P Buy',
	P2P_SELL: 'P2P Sell',
	P2P_REFUND: 'P2P Refund',
	TRADING_DEPOSIT: 'Trading Deposit',
	TRADING_WITHDRAW: 'Trading Withdrawal',
	EXCHANGE: 'Exchange',
	WALLET: 'Wallet',

	canHandleRequest: 'Can Handle Request',
	offers: 'Offers',
	tradingAccount: 'Trading Account',
	walletsAccount: 'Wallet Account',

	Currencycodealreadyexist: 'Currency code already exist',
	Walletnotexist: 'Wallet not exist',
	Walletbalanceunsatisfied: 'Wallet balance unsatisfied',
	Merchantwalletscurrencyisdifferent: 'Merchant wallets currency is different',
	Walletonholdbalanceunsatisfied: 'Wallet on hold balance unsatisfied',
	Erroroccurredwhiledoingtransaction: 'Error occurred while doing transaction',
	Youarenotallowedtoperformthisaction: 'You are not allowed to perform this action',
	p2pBuyOrder: 'P2P Buy Order',
	p2pSellOrder: 'P2P Sell Order',
	depositToTradingAccount: 'Deposit To Trading Account',
	withdrawFromTradingAccount: 'Withdraw From Trading Account',
	actionBy: 'Action By',

	requestConfirmation: 'Are you sure you want to {{type}} this Transaction?',
	userName: 'User Name',
	transactionNumber: 'Transaction Number',
	sendEmail: 'Send Email',
	withdrawRequest: 'Withdraw Requests',
	withdrawAmount: 'Withdraw Amount',
	transactionId: 'Transaction ID',
	REQUEST_REMINDER: 'REQUEST REMINDER',
	PENDING_REQUEST: 'PENDING REQUEST',
	REQUEST_REASSIGNED: 'REQUEST REASSIGNED',
	REQUEST_REASSIGNED_NEW_ADMIN: 'REQUEST REASSIGNED NEW ADMIN',
	P2P_TRANSACTION_REQUESTS: 'P2P TRANSACTION REQUESTS',
	requestRemindar: 'You have a pending request which will be reassigned in {{reassignedInMinutes}} hour(s)',
	pendingRequest:
		'Transaction Request with {{transactioNumber}} is not responded by {{oldAssigneeFullName}} in {{inactiveTimeMinutes}} hour(s), click here to reassign.',
	requestReassign:
		'Your transaction request was reassigned to {{newAssigneeFullName}} by  {{reassignMethod}} assignment',
	requestReassignedNewAdmin:
		'New transaction request was reassigned to you by $oldAssigneeFullName by  $reassignMethod assignment',
	p2pTransactionRequest:
		'You made {{transactionType}} of {{amount}}, Your {{transactionType}} request of {{amount}} has been {{requestState}}',
	OrderWalletTransaction: 'Order Wallet Transaction',
	depositWithdrawRequest: 'I agree to {{type}} the transaction of {{amount}}',
	approveTransaction: 'Approve Transaction',
	rejectTransaction: 'Reject Transaction',
	transactionRequest: 'A {{transactionType}} request with {{transactionNumber}} is assigned you of {{amount}}',
	TRANSACTION_REQUEST: 'Transaction Request',
	handleRequest: 'Handle Request',
	searchAdmin: 'Search Admin',
	reassignSuccessfully: 'Reassign Successfully',
	ordersAmountStatistic: 'Order Amount Statistics',
	buyAmount: 'Deposit Amount',
	sellAmount: 'Withdrawal Amount',
	validationChatMessage: 'special characters <, >, & are not allowed.',
	withdrawRequesttitle: 'Withdraw from Wallet',
	depositRequestTitle: 'Deposit to Wallet',
	withdrawRequestSubtitle: 'The amount will be debited from Wallet within a minute.',
	depositRequestSubtitle: 'The amount will be credited to Wallet within a minute.',
	withdrawAction: 'Withdraw',
	depositAction: 'Deposit',
	errorAvalibleWalletBalance: 'should be less than avalible wallet balance',
	successfullyWithdraw: 'Successfully withdraw {{amount}} {{currency}}',
	successfullyDeposit: 'Successfully Deposit {{amount}} {{currency}}',
	merchantsDashborad: 'Merchants Dashborad',
	depositOrder: 'Deposit Order',
	withdrawOrder: 'Withdraw Order',
	completedOrders: 'Completed Orders',
	cancelledOrders: 'Cancelled Orders',
	completedOrdersSubtitle: 'Merchant Completed Orders.',
	cancelledOrdersSubtitle: 'Merchant Cancelled Orders.',
	expiredOrders: 'Expired Order',
	expiredOrdersSubtitle: 'Merchant Expired Orders.',
	appealOrders: 'Appeal Orders',
	appealOrdersSubtitle: 'Merchant Appeal Orders.',
	CustomerReport: 'Customer Report',
	withdraw: 'Withdraw',
	typeSearch: 'Type To Search',
	customers: 'Customers',
	repeatedOrders: 'Customers with Repeated Order',
	oldCustomers: 'Order by Old Customers',
	newCustomers: 'Order by New Customers',
	report: 'Report',
	noData: 'No Data',
	repeated: 'Repeated',
	new: 'New',
	old: 'Old',
	clientsDashborad: 'Clients Dashboard',
	maintanceError: 'End date must be after start date',
	otpResendMessage: 'OTP Resent Successfully',
	otpSendMessage: 'Otp sent Successfully',
	FileExtensionNotSupported: 'File Extension Not Supported',
	FileTooLarge: 'File is too large',
	InvalidFile: 'Invalid File',
	ADMIN_WALLET_DEPOSIT: 'Admin Wallet Deposit',
	ADMIN_WALLET_WITHDRAW: 'Admin Wallet Withdraw',
	contactInfo: 'Contact Info',
	frozenBalance: 'On Hold',
	walletBalance: 'Available',
	ACCEPTED: 'Accepted',
	REJECTED: 'Rejected',
	SimultaneousTransactions: 'Simultaneous transactions Not Allowed',
	recaptchaError: 'Your request could not be completed. Please refresh the page and try again',
	'Currency/Wallet': 'Currency/Wallet',
	createdBy: 'Created By',
	both: 'Seller and Buyer',
	Comment: 'Comment',
	AddyourComments: 'Add your Comments',
	withdrawRequests: 'Withdraw Requests',
	suspended: 'Suspended',
	accept: 'Accept',
	'Filter Options': 'Filter Options',
	languageError: 'Can not update the same language',
	'Country/Payment Methods': 'Country/Payment Methods',
	Message: 'Message',
	'Select Country': 'Select Country',
	valueBetweenZeroAandhundred: 'Value Should be between 0 and 100',
	accountGroupName: 'Group Name',
	'TradingAccount(s)NotSupported': 'Trading Account(s) Not Supported in P2P System',
	blocked: 'BLOCKED',
	dashboardTotalBalance: 'Total Wallet Balance',
	dashboardClientsWalletsBalance: 'Total Clients Wallet Balance',
	dashboardMerchantsWallestsBalance: 'Total Merchants Wallet Balance',
	dashboardClientsBalance: 'Total Clients Balance',
	dashboardMerchantsBalance: 'Total Merchants Balance',
	complained: 'Complained',
	withdrawalOffer: 'Withdrawal Offer',
	depositOffer: 'Deposit Offer',
	availableDeposit: 'Available',
	minimumAmountDeposit: 'Minimum Amount',
	maximumAmountDeposit: 'Maximum Amount',
	availableWithdraw: 'Available',
	minimumAmountWithdraw: 'Minimum Amount',
	maximumAmountWithdraw: 'Maximum Amount',
	amountmustbelessThanavailabel: 'Amount must be less than Available Balance {{amount}} {{currency}}',
	amountmustbeBiggerThanMinmum: 'Amount must be Bigger Than the minimum',
	minAllowedValue: 'Minimum Amount must be greater than or equal to {{amount}} {{currency}}',
	maxAllowedValue: 'Maximum Amount must be greater than or equal to {{amount}} {{currency}}',
	maxValueRange: 'Maximum value should be in between 1 - {{amount}}',
	noteMinLength: 'Note should be greater than 20 characters',
	noteMaxLength: 'Note should be less than 1000 characters',
	noteIsRequred: 'Please Enter a Note',
};
