import { useConfigurationContext } from '../../../store/configurationContext';
import './MerchantsHead.scss';
import { useTranslation } from 'react-i18next';

import { headerImage as defaultheaderImage } from '../../../constant/fallback-config';

const MerchantsHead = () => {
	const { t } = useTranslation();

	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';
	return (
		<div className='merchants-head py-10' style={{ backgroundImage: `url(${headerImage})` }}>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>{t<string>('merchantsOffer')}</h1>
			<p className='text-white text-sm text-center'>{t<string>('merchantsOfferSubtitle')}</p>
		</div>
	);
};

export default MerchantsHead;
