import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './SingleClient.scss';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';

import { getInitials } from '../../helpers/getStringInitials';

import { PhoneFilled, RedEnvelopeFilled, HistoryOutlined, AreaChartOutlined } from '@ant-design/icons';
import { Divider, Grid, notification, Skeleton, Table } from 'antd';
import { Client } from '../../models/Clients';
import { getAccontClientsColumn } from '../../helpers/ClientAccountColumn';
import { UsersActions } from '../../components/UsersActions/UsersActions';
import { WalletsAccountscolumns } from '../../components/walletsAccountColumn/walletAccountColumn';
import { Wallet } from '../../models/merchant';
import { useMainContext } from '../../store/MainContext';
import { WithdrawDepositModal } from '../../components/walletRequestModal';
import OTPModal from '../../components/OTPModal/OTPModal';
import { useConfigurationContext } from '../../store/configurationContext';
import { StyledPagination } from '../../components/table.style';

const SingleClient = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const { mainState } = useMainContext();

	const [client, setClient] = useState<Client>();
	const [dataLoading, setDataLoading] = useState(false);
	const [requestType, setRequestType] = useState('');
	const [walletData, setWalletData] = useState<Wallet>();
	const [wallets, setWallets] = useState<Wallet[]>([]);
	const [totalElements, setTotalElements] = useState(0);
	const [action, setAction] = useState(queryParams.get('action') ?? 'tradingAccount');
	const [page, setPage] = useState(0);
	const [otpModal, setOTPModal] = useState(false);
	const [expireTime, setExpireTime] = useState(0);
	const [amount, setAmount] = useState<number>();
	const [comment, setComment] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [searchParams] = useSearchParams();
	const clientId = searchParams.get('id') || '';
	const navigate = useNavigate();
	const { configurationState } = useConfigurationContext();
	const { t } = useTranslation();
	const token = getUserInfo()?.token;
	const { lg } = Grid.useBreakpoint();
	const allowed_admins_emails =
		configurationState.find((item) => item.configKey === 'allowed_admins_emails')?.value || '';
	const email = getUserInfo()?.email ?? '';
	const show = useMemo(() => {
		if (allowed_admins_emails) return allowed_admins_emails.includes(email);
		else if (getUserInfo()?.role === 'SUPER_USER') return true;
		else return false;
	}, [configurationState]);
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	useEffect(() => {
		if (!clientId) {
			navigate('/clients');
		}
	}, [clientId, navigate]);

	useEffect(() => {
		setDataLoading(true);
		axios
			.get(`${API_ENDPOINTS.getClient.replace('%clientId%', clientId)}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setClient(res.data);
				setWallets(res.data.wallets);
				setTotalElements(res?.data?.accounts?.length ?? 0);
			})
			.finally(() => setDataLoading(false));
	}, []);

	const walletTableColumns = useMemo(
		() =>
			WalletsAccountscolumns({
				isMobile: !lg,
				translate: t,
				showActions: show,
			}),
		[lg, t, configurationState],
	);

	const onRequest = (otp: string) => {
		setIsLoading(true);
		let payload = {
			type: requestType,
			quantity: amount,
			walletNumber: walletData?.number,
			userId: client?.userId,
			currency: walletData?.currency,
			otp,
			comment: comment ? comment : undefined,
		};
		axios
			.post(API_ENDPOINTS.walletTransaction, payload)
			.then((res) => {
				const newWallet = wallets.map((wallet) => {
					if (wallet.number === res.data.walletNumber) {
						return {
							...wallet,
							balance: res.data.availableBalance,
						};
					} else {
						return wallet;
					}
				});
				setWallets(newWallet);
				if (requestType === 'WITHDRAW') {
					notification['success']({
						message: '',
						description: t<string>('successfullyWithdraw', {
							amount: amount,
							currency: walletData?.currency,
						}),
					});
				} else {
					notification['success']({
						message: '',
						description: t<string>('successfullyDeposit', {
							amount: amount,
							currency: walletData?.currency,
						}),
					});
				}
				setRequestType('');
				setOTPModal(false);
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const sendOtp = (value: number, resend?: boolean) => {
		let payload = {
			type: requestType,
			quantity: value,
			walletNumber: walletData?.number,
			userId: client?.userId,
			currency: walletData?.currency,
		};
		axios
			.post(API_ENDPOINTS.getWalletTransactionOTP, payload)
			.then((res) => {
				console.log(res);
				setExpireTime(res.data.remainingExpiryTime);
				if (!otpModal) setOTPModal(true);
				if (resend) {
					notification['success']({ message: '', description: t<string>('otpResendMessage') });
				}
			})
			.catch(console.error);
	};

	return (
		<section className='bg-white p-6'>
			<div className='flex w-full justify-between items-center '>
				<h2 className='font-extrabold m-0'>{t('clientProfile')}</h2>
				<div className='flex justify-between w-20'>
					<Link
						to={`/orders?clientId=${clientId}`}
						className='h-9 w-9  border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px] '
					>
						<HistoryOutlined className='text-black text-[18px]' />
					</Link>
					<Link
						to={`/dashboard?role=CLIENT&id=${client?.userId}&email=${client?.emailAddress}`}
						className='h-9 w-9  border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px] '
					>
						<AreaChartOutlined className='text-black text-[18px]' />
					</Link>
				</div>
			</div>
			<Divider className='my-2' />
			<div className='single-client xss:w-[90%] lg:w-full'>
				{dataLoading ? (
					<>
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</>
				) : (
					<>
						<article className='flex flex-col items-center justify-center'>
							<h2 className='name-initials'>{getInitials(client?.fullName ?? '')}</h2>
							<h2 className='m-1'>{client?.fullName}</h2>
							<p className='text-[#333333] xss:text-[10px] lg:text-[13px] m-1'>
								<PhoneFilled className='mx-1 text-gray' />
								{client?.phoneNumber}
								<span className='inline-block mx-1'> </span>
								<RedEnvelopeFilled className='mx-1 text-gray' />
								{client?.emailAddress}
							</p>
							<div className='xss:text-[10px] lg:text-[13px]'>
								<span className='text-[#333333] flex flex-row gap-2 m-1'>
									<span>
										{client?.ordersCompleted} {t<string>('orders')}
									</span>
									<span>|</span>
									<span>
										{(
											(Number(client?.ordersCompleted) /
												(client?.totalOrders === null || client?.totalOrders === 0 ? 1 : Number(client?.totalOrders))) *
											100
										).toFixed()}
										% {t<string>('completionRate')}
									</span>
								</span>
							</div>
						</article>
						<UsersActions role='CLIENT' onAction={setAction} action={action} />
						<div className='m-5 w-full'>
							{action === 'tradingAccount' ? (
								<StyledPagination bgColor={themeColor} color={secondary_color} isClikable={true}>
									<Table
										columns={getAccontClientsColumn(t, client?.exchangeBaseCurrency ?? 'USD')}
										dataSource={client?.accounts}
										loading={dataLoading}
										pagination={{ pageSize: 10, total: totalElements, onChange: (pageIndex) => setPage(pageIndex - 1) }}
										tableLayout='auto'
									/>
								</StyledPagination>
							) : (
								<StyledPagination bgColor={themeColor} color={secondary_color} isClikable={true}>
									<Table
										className='w-full mt-6'
										columns={walletTableColumns}
										dataSource={wallets}
										loading={dataLoading}
										onRow={(record: Wallet) => ({
											onClick: (event: any) => {
												setWalletData(record);
												if (event.target?.innerHTML === t('deposit')) {
													setRequestType('DEPOSIT');
												} else if (event.target?.innerHTML === t('withdrawal')) {
													setRequestType('WITHDRAW');
												} else {
													navigate(`/wallet?walletNumber=${record.number}`);
												}
											},
										})}
										pagination={false}
										tableLayout='auto'
										rowKey='id'
									/>
								</StyledPagination>
							)}
						</div>
					</>
				)}
			</div>
			<WithdrawDepositModal
				open={Boolean(requestType)}
				setOpen={setRequestType}
				onRequest={(value, comment) => {
					setComment(comment);
					setAmount(value);
					sendOtp(value);
				}}
				type={requestType}
				availablebalance={walletData?.balance}
			/>
			{otpModal && (
				<OTPModal
					showOTPModal={otpModal}
					setOTPModal={setOTPModal}
					handelAccept={onRequest}
					sendOtp={(resend) => sendOtp(amount!, resend)}
					expireTime={expireTime}
					isLoading={isLoading}
				/>
			)}
		</section>
	);
};

export default SingleClient;
