import { CaretDownFilled, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Collapse,
	ConfigProvider,
	DatePicker,
	Dropdown,
	Form,
	Grid,
	Input,
	Row,
	Select,
	TimeRangePickerProps,
} from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import { Status } from '../types/Status';
import { RequetStatus } from './RequetStatus';

import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const NumberOfRowsControl = ({
	numberOfEntries,
	setNumberOfEntries,
}: {
	numberOfEntries: string;
	setNumberOfEntries: (numberOfEntries: string) => void;
}) => {
	const { t } = useTranslation();

	const numberOfEntriesOptions = useMemo(
		() => [
			{ label: '10', key: '10' },
			{ label: '20', key: '20' },
			{ label: '50', key: '50' },
			{ label: '100', key: '100' },
		],
		[],
	);

	const onClick = useCallback(
		({ key }: { key: string }) => {
			setNumberOfEntries(key);
		},
		[setNumberOfEntries],
	);

	return (
		<div className='flex items-center gap-2'>
			<span>{t('show')}</span>
			<Dropdown menu={{ items: numberOfEntriesOptions, onClick }}>
				<span className='bg-[#F5F5F5] text-[#1E2329] xss:h-8 md:h-10 xss:w-15 md:w-24 rounded h-10 w-24 flex items-center justify-between px-4 cursor-pointer'>
					<span>{numberOfEntries}</span>
					<CaretDownFilled />
				</span>
			</Dropdown>
			<span>{t('entries')}</span>
		</div>
	);
};

type WalletFiltersProps = {
	className?: string;
	numberOfEntries: string;
	setNumberOfEntries: (numberOfEntries: string) => void;
	search: string;
	setDateRange: (range: string[]) => void;
	setSearch: (search: string) => void;
	status: string | null;
	setStatus: (status: string | null) => void;
	refresh: () => void;
	setAccount: (account: string) => void;
	account: string;
	assignee: any;
	setAssignee: (vaule: any) => void;
};

export const RequestFilters = (props: WalletFiltersProps) => {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const [form] = Form.useForm();
	const token = getUserInfo()?.token;
	const [transactionStatusList, setTransactionStatusList] = useState([]);
	const [assignees, setAssignees] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);

	const requestStatusList = [
		{
			label: <>{t('all')}</>,
			value: '',
		},
	].concat(
		transactionStatusList.map((status: any) => ({
			label: <RequetStatus status={status.value as Status} />,
			value: status.value,
		})),
	);
	const AssigneesRequestList = [
		{
			label: <>{t('all')}</>,
			value: '',
		},
	].concat(
		assignees.map((assinee: any) => ({
			label: assinee.fullName,
			value: assinee.id,
		})),
	);

	const requestAccountsList = [
		{
			label: <>{t('all')}</>,
			value: '',
		},
	].concat(
		accounts.map((account: any) => ({
			label: (
				<>
					{account.accountGroup} | {account.number} | {account.tradingPlatform}
				</>
			),
			value: account.number,
		})),
	);
	useEffect(() => {
		Promise.all([
			axios.get(`${API_ENDPOINTS.withdrawRequest}/status`, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(`${API_ENDPOINTS.adminList}`, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(`${API_ENDPOINTS.accounts}`, { headers: { Authorization: `Bearer ${token}` } }),
		])
			.then(([statuses, assignees, accounts]) => [statuses?.data, assignees?.data, accounts.data])
			.then(([statuses, assignees, accounts]) => {
				setTransactionStatusList(statuses.map((status: string) => ({ label: t(status), value: status })));
				setAssignees(assignees);
				setAccounts(accounts);
			});
	}, [t, token]);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);
	const onDateRangeChange = useCallback((values: TimeRangePickerProps['value']) => {
		const range = (values || [])?.map((value) => dayjs(value).format('YYYY-MM-DD 00:00:00'));
		props.setDateRange(range);
	}, []);
	return (
		<ConfigProvider
			theme={{
				inherit: true,
				components: {
					TreeSelect: {
						nodeSelectedBg: '#fff',
						nodeHoverBg: 'red',
					},
				},
			}}
		>
			<div className={props.className}>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='border-[#000d1d]/10 rounded-md shadow-md py-3 pb-0 px-4 border border-solid border-gray-200'>
							<Form form={form} layout='vertical'>
								<Row gutter={[16, 0]}>
									<Col xs={24} lg={6} className='w-full'>
										<Form.Item label={t('dateRange')} name='dateRange'>
											<RangePicker onChange={(values) => onDateRangeChange(values)} className='w-full' />
										</Form.Item>
									</Col>
									<Col xs={24} lg={6} className='w-full'>
										<Form.Item label={t('status')} name='status' className='min-w-64'>
											<Select
												value={props.status}
												placeholder={t('all')}
												onChange={(e) => props.setStatus(e)}
												options={requestStatusList}
												className='w-full'
											/>
										</Form.Item>
									</Col>
									<Col xs={24} lg={6} className='w-full'>
										<Form.Item label={t('account')} name='account' className='min-w-64'>
											<Select
												value={props.account}
												placeholder={t('all')}
												onChange={(e) => props.setAccount(e)}
												options={requestAccountsList}
												showSearch
												filterOption={(inputValue, option: any) =>
													option?.label?.props?.children
														? option?.label?.props?.children?.toString().includes(inputValue.toLowerCase())
														: false
												}
												className='w-full'
											/>
										</Form.Item>
									</Col>
									<Col xs={24} lg={6} className='w-full'>
										<Form.Item label={t('assignee')} name='assignee' className='min-w-64'>
											<Select
												value={props.assignee}
												placeholder={t('all')}
												onChange={(e) => props.setAssignee(e)}
												options={AssigneesRequestList}
												className='w-full'
											/>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<Row gutter={[16, 16]} align='middle' className='my-2'>
					<Col xs={12} lg={12}>
						<NumberOfRowsControl
							numberOfEntries={props.numberOfEntries}
							setNumberOfEntries={props.setNumberOfEntries}
						/>
					</Col>
					<Col xs={12} lg={12} className='flex gap-2 items-center justify-end'>
						<Button
							onClick={props.refresh}
							className='bg-[#EAECEF] text-[#1E2329] border-none shadow-none'
							htmlType='button'
							type='primary'
							icon={<SyncOutlined />}
						></Button>
						<Input
							value={props.search}
							onChange={(e) => props.setSearch(e.target.value)}
							className='xss:w-36 h-9 md:w-64 border-none'
							placeholder={t<string>('searchWallet')}
							prefix={<SearchOutlined />}
						/>
					</Col>
				</Row>
			</div>
		</ConfigProvider>
	);
};
