type ColumnTitleProps = {
	title: string;
};

export const ColumnTitle = (props: ColumnTitleProps) => {
	return (
		<div className='text-[#000D1D99]  flex items-center cursor-pointer'>
			<span>{props.title}</span>
		</div>
	);
};
