import { TableColumnType, TableColumnsType } from 'antd';
import { Wallet } from './types/Wallet';
import { TFunction } from 'i18next';

import { TransactionType } from './components/TransactionType';

import dayjs from 'dayjs';
import { TransactionStatus } from './components/TransactionStatus';

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

type ColumnTitleProps = {
	title: string;
};

const ColumnTitle = (props: ColumnTitleProps) => {
	return (
		<div className='text-[#000D1D99]  flex items-center cursor-pointer'>
			<span>{props.title}</span>
		</div>
	);
};

export const columns = ({
	isMobile,
	translate,
	onView,
}: {
	isMobile: boolean;
	translate: TFunction;
	onView: (operation: string, transactionNumber: string) => void;
}): TableColumnsType<Wallet> => [
	{
		key: 'transactionNumber',
		dataIndex: 'transactionNumber',
		title: <ColumnTitle title={translate('id')} />,
		sorter: (current, next) => Number(current.id) - Number(next.id),
		render: (value, record) => {
			return (
				<div className='cursor-pointer underline' onClick={() => onView(record.operation, record.transactionNumber)}>
					{value}
				</div>
			);
		},
		responsive: ['lg'],
	},
	{
		key: 'userName',
		dataIndex: 'userName',
		title: <ColumnTitle title={translate('user')} />,
		render: (value, record) => {
			return <div className='word-break-column font-[500] text-[#5bcbff]'>{value}</div>;
		},
		responsive: ['lg'],
	},
	{
		key: 'walletNumber',
		dataIndex: 'walletNumber',
		title: <ColumnTitle title={translate('walletNumber')} />,
		render: (value, record) => {
			return <div className='cursor-pointer'>{value}</div>;
		},
		responsive: ['lg'],
	},
	{
		key: 'type',
		dataIndex: 'type',
		title: <ColumnTitle title={translate('type')} />,
		render: (value) => <TransactionType type={value} />,
		responsive: ['lg'],
	},
	{
		key: 'amount',
		dataIndex: 'amount',
		title: <ColumnTitle title={translate('amount')} />,
		sorter: (current, next) => Number(current.id) - Number(next.id),
		responsive: ['lg'],
	},
	{
		key: 'createdAt',
		dataIndex: 'createdAt',
		title: <ColumnTitle title={translate('creationDate')} />,
		sorter: (current, next) => Number(current.createdAt) - Number(next.createdAt),
		render: (value) => <div>{dayjs(value).format(DATE_TIME_FORMAT)}</div>,
		responsive: ['lg'],
	},
	{
		key: 'status',
		dataIndex: 'status',
		title: <ColumnTitle title={translate('status')} />,
		render: (value) => <TransactionStatus status={value} />,
		responsive: ['lg'],
	},
	// --- Mobile ---
	...(isMobile
		? [
				{
					key: 'wallet',
					dataIndex: 'wallet',
					render: (_value, record) => (
						<div>
							<ul className='list-none p-0 m-0 flex flex-col gap-2'>
								<li className='flex items-center justify-between'>
									<span>{translate('id')}</span>
									<span
										className='cursor-pointer underline'
										onClick={() => onView(record.operation, record.transactionNumber)}
									>
										{record?.transactionNumber}
									</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('type')}</span>
									<TransactionType type={record?.type} />
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('amount')}</span>
									<span>{record?.amount}</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('status')}</span>
									<TransactionStatus status={record?.status} />
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('createdAt')}</span>
									<span>{dayjs(record.createdAt).format(DATE_TIME_FORMAT)}</span>
								</li>
							</ul>
						</div>
					),
				} as TableColumnType<Wallet>,
		  ]
		: []),
];
