import { useTranslation } from 'react-i18next';
import { WalletType } from '../types/WalletType';

export const TransactionType = ({ type }: { type: WalletType }) => {
	const { t } = useTranslation();

	switch (type) {
		case WalletType.P2P_BUY:
		case WalletType.TRADING_DEPOSIT:
		case WalletType.ADMIN_WALLET_DEPOSIT:
			return <div className='text-green-500'>{t(type)}</div>;
		case WalletType.P2P_SELL:
		case WalletType.TRADING_WITHDRAW:
		case WalletType.ADMIN_WALLET_WITHDRAW:
			return <div className='text-red-500'>{t(type)}</div>;
		default:
			return <div>{t(type)}</div>;
	}
};
