import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';

const DountOrdersChart = ({ data }: any) => {
	const { i18n, t } = useTranslation();

	const totalBuy = data.totalBuy ?? 0;
	const totalSell = data.totalSell ?? 0;
	const newData = [
		{
			id: t('deposit'),
			label: t('deposit'),
			value: totalBuy,
			color: '#33a867',
		},
		{
			id: t('withdraw'),
			label: t('withdraw'),
			value: totalSell,
			color: '#f6465d',
		},
	];

	const highestPercentage = totalBuy > totalSell ? totalBuy : totalSell;

	return (
		<ResponsivePie
			data={newData}
			margin={{ top: 10, left: 10, right: 100, bottom: 10 }}
			startAngle={-80}
			sortByValue={true}
			innerRadius={0.6}
			activeInnerRadiusOffset={8}
			activeOuterRadiusOffset={8}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 0.5]],
			}}
			enableArcLinkLabels={false}
			arcLinkLabelsSkipAngle={13}
			arcLinkLabelsTextOffset={31}
			arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
			arcLinkLabelsOffset={-8}
			arcLinkLabelsDiagonalLength={6}
			arcLinkLabelsStraightLength={13}
			arcLinkLabelsThickness={12}
			arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
			arcLabelsRadiusOffset={0.4}
			arcLabelsSkipAngle={10}
			colors={['#33a867', '#f6465d']}
			arcLabelsTextColor={{
				from: 'color',
				modifiers: [['darker', 2.2]],
			}}
			legends={[
				{
					anchor: 'right',
					direction: 'column',
					justify: false,
					translateX: i18n.language === 'ar' ? 130 : 100,
					translateY: -9,
					itemsSpacing: 4,
					itemWidth: 93,
					itemHeight: 40,
					itemTextColor: '#999',
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolSize: 20,
					symbolShape: ({ x, y, size, fill, borderWidth, borderColor }) => (
						<rect
							x={i18n.language === 'ar' ? x + size * 2 : x}
							y={y}
							width={size}
							height={size}
							rx={4} // Set corner radius to make the square rounded
							ry={4} // Set vertical corner radius (same as rx)
							fill={fill}
							strokeWidth={borderWidth}
							stroke={borderColor}
						/>
					),
					effects: [
						{
							on: 'hover',
							style: {
								itemTextColor: '#000',
							},
						},
					],
				},
			]}
			layers={[
				'arcs',
				'legends',
				// Custom layer to render the white circle and the highest percentage inside it
				({ centerX, centerY }) => (
					<g>
						{/* White circle in the center */}
						{highestPercentage === 0 && <circle cx={centerX} cy={centerY} r={80} fill='#cccccc' />}
						<circle
							cx={centerX}
							cy={centerY}
							r={50} // Adjust the radius as needed
							fill='white'
							style={{
								filter: 'drop-shadow(0px 4px 6px rgba(0,0,0,0.1))', // Card-like shadow
							}}
						/>
						{/* Highest percentage text inside the circle */}
						<text
							x={centerX}
							y={centerY}
							textAnchor='middle'
							dominantBaseline='central'
							style={{
								fontSize: '18px', // Adjust the font size as needed
								fontWeight: 'bold',
								fill: '#333',
							}}
						>
							{highestPercentage}%
						</text>
					</g>
				),
			]}
		/>
	);
};

export default DountOrdersChart;
