import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../../assets/config/validation-regex';
import { SignInValues } from '../../models/SignIn';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { deleteUserInfo, getUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import { useMainContext } from '../../store/MainContext';
import { resetMainState } from '../../assets/config/initial-main-state';
import './SignIn.scss';
import { getIsUserLogedIn } from '../../helpers/getIsUserLogedIn';
import { encryptData } from '../../helpers/passwordEncryptionHandler';
import { useConfigurationContext } from '../../store/configurationContext';

import CaptchaButton from '../../components/Button/CaptchaButton';

function SignIn() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { mainState, setMainState } = useMainContext();
	const [signInFormLoading, setSignInFormLoading] = useState(false);
	const [signInFormDisabled, setSignInFormDisabled] = useState(true);
	const [recaptcha, setRecaptcha] = useState('');
	const [isEnabled, setIsEnabled] = useState(false);
	const [version, setRecapchaV] = useState('');
	const user = getUserInfo();

	const currentYear = React.useMemo(() => new Date().getFullYear(), []);
	const { configurationState } = useConfigurationContext();

	const companyWebsite = configurationState.find((item) => item.configKey === 'company_website')?.value || '';
	const backgroundImage = configurationState.find((item) => item.configKey === 'background_image')?.value || '';

	useEffect(() => (getIsUserLogedIn() ? navigate('/merchants') : deleteUserInfo()), []);

	const onSubmit = (signInValues: SignInValues) => {
		// Ensure reCAPTCHA is ready and generate a token
		const encryptedPassword = encryptData(signInValues.password, signInValues.email.toLocaleLowerCase());
		const headers: Record<string, string> = {};

		if (isEnabled && recaptcha) {
			headers['RECAPTCHA-TOKEN'] = recaptcha;
		}
		axios
			.post(
				API_ENDPOINTS.signin,
				{
					...signInValues,
					email: signInValues.email.toLocaleLowerCase(),
					password: encryptedPassword,
				},
				{
					headers: {
						'Content-Type': 'application/json', // Example header
						...headers,
					},
				},
			)
			.then(async (res) => {
				const userData = res.data;
				if (user?.language && user.language !== userData.language)
					await axios.put(
						API_ENDPOINTS.changeLanguage,
						{ lang: user.language },
						{
							headers: { Authorization: `Bearer ${userData.token}` },
						},
					);
				setMainState({
					...mainState,
					email: userData.email,
					userName: userData.userName,
					countryCode: userData.countryCode,
					isLoggedIn: true,
					role: userData.role,
					canHandleAppeal: userData.canHandleAppeal,
					canHandleRequests: userData.canHandleRequests,
					language: user?.language ?? userData?.language,
				});
				setUserInfo({ ...res.data, language: user?.language ?? userData?.language });
				// Calculate the delay for token refresh (e.g., 10 seconds before token expiration)
				setSignInFormLoading(false);
				navigate('/merchants');
			})
			.catch((_) => {
				deleteUserInfo();
				setMainState(resetMainState);
				setSignInFormLoading(false);
			});
	};

	const onFormChange = (_: any, signInValues: SignInValues) => {
		setSignInFormDisabled(
			!EMAIL_VALIDATION.test(signInValues?.email) || !PASSWORD_VALIDATION.test(signInValues?.password),
		);
	};

	return (
		<>
			<section className='signin-section ' style={{ backgroundImage: `url(${backgroundImage})` }}>
				<div className='form-container xss:w-[300px] sm:w-[420px] m-5'>
					<div>
						<h1 className='text-center m-0 my-3 text-sm font-normal text-[#757575]'>{t<string>('welcomeBack')}👋</h1>
						<p className='text-center m-0 my-4 text-2xl font-bold text-[#0f2147]'>{t<string>('loginToAdminPortal')}</p>
					</div>
					<Form
						name='basic'
						layout='vertical'
						onFinish={onSubmit}
						onValuesChange={onFormChange}
						autoComplete='off'
						requiredMark='optional'
					>
						<Form.Item
							label={t<string>('email')}
							name='email'
							rules={[
								{ required: true, message: t<string>('emailRequired') },
								{ pattern: EMAIL_VALIDATION, message: t<string>('emailMsg') },
							]}
						>
							<Input placeholder={t<string>('enterYourEmail')} />
						</Form.Item>
						<Form.Item
							label={t<string>('password')}
							name='password'
							rules={[
								{ required: true, message: t<string>('passwordRequired') },
								{
									pattern: PASSWORD_VALIDATION,
									message: t<string>('passwordMsg'),
								},
							]}
						>
							<Input.Password placeholder={t<string>('enterPassword')} />
						</Form.Item>
						<Form.Item>
							<Button
								className='form-btn'
								type='primary'
								htmlType='submit'
								disabled={signInFormDisabled || (version === 'v2' && isEnabled && !recaptcha)}
								loading={signInFormLoading}
							>
								{t<string>('signIn')}
							</Button>
						</Form.Item>
						<div className='text-xs text-center my-2 text-[#0f2147]'>
							{t<string>('readOur')}{' '}
							<span className='text-xs text-[#00afff] underline'>{t<string>('termsAndConditions')}</span>
						</div>
						<p className='all-rights-reserved'>
							© {currentYear} {companyWebsite} {t<string>('allRightsReserved')}
						</p>
					</Form>
				</div>
				<CaptchaButton
					setRecaptchaToken={setRecaptcha}
					setIsRecaptchaEnabled={setIsEnabled}
					action='ADMIN_LOGIN'
					setRecapchaV={setRecapchaV}
				/>
			</section>
		</>
	);
}

export default SignIn;
