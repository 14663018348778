import { NotificationActionType } from './getNotificationsActionTypes';

export function getNotificationType(notification: string): string {
	switch (notification) {
		case NotificationActionType.NEW_ORDER:
		case NotificationActionType.ORDER_NEW_MESSAGE:
		case NotificationActionType.ORDER_STATUS_UPDATE:
		case NotificationActionType.NEW_APPEAL_TASK_ASSIGNED:
		case NotificationActionType.APPEAL_NEW_MESSAGE:
		case NotificationActionType.APPEAL_REASSIGNED:
			return 'ACTION';
		case NotificationActionType.ORDER_EXPIRY_WARNING:
		case NotificationActionType.OFFER_DISABLED:
		case NotificationActionType.ORDER_EXPIRED:
		case NotificationActionType.ACCOUNT_BALANCE_LOW:
		case NotificationActionType.SELL_AVAILABLE_LOW:
		case NotificationActionType.APPEAL_REMINDER:
		case NotificationActionType.REQUEST_REMINDER:
			return 'WARNING';
		case NotificationActionType.REQUEST_REASSIGNED:
		case NotificationActionType.REQUEST_REASSIGNED_NEW_ADMIN:
		case NotificationActionType.P2P_TRANSACTION_REQUESTS:
		case NotificationActionType.PENDING_REQUEST:
			return 'INFO';
		default:
			return 'ACTION';
	}
}
