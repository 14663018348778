import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { Account } from '../models/Clients';

import './merchantsTableColumns.scss';

export const getAccontClientsColumn = (
	t: TFunction<'translation', undefined, 'translation'>,
	exchangeBaseCurrency: string,
) => {
	const columns: ColumnsType<Account> = [
		{
			title: (
				<div className='p-0 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('tradingPaltform')}
				</div>
			),
			dataIndex: 'tradingPaltform',
			ellipsis: true,
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<div className='flex items-baseline text-[14px] font-medium text-lightBlue m-0'>{record.tradingPlatform}</div>
				);
			},
		},
		{
			title: (
				<div className='p-0 text-[#000D1D99] text-sm flex items-center cursor-pointer'>{t<string>('accountGroup')}</div>
			),
			dataIndex: 'accountGroup',
			key: 'accountGroup',
			responsive: ['lg'],
			render: (value, record) => {
				return <p className='text-[14px] text-[#999999] m-0'>{record.accountGroup}</p>;
			},
		},
		{
			title: (
				<div className='m-0 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('accountNumber')}
				</div>
			),
			dataIndex: 'accountNumber',
			responsive: ['lg'],
			key: 'accountNumber',
			render: (value, record) => {
				return <p className='text-[17px] font-medium text-[#999999] flex items-center m-0'>{record.number}</p>;
			},
		},
		{
			title: (
				<div className='p-0 text-[#000D1D99] text-sm flex items-center cursor-pointer'>{t<string>('balance')}</div>
			),
			dataIndex: 'balance',
			key: 'balance',
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<p className='text-[14px] font-medium text-lightBlue flex items-center m-0'>
						{record.balance} <span className='text-[#999999] text-[15px] px-1'>{exchangeBaseCurrency}</span>
					</p>
				);
			},
			ellipsis: true,
		},
		{
			title: (
				<div className='p-0 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('onHoldBalance')}
				</div>
			),
			dataIndex: 'onHoldBalance',
			key: 'onHoldBalance',
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<p className='text-[14px] font-medium flex items-center text-lightBlue m-0'>
						{record.onHold} <span className='text-[#999999] text-[15px] px-1'>{exchangeBaseCurrency}</span>
					</p>
				);
			},
		},
		{
			dataIndex: 'record',
			key: 'record',
			className: 'small-screen-row',
			render: (value, record) => {
				return (
					<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
						<div className='w-full'>
							<div className='flex flex-row py-2 justify-between'>
								<div> {t<string>('tradingPaltform')}:</div>
								<div className='text-lightBlue text-[15px] font-medium flex items-center'>{record.tradingPlatform}</div>
							</div>

							{/* TODO: change the curency if needed to local currency */}
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('accountGroup')}:</div>
								<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
									{record.accountGroup}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('accountNumber')}:</div>
								<div className='text-[15px] font-medium text-lightBlue flex items-center'>{record.number}</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('balance')}:</div>
								<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>{record.balance}</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('onHoldBalance')}:</div>
								<div className='flex items-center  flex-wrap rounded-sm text-[14px] font-medium text-[#000] px-2 '>
									{record.onHold}
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
