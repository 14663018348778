import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

type UsersActionsProps = {
	className?: string;
	role: 'CLIENT' | 'MERCHANT';
	action: string;
	onAction: (value: string) => void;
};

export const UsersActions = (props: UsersActionsProps) => {
	const { t } = useTranslation();

	return (
		<article className={`flex gap-2 justify-start w-full ${props.className} `}>
			<Button
				htmlType='button'
				type='default'
				shape='round'
				className='text-[12px]'
				onClick={() => props.onAction(props.role === 'MERCHANT' ? 'offers' : 'tradingAccount')}
				style={
					props.action === 'offers' || props.action === 'tradingAccount'
						? { backgroundColor: '#0186ff', color: 'white', fontWeight: '600' }
						: {}
				}
			>
				{props.role === 'MERCHANT' ? t('offers') : t('tradingAccount')}
			</Button>
			<Button
				htmlType='button'
				type='default'
				shape='round'
				className='text-[12px]'
				style={props.action === 'wallets' ? { backgroundColor: '#0186ff', color: 'white', fontWeight: '600' } : {}}
				onClick={() => props.onAction('wallets')}
			>
				{t('walletsAccount')}
			</Button>
		</article>
	);
};
