import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';

import {
	EyeOutlined,
	PlusOutlined,
	EditFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	CaretUpOutlined,
	CaretDownOutlined,
} from '@ant-design/icons';

import { Tooltip } from 'antd';
import './countryAndPaymMethodColumn.scss';
import { handleSortClick } from './sorterColumn';
import { currency } from '../models/currency';
import { ColumnTitle } from '../components/ColumnTitle/ColumnTitle';

export const getCurrencyColumn = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
) => {
	const columns: ColumnsType<currency> = [
		{
			responsive: ['lg'],
			// TODO: will be added in the next sprint
			title: (
				<div>
					{/* <Link to={`/add-currency`} >
						<PlusOutlined className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
					</Link> */}
				</div>
			),
			dataIndex: 'add',
			key: 'add',
			width: 50,
			ellipsis: true,
			render: (value, record) => {
				return (
					<div>
						<Link to={`/add-currency?id=${record.id}`}>
							<EditFilled className='flex items-center cursor-pointer text-black font-bold' />
						</Link>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className=' flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('currencyCode')} />
					<div className='flex flex-col mx-2 '>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'currency-code-asc'))}
							style={{ color: sortBy === 'currency-code-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'currency-code-desc'))}
							style={{ color: sortBy === 'currency-code-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'currencyCode',
			key: 'currencyCode',
			ellipsis: true,
			render: (value, record) => {
				return <div className='text-[#00142A] flex items-centerfont-medium'>{record.currencyCode}</div>;
			},
		},
		{
			responsive: ['lg'],
			title: <ColumnTitle title={t<string>('buyMin')} />,
			dataIndex: 'country-code',
			key: 'country-code',
			render: (value, record) => {
				return (
					<div className=' text-[#626A74] flex items-center mb-1 font-medium'>{record.buyMin?.toLocaleString()}</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: <ColumnTitle title={t<string>('buyMax')} />,
			dataIndex: 'buyMax',
			key: 'buyMax',
			render: (value, record) => (
				<div className='text-[#626A74] flex items-center mb-1 font-medium'>{record.buyMax?.toLocaleString()}</div>
			),
		},
		{
			responsive: ['lg'],
			title: <ColumnTitle title={t<string>('sellMin')} />,
			dataIndex: 'sellMin',
			key: 'sellMin',
			render: (value, record) => {
				return (
					<div className=' font-medium text-[#000D1D99] flex items-center'>{record.sellMin?.toLocaleString()}</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: <ColumnTitle title={t<string>('sellMax')} />,
			dataIndex: 'sellMax',
			key: 'sellMax',
			render: (value, record) => (
				<div className=' font-medium text-[#000D1D99] flex items-center'>{record.sellMax?.toLocaleString()}</div>
			),
			ellipsis: true,
		},
		{
			responsive: ['lg'],
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('public')} />
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'is-public-asc'))}
							style={{ color: sortBy === 'is-public-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'is-public-desc'))}
							style={{ color: sortBy === 'is-public-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'isPublic',
			key: 'isPublic',
			render: (value, record) => {
				return (
					<div className=' font-medium text-lightBlue flex items-center'>
						{record.isPublic ? (
							<CheckCircleOutlined className='h-[20px] w-[20px]  text-[#33A867]' />
						) : (
							<CloseCircleOutlined className='h-[20px] w-[20px]  text-[#F6465D]' />
						)}
					</div>
				);
			},
		},
		{
			dataIndex: 'record',
			title: <div></div>,
			className: 'small-screen-row',
			render: (value, row) => {
				return (
					<div className='flex py-2 pb-0 justify-between'>
						<div className='flex-col'>
							<div className='flex flex-row'>
								<div>
									<Link to={`/add-currency?id=${row.id}`}>
										<EditFilled className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
									</Link>
								</div>
								<div className='flex py-1 pb-0 my-1'>
									<div className='text-[14px] text-[#00142A] flex items-center mb-1 font-medium'>
										{row.currencyCode}
									</div>
								</div>
							</div>
							<div>
								<div className='text-xs text-[#00142A66] m-0  p-0'>
									{t<string>('buyMin')}
									<span className='inline-block mx-1 text-[#000] text-[13px]'>{row.buyMin?.toLocaleString()}</span>
								</div>
							</div>
							<div className='flex items-center h-6'>
								<span className='text-xs text-[#00142A66] '>{t<string>('buyMax')}</span>
								<span className='text-xs text-lightBlue mx-2'>{row.buyMax?.toLocaleString()}</span>
							</div>
							<div className='flex items-center h-6'>
								{t<string>('sellMin')}
								<span className='inline-block mx-1 text-[#000] text-[13px]'>{row.sellMin?.toLocaleString()}</span>
							</div>
							<div className='flex items-center h-6'>
								{t<string>('sellMax')}
								<span className='inline-block mx-1 text-[#000] text-[13px]'>{row.sellMax?.toLocaleString()}</span>
							</div>
						</div>
						<div className='flex-col'>
							<div className='text-[19px] font-medium text-lightBlue flex items-center p-5'>
								{row.isPublic ? (
									<CheckCircleOutlined className='h-[20px] w-[20px] mx-2 text-[#33A867]' />
								) : (
									<CloseCircleOutlined className='h-[20px] w-[20px] mx-2 text-[#F6465D]' />
								)}
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
