import { ConfigProvider, Dropdown, MenuProps, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import './Maintenance.scss';
import Header from '../../components/Header/Header';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { DownOutlined } from '@ant-design/icons';
import { getMaintenanceColumn } from '../../helpers/MaintenanceColumn';
import { themeConfig } from '../WalletTransactions/config';
import { StyledPagination } from '../../components/table.style';
import { useConfigurationContext } from '../../store/configurationContext';

const Maintenance = () => {
	const { t } = useTranslation();
	const [annoncementLoading, setAnnoncementLoading] = useState(false);
	const [annoncement, setAnnoncement] = useState([]);
	const [pagesize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const token = getUserInfo()?.token;
	const { configurationState } = useConfigurationContext();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	const reloadDataCallback = () => {
		setAnnoncementLoading(true);
		axios
			.get(`${API_ENDPOINTS.annoncement}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((reult) => {
				setAnnoncementLoading(false);
				setAnnoncement(reult.data);
				setTotalElements(reult.data.length);
			})
			.catch((err) => setAnnoncementLoading(false));
	};
	useEffect(() => {
		reloadDataCallback();
	}, []);

	const changeOageSize = (pageSize: number) => {
		setPage(0);
		setPageSize(pageSize);
	};

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => changeOageSize(10) },
		{ label: '20', key: '2', onClick: () => changeOageSize(20) },
		{ label: '50', key: '3', onClick: () => changeOageSize(50) },
		{ label: '100', key: '4', onClick: () => changeOageSize(100) },
	];

	return (
		<div className='countries-and-paym-container'>
			<Header title={t<string>('maintenance')} description={t<string>('maintenanceSubtitle')} />
			<div className='min-h-[84vh] mx-auto xss:px-[10px] md:px-[75px] pt-[30px] pb-20 bg-white'>
				<div className='flex items-center my-2'>
					<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
					<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
						<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
							{pagesize}
							<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
						</span>
					</Dropdown>
					<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
				</div>
				<ConfigProvider theme={themeConfig}>
					<StyledPagination bgColor={themeColor} color={secondary_color}>
						<Table
							columns={getMaintenanceColumn(t, reloadDataCallback, token)}
							dataSource={annoncement}
							pagination={{ pageSize: pagesize, total: totalElements, onChange: (pageIndex) => setPage(pageIndex - 1) }}
							loading={annoncementLoading}
							tableLayout='auto'
						/>
					</StyledPagination>
				</ConfigProvider>
			</div>
		</div>
	);
};

export default Maintenance;
