import { useConfigurationContext } from '../../store/configurationContext';
import './Header.scss';
import { headerImage as defaultheaderImage } from '../../constant/fallback-config';
interface SubHeaderProps {
	title: string;
	description: string;
	subDescription?: string;
}

const Header = ({ title, description, subDescription }: SubHeaderProps) => {
	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';

	return (
		<div className='header py-10' style={{ backgroundImage: `url(${headerImage})` }}>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>{title}</h1>
			<p className='text-white text-sm text-center'>{description}</p>
			<p className='text-white text-sm text-center'>{subDescription}</p>
		</div>
	);
};

export default Header;
