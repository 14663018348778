import { ResponsiveLine } from '@nivo/line';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type data = { date: string; buy: string; sell: string };

// Function to transform API data to the desired format
const transformData = (apiData: data[]) => {
	// Initialize the transformed data structure
	const transformedData: any = [
		{ id: 'sell', color: '#f6465d', data: [] },
		{ id: 'buy', color: '#33a867', data: [] },
	];

	// Loop through the API data to populate the transformed structure
	apiData.forEach((entry) => {
		const { date, buy, sell } = entry;
		transformedData[0].data.push({
			x: date, // Keep date in original format or format it as needed
			y: sell,
		});
		transformedData[1].data.push({
			x: date, // Keep date in original format or format it as needed
			y: buy,
		});
	});

	return transformedData;
};

const ReportResponsiveLine = ({ data }: any) => {
	const { t, i18n } = useTranslation();

	const [withdrawHidden, setWithdrawHidden] = useState(true);
	const [depositHidden, setDepositHidden] = useState(true);
	// If data is empty, provide a default structure for an empty chart
	const newData =
		data && data.length > 0
			? transformData(data)
			: [
					{
						id: 'sell',
						color: '#f6465d',
						data: [{ x: 'No Data', y: 0 }],
					},
					{
						id: 'buy',
						color: '#33a867',
						data: [{ x: 'No Data', y: 0 }],
					},
			  ];

	const translatedData = newData.map((serie: any) => ({
		...serie,
		id: serie.id === 'buy' ? t('depositOrder') : t('withdrawOrder'), // Example translation
	}));

	// Custom tooltip component
	const CustomTooltip = ({ point }: any) => {
		const { t } = useTranslation();
		// Find matching data for the point (based on the x value)
		const obj = data?.find((p: data) => p.date === point?.data?.x);
		return (
			<div className='tooltip-container'>
				{obj ? (
					<>
						<p className='tooltip-text'>
							{t('deposit')}: <span className='value buy'>{obj.buy}</span>
						</p>
						<p className='tooltip-text'>
							{t('withdraw')}: <span className='value sell'>{obj.sell}</span>
						</p>
						<p className='tooltip-text'>
							{t('date')}: <span className='value'>{point.data.x}</span>
						</p>
					</>
				) : (
					<p>{t('noData')}</p> // Show a no data message in the tooltip if no matching point is found
				)}
			</div>
		);
	};

	// Function to toggle the series
	const handleLegendClick = (item: any) => {
		if (item.id === t('depositOrder')) setWithdrawHidden(item.hidden);
		if (item.id === t('withdrawOrder')) setDepositHidden(item.hidden);
	};

	return (
		<ResponsiveLine
			data={translatedData}
			margin={{ top: 50, right: 10, bottom: 40, left: 33 }}
			xScale={{ type: 'point' }}
			yScale={{
				type: 'linear',
				min: data && data.length > 0 ? 'auto' : 0,
				max: data && data.length > 0 ? 'auto' : 50,
				stacked: true,
				reverse: false,
			}}
			yFormat=' >-.2f'
			axisTop={null}
			axisRight={null}
			curve='cardinal'
			enableGridX={false}
			colors={['#f6465d', '#33a867']}
			enablePoints={true}
			pointSize={2}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabel='data.yFormatted'
			pointLabelYOffset={-12}
			enableTouchCrosshair={true}
			useMesh={Boolean(withdrawHidden || depositHidden)}
			tooltip={CustomTooltip}
			axisBottom={{
				tickSize: 0, // Hide tick marks on x-axis
				tickPadding: 0, // Remove padding around ticks
				tickValues: [], // Remove x-axis tick labels
				legend: '', // Remove any x-axis legend (label)
			}}
			axisLeft={{
				tickValues: 6, // Show 6 ticks (0, 100, 200, ..., 600)
				tickPadding: 5, // Adjust tick padding if necessary
				legend: '', // Adjust label if necessary
				legendOffset: -40,
				legendPosition: 'middle',
			}}
			legends={[
				{
					anchor: 'top-left',
					direction: 'row',
					justify: false,
					translateX: i18n.language === 'ar' ? -100 : -30,
					translateY: -40,
					itemsSpacing: 5,
					itemDirection: i18n.language === 'ar' ? 'right-to-left' : 'left-to-right',
					itemWidth: 150,
					itemHeight: 10,
					itemOpacity: 0.75,
					symbolSize: 14,
					symbolShape: ({ x, y, size, fill }) => (
						<circle
							cx={i18n.language === 'ar' ? x + size * 5 : x + size / 2}
							cy={y + size / 2}
							r={size / 2}
							stroke={fill}
							strokeWidth={2}
							fill='none'
						/>
					),
					toggleSerie: true,
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
					onClick: (legendItem) => handleLegendClick(legendItem),
				},
			]}
			theme={{
				legends: {
					text: {
						fontSize: 15, // Adjust this value for text size
						fontWeight: 600,
						color: 'black',
					},
				},
			}}
		/>
	);
};

export default ReportResponsiveLine;
