import { useTranslation } from 'react-i18next';
import { WalletStatus } from '../types/WalletStatus';

export const TransactionStatus = ({ status }: { status: WalletStatus }) => {
	const { t } = useTranslation();

	switch (status) {
		case WalletStatus.COMPLETED:
			return <div className='text-green-500'>{t(status)}</div>;
		case WalletStatus.PENDING:
			return <div className='text-cyan-500'>{t(status)}</div>;
		case WalletStatus.PENDING_APPROVAL:
			return <div className='text-yellow-500'>{t(status)}</div>;
		case WalletStatus.REVERTED:
			return <div className='text-red-500'>{t(status)}</div>;
		case WalletStatus.CANCELLED:
			return <div className='text-gray-500'>{t(status)}</div>;
		default:
			return <div>{t(status)}</div>;
	}
};
