import { ResponsivePie } from '@nivo/pie';
import { Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';

const CustomArcLabel = (d: any) => {
	return (
		<animated.g
			transform={d.style.transform}
			style={{
				pointerEvents: 'none',
			}}
		>
			<rect
				x={-40} // Adjust these values to center the card
				y={-20}
				width={80}
				height={40}
				rx={5}
				ry={5}
				fill='white'
				filter={'drop-shadow(0px 0px 5px rgba(0,0,0,0.1))'} // Optional: Add shadow effect
			/>
			<text
				x={0}
				y={-5}
				textAnchor='middle'
				dominantBaseline='middle'
				fill={'black'}
				style={{ fontSize: 12, fontWeight: 'bold' }}
			>
				{d.datum.label}
			</text>
			<text x={0} y={10} textAnchor='middle' dominantBaseline='middle' fill={'black'} style={{ fontSize: 10 }}>
				{d.datum.value}%
			</text>
		</animated.g>
	);
};

const CustomerCharts = ({ data }: any) => {
	const { t } = useTranslation();
	const { xs } = Grid.useBreakpoint();
	const customerData = [
		{
			id: t('new'),
			label: t('new'),
			value: data.newOrder ?? 0,
			color: '#ea9010',
		},
		{
			id: t('old'),
			label: t('old'),
			value: data.oldOrder ?? 0,
			color: '#f9dec9',
		},
	];

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<ResponsivePie
				margin={{ top: 20, left: 20, right: 0, bottom: 25 }}
				data={customerData}
				startAngle={-80}
				sortByValue={true}
				innerRadius={0.7}
				padAngle={3}
				cornerRadius={18}
				isInteractive={false}
				enableArcLabels={true} // Enable arc labels
				arcLabelsSkipAngle={5} // Always display labels, even for small slices
				arcLabelsRadiusOffset={1}
				arcLabelsComponent={CustomArcLabel}
				arcLabelsTextColor={{
					from: 'color', // Set text color to be dynamic based on the slice color
					modifiers: [['darker', 1.6]], // Make the text a bit darker for contrast
				}}
				colors={['#ea9010', '#f9dec9']} // Custom colors for the slices
				layers={[
					// Custom layer for the outer background circle
					({ centerX, centerY }) => (
						<g>
							<circle
								cx={centerX}
								cy={centerY}
								r={xs ? 80 : 100} // Outer circle radius
								fill='#fceaee' // Background color for the chart
							/>
						</g>
					),
					'arcs',
					// Custom layer for the inner circle and text inside donut
					({ centerX, centerY }) => (
						<g>
							<circle
								cx={centerX}
								cy={centerY}
								r={xs ? 40 : 60} // Inner circle radius
								fill='white'
								style={{ filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.1))' }} // Shadow effect for inner circle
							/>
							<text
								x={centerX}
								y={centerY}
								textAnchor='middle'
								dominantBaseline='central'
								style={{
									fontSize: '18px', // Adjust the font size
									fontWeight: 'bold',
									fill: '#333',
								}}
							>
								{t('report')}
							</text>
						</g>
					),
					'arcLabels',
				]}
			/>
		</div>
	);
};

export default CustomerCharts;
