import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { useConfigurationContext } from '../../store/configurationContext';
import {
	themeColor as defaultThemeColor,
	companyWebsite as defaultCompanyWebsite,
} from '../../constant/fallback-config';

const Footer = () => {
	const currentYear = React.useMemo(() => new Date().getFullYear(), []);
	const { t } = useTranslation();
	const { configurationState } = useConfigurationContext();

	const companyWebsite = configurationState.find((item) => item.configKey === 'company_website')?.value ?? '';
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value;
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div className='footer' style={{ color: secondary_color }}>
			<div className='footer-tail py-4' style={{ backgroundColor: themeColor }}>
				<div className='max-w-[1440px] mx-auto flex flex-col sm:flex-row items-center justify-between'>
					<span className='text-xs lg:mb-0'>
						© {currentYear} {companyWebsite} {t<string>('allRightsReserved')}.
					</span>
				</div>
			</div>
		</div>
	);
};

export default Footer;
