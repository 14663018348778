import { useTranslation } from 'react-i18next';

type propsType = {
	repeatedOrderCustomer: number;
	newOrders: number;
	oldOrders: number;
};

const CustomerReport = ({ newOrders, oldOrders, repeatedOrderCustomer }: propsType) => {
	const { t } = useTranslation();
	return (
		<div className='w-full'>
			<div
				className='w-full flex justify-between h-9  rounded-xl p-2 my-7'
				style={{ backgroundColor: '#07dac2', backgroundImage: 'linear-gradient(90deg, #07dac2, white)' }}
			>
				<span>{t('repeatedOrders')}</span>
				<span>{repeatedOrderCustomer}</span>
			</div>
			<div
				className='w-full flex justify-between h-9  rounded-xl p-2 my-7'
				style={{ backgroundColor: '#eb9418', backgroundImage: 'linear-gradient(90deg, #f9dfcb, white)' }}
			>
				<span>{t('oldCustomers')}</span>
				<span>{oldOrders}</span>
			</div>
			<div
				className='w-full flex justify-between h-9  rounded-xl p-2 my-7'
				style={{ backgroundColor: '#f9dfcb', backgroundImage: 'linear-gradient(90deg, #eb9418, white)' }}
			>
				<span>{t('newCustomers')}</span>
				<span>{newOrders}</span>
			</div>
		</div>
	);
};
export default CustomerReport;
