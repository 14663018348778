import { CreditCardTwoTone, MailFilled, MailOutlined, PhoneFilled } from '@ant-design/icons';
import { Avatar, Button, Col, Form, Modal, Row, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CopyFilled } from '@ant-design/icons';
import { WalletType } from '../types/WalletType';
import { useMemo, useState } from 'react';
import { TransactionStatus } from '../components/TransactionStatus';
import { WalletStatus } from '../types/WalletStatus';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import TextArea from 'antd/es/input/TextArea';

type Account = {
	group: string;
	number: number;
	platform: string;
	balance: string;
};
type Request = {
	requestId: number;
	status: string;
	resolutionTime: string;
	assignedOn: string;
	assigneeName: string;
	assigneeId: number;
	actionBy: string;
};
type TransactionReportModalProps = {
	open?: boolean;
	transactionNumber: string;
	userName: string;
	phone: string;
	email: string;
	setOpen: (open: boolean) => void;
};

export const ContactUserModal = (props: TransactionReportModalProps) => {
	const { t } = useTranslation();
	const [message, setMessage] = useState('');
	const token = getUserInfo()?.token;

	const sendEmail = () => {
		axios
			.post(
				API_ENDPOINTS.sendEmail,
				{
					transactionNumber: Number(props.transactionNumber),
					message: message,
					toEmail: props.email,
				},
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.then((res) => {
				props.setOpen(false);
			})
			.catch((err) => console.error(err))
			.finally(() => props.setOpen(false));
	};

	return (
		<Modal
			width={640}
			open={props?.open}
			footer={null}
			closable={true}
			onCancel={() => props.setOpen(false)}
			destroyOnClose
		>
			<div className='flex flex-col gap-4'>
				<div className='mx-auto'>
					<div className='rounded-full w-16 h-16 bg-[#eee] flex items-center justify-center'>
						<MailFilled className='text-2xl text-[#0194ff]' />
					</div>
				</div>
				<div className='font-[600] text-lg text-center underline'>{t('contactUser')}</div>
				<Row className='mb-4' gutter={[16, 16]}>
					{/** Left */}
					<Col span={12}>
						<div className='grid justify-center'>
							<div className='flex items-center text-[12px] gap-2 my-1'>
								<div className='font-bold '>
									<span className='text-[#c3c3c3] font-[600]'>{t('userName')} : </span>
									{props?.userName}
								</div>
							</div>
							<div className='flex gap-1 text-[12px] my-1'>
								<div className='font-bold text-[#3091ff]'>
									<span className='text-[#c3c3c3] font-[600]'>{t('emailAddress')} : </span>
									{props?.email}
								</div>
							</div>
							<div className='flex gap-1 text-[12px] my-1'>
								<div className='font-bold '>
									<span className='text-[#c3c3c3] font-[600]'>{t('transactionNumber')} : </span>
									{props?.transactionNumber}
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<div>
					<div className='font-bold mb-2'>{t('Message')}</div>
					<Form.Item name='message'>
						<TextArea
							onChange={(e) => setMessage(e.target.value)}
							placeholder={t<string>('typeMessageHere')}
							autoSize={{ minRows: 6, maxRows: 6 }}
						/>
					</Form.Item>
				</div>

				<Button
					onClick={sendEmail}
					className='w-full border-none shadow-none text-white my-4'
					style={{ background: 'linear-gradient(#00AFFF, #0077FF)' }}
					htmlType='button'
					size='large'
					type='primary'
				>
					{t('sendEmail')}
				</Button>
			</div>
		</Modal>
	);
};
